export interface IButtonProps {
  title: string;
  buttonStyle?: ButtonStyle;
  className?: string;
}
export interface IButtonLinkProps extends IButtonProps {
  link: string;
  onClick?: () => void;
}
export interface IButtonElementProps extends IButtonProps {
  onClick: () => void;
  childClassNames?: {
    buttonContent?: string;
    loadingContainer?: string;
  };
  showLoading?: boolean;
}
export enum ButtonStyle {
  Primary = 1,
  Secondary = 2,
  Tertiary = 3,
  TransparentBackgroundWhiteBorder = 4,
  TransparentBackgroundGreenBorder = 5,
}
