import * as styles from "./RadioGroup.module.css";

import { FormDispatches, FormTypes } from "@models/forms";

import RadioButton from "./RadioButton/RadioButton";
import React from "react";
import { useFormDispatch } from "@hooks";

const RadioGroup = ({
  name,
  options,
  dispatch = null, //used when part of a form
  formType = undefined,
  setValue = (_value) => {}, //used when it's for display
  required = true,
  incompleteForm = false,
  className = "",
}: {
  name: string;
  options: { key: string; value: string }[];
  dispatch?: FormDispatches;
  formType?: FormTypes;
  setValue?: (_value: string) => void;
  required?: boolean;
  incompleteForm?: boolean;
  className?: string;
}) => {
  // Set up the state for the current value, begins as undefined
  const [currentValue, setCurrentValue] = React.useState<string | undefined>(
    undefined
  );

  // Sets the current value, and if it's part of a form, updates the context,
  // otherwise updates the state with the setValue function
  const onChangeHandler = (value: string) => {
    setCurrentValue(value);
    setValue(value);
    if (dispatch && formType) {
      useFormDispatch({
        name,
        value: { valid: true, value },
        formType,
        dispatch,
      });
    }
  };

  return (
    <div className={`${styles.radioGroup} ${className}`}>
      {/* Loops through all of the options and displays a radio button for each */}
      {options.map((option, i) => (
        <RadioButton
          key={`RadioButton-${name}-${i}`}
          name={name}
          value={option.value}
          label={option.key}
          onChange={onChangeHandler}
          checked={currentValue === option.value}
        />
      ))}

      {/* Displays an error message if the field is required and the form is incomplete */}
      {currentValue === undefined && required && incompleteForm && (
        <span className={styles.radioError}>This field is required</span>
      )}
    </div>
  );
};

export default RadioGroup;
