import * as buttonElementStyles from "./ButtonElementWithArrow.module.css";

import { ButtonStyle, IButtonElementProps } from "@models/component-props";

import { ButtonElement } from "@components/ui";
import React from "react";

/**
 * A React functional component that renders a ButtonElement with an arrow.
 *
 * @param {string} buttonText - The text to be displayed on the button.
 * @param {string} title - The title of the button.
 * @param {function} onClick - The callback function to be executed when the button is clicked.
 * @param {ButtonStyle} buttonStyle - The style of the button (default: ButtonStyle.Primary).
 * @param {string} className - Additional class names to be applied to the button.
 * @param {object} childClassNames - Class names to be applied to the button's child elements.
 * @param {boolean} showLoading - Whether to display a loading indicator on the button (default: false).
 * @return {JSX.Element} The rendered ButtonElement with an arrow.
 */
const ButtonElementWithArrow = ({
  title,
  onClick,
  buttonStyle = ButtonStyle.Primary,
  className = "",
  childClassNames = {},
  showLoading = false,
}: IButtonElementProps) => {
  return (
    <ButtonElement
      title={title}
      onClick={onClick}
      buttonStyle={buttonStyle}
      className={`${buttonElementStyles.button} ${className}`}
      childClassNames={{
        buttonContent: buttonElementStyles.buttonContent,
        loadingContainer: buttonElementStyles.loadingContainer,
        ...childClassNames,
      }}
      showLoading={showLoading}
    />
  );
};

export default ButtonElementWithArrow;
