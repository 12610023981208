import * as styles from "./Input.module.css";

import { FormDispatches, FormTypes } from "@models/forms";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { useFormDispatch } from "@hooks";

/**
 * Props for the Input component.
 */
interface InputProps {
  type: "text" | "tel" | "email" | "number" | "date" | "textarea" | "password";
  title: string;
  name: string;
  placeholder: string;
  dispatch?: FormDispatches;
  formType?: FormTypes;
  setValue?: (_value: string) => void;
  required?: boolean;
  min?: number | Date | null;
  max?: number | Date | null;
  isInt?: boolean;
  incompleteForm?: boolean;
  className?: string;
  defaultValue?: string;
}

/**
 * A basic input component that can be used to create a form field with a single
 * input element. It can be used to create a text, tel, email, number, date, or
 * textarea input. The component will validate the input based on the type and
 * any min and max values provided. If the input is part of a form, it will
 * dispatch the value to the form state. Otherwise, it will set the value to the
 * provided setValue callback. The component will also show an error message if
 * the input is invalid.
 *
 * @param {string} type The type of input element to create.
 * @param {string} title The label text for the input element.
 * @param {string} name The name of the form field.
 * @param {string} placeholder The placeholder text for the input element.
 * @param {FormDispatches} [dispatch] The dispatch function to use to update the form state.
 * @param {FormTypes} [formType] The type of form to update.
 * @param {boolean} [required] Whether the field is required.
 * @param {number | Date | null} [min] The minimum value for the input element.
 * @param {number | Date | null} [max] The maximum value for the input element.
 * @param {boolean} [isInt] Whether the input element is a number and should
 *    only accept whole numbers.
 * @param {boolean} [incompleteForm] Whether the form is incomplete.
 * @param {string} [className] The class name to add to the container element.
 * @param {string} [defaultValue] The default value of the input element.
 * @return {JSX.Element} The input component.
 */
const Input = ({
  type,
  title,
  name,
  placeholder,
  dispatch = null, //used when part of a form
  formType = undefined,
  setValue = (_value) => {}, //used when it's for display
  required = true,
  min = null,
  max = null,
  isInt = false,
  incompleteForm = false,
  className = "",
  defaultValue = "",
}: InputProps) => {
  const [curVal, setCurVal] = useState(""); // For checking if valid after the user stops typing
  const [hasTyped, setHasTyped] = useState(false);
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [errorText, setErrorText] = useState("");

  // Reference to the input/textarea element
  const inputRef = useRef<HTMLInputElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  // Set default value of the input field when the component mounts
  useEffect(() => {
    if (inputRef.current) inputRef.current.value = defaultValue;
  }, []);

  // Validates the input value and updates the state accordingly.
  const checkIsValid = useCallback(() => {
    let curIsValid = true;
    let curErrorText = "";
    let val =
      type === "textarea"
        ? textAreaRef.current
          ? textAreaRef.current.value
          : ""
        : inputRef.current
        ? inputRef.current.value
        : "";

    // Check if the field is required and not empty
    if (required && val.trim().length === 0) {
      curIsValid = false;
      curErrorText = `This field is required`;
    } else if (val.trim().length > 0) {
      // Format the telephone number if it's a tel input
      if (type === "tel") {
        // Replace all spaces with empty strings
        let newVal = val.replace(/\s/g, "");

        // Add spaces to the end of the phone number that contains
        // an extension (e.g. 0123456789#1234)
        newVal = newVal.replace(/((x|ext\.?|#)\d{3,4})/g, " $1");
        (inputRef.current as HTMLInputElement).value = newVal;
        val = newVal;
      }
      // Additional validation based on type
      if (
        // Check if the value matches an email pattern
        (type === "email" && !val.match(/^.*?@.*?\..+?$/gi)) ||
        // Check if the value matches a number pattern
        (type === "number" &&
          ((isInt && !val.match(/^\d+?$/g)) ||
            (!isInt && !val.match(/^\d+(?:\.?\d+)?$/g)))) ||
        // Check if the value matches a date pattern
        (type === "date" &&
          !val.match(
            /(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/g
          )) ||
        // Check if the value matches a phone number pattern
        (type === "tel" &&
          !val.match(
            /^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|#)\d{3,4})?$/g
          ))
      ) {
        // If the value doesn't match the pattern, the value is invalid
        // and the error message is set
        curIsValid = false;
        curErrorText = `The ${type} provided is invalid`;
        // Change the error message depending on the type
        if (type === "number" && isInt)
          curErrorText = `The number provided is not a whole number`;
        if (type === "tel")
          curErrorText = `The phone number provided is invalid`;
      } else if ((type === "number" || type === "date") && (min || max)) {
        // The value is valid, if it's a number or date, check if it's in the range (if min and max are provided)
        let typeMin: Date | number | null = min;
        let typeMax: Date | number | null = max;
        if (type === "date") {
          typeMin = min ? new Date(min) : null;
          typeMax = max ? new Date(max) : null;
        }
        const typeVal = type === "number" ? +val : new Date(val);

        // Check if the value is in the range
        if ((typeMin && typeVal < typeMin) || (typeMax && typeVal > typeMax)) {
          // If the value is not in the range, the value is invalid
          // and the error message is set
          curIsValid = false;
          curErrorText =
            typeMin && typeVal < typeMin
              ? `The ${type} provided should be at least ${
                  type === "date" ? (typeMin as Date).toLocaleDateString() : min
                }`
              : `The ${type} provided should be less than or equal to ${
                  type === "date" ? (typeMax as Date).toLocaleDateString() : max
                }`;
        }
      }
    }

    // Set the validity and error message
    setIsValid(curIsValid);
    setErrorText(curErrorText);

    // Dispatch the value if it's part of a form, otherwise set the value
    if (formType && dispatch)
      useFormDispatch({
        name,
        value: { valid: curIsValid, value: val },
        formType,
        dispatch,
      });
    else setValue(val);
  }, [isInt, max, min, name, required, setValue, type]);

  // Set any non-required fields that are blank as valid when the component mounts or required/incompleteForm changes
  useEffect(() => {
    if (
      !required &&
      (!inputRef.current ||
        (inputRef.current && inputRef.current.value.trim().length === 0)) &&
      (!textAreaRef.current ||
        (textAreaRef.current && textAreaRef.current.value.trim().length === 0))
    ) {
      // Set the states
      setIsValid(true);
      setErrorText("");

      // Dispatch the value if it's part of a form, otherwise set the value
      if (formType && dispatch)
        useFormDispatch({
          name,
          value: { valid: true, value: "" },
          formType,
          dispatch,
        });
      else setValue("");
    }

    // If the form is incomplete, check if the input is valid
    if (incompleteForm) checkIsValid();
  }, [required, incompleteForm]);

  // Debounce validation check when the user types
  useEffect(() => {
    const delaySetState = setTimeout(() => {
      if (hasTyped) checkIsValid();
    }, 500);
    return () => clearTimeout(delaySetState);
  }, [curVal, hasTyped]);

  // Handle input blur event
  const onBlurHandler = () => {
    checkIsValid();
  };

  // Handle input change event
  const onChangeHandler = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!hasTyped) setHasTyped(true);
    else setCurVal(e.currentTarget.value); //stops check is valid from occurring as soon as the form loads
  };

  // Set the attributes for the input or textarea element
  let attr: {
    id: string;
    className: string;
    name: string;
    placeholder: string;
    onBlur: () => void;
    onChange: (
      _e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    rows?: number;
    type?: string;
    min?: number | string;
    max?: number | string;
    step?: number;
  } = {
    id: name,
    className: `${styles.input} ${
      isValid ? styles.inputValid : isValid === false ? styles.inputInvalid : ""
    }`,
    name,
    placeholder,
    onBlur: onBlurHandler,
    onChange: onChangeHandler,
  };

  attr = type === "textarea" ? { ...attr, rows: 6 } : { ...attr, type };

  // Add min and max attributes if applicable
  if (type === "number" || type === "date") {
    if (min)
      attr = {
        ...attr,
        min:
          type === "date"
            ? (min as Date).toLocaleDateString()
            : (min as number),
      };
    if (max)
      attr = {
        ...attr,
        max:
          type === "date"
            ? (max as Date).toLocaleDateString()
            : (max as number),
      };
  }

  if (type === "number" && isInt) attr = { ...attr, step: 1 };

  return (
    <div
      className={`${styles.inputContainer} ${
        className.length > 0 ? className : ""
      }`}
    >
      {/* Label for the input */}
      <label htmlFor={name} className={styles.inputTitle}>
        {title}
      </label>

      {/* Textarea if applicable, otherwise input element */}
      {type === "textarea" ? (
        <textarea ref={textAreaRef} {...attr}></textarea>
      ) : (
        <input ref={inputRef} {...attr} />
      )}

      {/* Error message */}
      {!isValid && <span className={styles.inputError}>{errorText}</span>}
    </div>
  );
};

export default Input;
