import * as styles from "./Accordion.module.css";

import React, { useEffect, useRef, useState } from "react";

import { ButtonLink } from "@components/ui";
import { HtmlOutput } from "@components/html";
import { IAccordionProps } from "@models/component-props";

const Accordion = ({ pageTitle, contentImages, items }: IAccordionProps) => {
  const itemsArr = items ? items : [];
  const refs = useRef(
    itemsArr.map(() => {
      return React.createRef<HTMLDivElement>();
    })
  );
  const defAccordionsOpen = itemsArr.map(() => {
    return false;
  });

  const [accordionsOpen, setAccordionsOpen] = useState(defAccordionsOpen);
  useEffect(() => {
    if (refs.current.length > 0)
      refs.current[0].current && refs.current[0].current.focus();
  }, []);

  return (
    <div className={styles.accordionContainer}>
      {itemsArr.map((item, i) => {
        return (
          <div
            key={`AccordionItem_${i}`}
            className={`${styles.accordionItem} ${
              accordionsOpen[i] ? styles.open : ""
            }`}
          >
            <button
              type="button"
              className={`${styles.accordionButton} ${
                i === 0 || (i > 0 && accordionsOpen[i - 1])
                  ? styles.thickerTop
                  : ""
              }`}
              aria-label={item.title}
              onClick={() => {
                const btnOpen = !accordionsOpen[i];
                const newAccordionsOpen = accordionsOpen.map((acc, x) => {
                  return x === i ? btnOpen : acc;
                });
                setAccordionsOpen(newAccordionsOpen);
              }}
            >
              {item.title}
            </button>
            <div
              ref={refs.current[i]}
              className={styles.accordionContent}
              style={{
                maxHeight: `${
                  accordionsOpen[i]
                    ? `${
                        (refs.current[i].current as HTMLDivElement).scrollHeight
                      }px`
                    : ""
                }`,
              }}
            >
              <HtmlOutput
                title={pageTitle}
                contentImages={contentImages}
                html={item.content}
              />
              {item.button && (
                <ButtonLink
                  link={item.button.to}
                  title={item.button.title}
                  buttonStyle={item.button.style}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Accordion;
