import * as colourStyles from "./SVGStyles/SVGColours.module.css";
import * as styles from "./SVGStyles/SVGPolygon.module.css";

import {
  ISVGPolygonStrokeComponentProps,
  ISVGPolygonStrokeTwoFiveIconType,
  allPolygonStrokeTwoFiveIconTypes,
} from "@models/component-props";

import React from "react";
import { SVGFromIconName } from "@components/svgs";
import { useSVGColour } from "@hooks";

/**
 * A component to render a polygon with a stroke colour, based on the given icon
 * and colour. This component ensures the styles specific to this icon are
 * applied.
 *
 * @param {ISVGPolygonStrokeComponentProps} props
 * @prop {ISVGIconType} icon - The icon to render
 * @prop {ISVGColours} [colour] - The colour of the SVG
 * @prop {ISVGProps} [svgProps] - Additional props to pass to the underlying SVG
 * @returns {React.ReactElement} The polygon SVG where generic styles are applied to the stroke
 */
const SVGPolygonStroke = ({
  icon,
  colour,
  svgProps,
}: ISVGPolygonStrokeComponentProps) => {
  // Check if the polygon needs a stroke-width: 2.5px; rule
  const isStrokeTwoFive = (
    value: string
  ): value is ISVGPolygonStrokeTwoFiveIconType => {
    return allPolygonStrokeTwoFiveIconTypes.includes(
      value as ISVGPolygonStrokeTwoFiveIconType
    );
  };

  // The class name based on the colour provided and the result
  // of the isStrokeTwoFive function
  const className = `${styles.svgPolygonStroke} ${useSVGColour(
    colourStyles,
    colour
  )} ${styles.svgPolygonFillNone} ${
    isStrokeTwoFive(icon)
      ? styles.svgPolygonStrokeTwoFive
      : styles.svgPolygonStrokeThree
  } ${svgProps.className || ""}`;

  // Return the SVG code based on the icon and the class name
  return <SVGFromIconName icon={icon} svgProps={{ ...svgProps, className }} />;
};

export default SVGPolygonStroke;
