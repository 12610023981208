import { graphql, useStaticQuery } from "gatsby";

import { IRequestACallbackHook } from "@models/hooks";

/**
 * Fetches the request a callback post data using Gatsby's useStaticQuery hook.
 *
 * @returns {IRequestACallbackHook} The request a callback post data.
 */
const useRequestACallback = () => {
  const data = useStaticQuery<IRequestACallbackHook>(graphql`
    {
      requestACallback {
        id
        date_created
        date_updated
        title
        meta_title
        meta_description
        excerpt
        call_prompt
        thank_you_title
        thank_you_excerpt
      }
    }
  `);
  return data;
};

export default useRequestACallback;
