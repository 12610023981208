import { RequestACallbackContext } from "@store";
import { useContext } from "react";

/**
 * Custom hook to use the RequestACallback context.
 *
 * @throws Will throw an error if the hook is used outside of a RequestACallbackProvider.
 * @returns The context value containing the state and dispatch function.
 */
export const useRequestACallbackContext = () => {
  const context = useContext(RequestACallbackContext);
  if (context === undefined) {
    throw new Error(
      "useRequestACallback must be used within a RequestACallbackProvider"
    );
  }
  return context;
};
