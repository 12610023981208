import { ISVGColours } from "@models/component-props";

/**
 * Given a styles object and a colour, returns the class name for the SVG to have
 * the given colour. If no colour is given, returns an empty string.
 *
 * @param {any} styles - The styles object to get the class name from.
 * @param {ISVGColours} [colour] - The colour to get the class name for.
 * @returns {string} The class name for the SVG to have the given colour.
 */
const useSVGColour = (styles: any, colour?: ISVGColours) => {
  return colour === "blue"
    ? styles.svgBlue
    : colour === "dark-blue"
    ? styles.svgDarkBlue
    : colour === "dark-green"
    ? styles.svgDarkGreen
    : colour === "green"
    ? styles.svgGreen
    : colour === "light-blue"
    ? styles.svgLightBlue
    : colour === "white"
    ? styles.svgWhite
    : colour === "black"
    ? styles.svgBlack
    : "";
};
export default useSVGColour;
