/**
 * Given some provided dimensions, default dimensions, and a ratio of width to
 * height, returns the dimensions to be used for an SVG. If only one of width or
 * height is provided, it calculates the other based on the ratio.
 *
 * @param {Object} dimensionsProvided - Width and/or height provided by the user
 * @param {Object} defaultDimensions - Default width and height for the SVG
 * @param {number} widthHeightRatio - Ratio of width to height for the SVG
 * @returns {Object} The width and height to be used for the SVG
 */
const useSVGDimensions = ({
  dimensionsProvided,
  defaultDimensions,
  widthHeightRatio,
}: {
  dimensionsProvided: { width?: string | number; height?: string | number };
  defaultDimensions: { width: number; height: number };
  widthHeightRatio: number;
}) => {
  // Gets provided dimensions and converts them to numbers
  const providedWidth =
    typeof dimensionsProvided.width === "string"
      ? +dimensionsProvided.width.replace("px", "").trim()
      : dimensionsProvided.width;
  const providedHeight =
    typeof dimensionsProvided.height === "string"
      ? +dimensionsProvided.height.replace("px", "").trim()
      : dimensionsProvided.height;

  // If width and height are provided, return them
  if (providedWidth && providedHeight)
    return { width: providedWidth, height: providedHeight };

  // If width and height are not provided, return default
  if (!providedWidth && !providedHeight) return defaultDimensions;

  // If only width is provided, calculate height based on ratio
  if (providedWidth && !providedHeight)
    return {
      width: providedWidth,
      height:
        defaultDimensions.width > defaultDimensions.height
          ? providedWidth / widthHeightRatio
          : providedWidth * widthHeightRatio,
    };

  // If only height is provided, calculate width based on ratio
  return {
    height: providedHeight!,
    width:
      defaultDimensions.width > defaultDimensions.height
        ? providedHeight! * widthHeightRatio
        : providedHeight! / widthHeightRatio,
  };
};
export default useSVGDimensions;
