import React, { Fragment } from "react";

import { HtmlInline } from "@components/html";
import { IHtmlInlineProps } from "@models/component-props";

/**
 * Component used to convert ul or ol lists and any sub lists / nested elements into JSX elements.
 * It calls a function to do this which is subsequently called for any sub list found.
 *
 * @param {string} domain - The domain of the HTML content.
 * @param {string} htmlString - The HTML string to be converted into JSX elements.
 * @param {string} pageTitle - The title of the page.
 * @param {string} keyProvided - A unique key provided for the JSX elements.
 * @return {JSX.Element} The JSX elements generated from the HTML string.
 */
const HtmlList = ({
  domain,
  htmlString: html,
  pageTitle,
  keyProvided: pre,
}: IHtmlInlineProps) => {
  /**
   * This function processes HTML content to convert any HTML tags into their corresponding JSX elements.
   * It specifically targets list items and nested lists, adding span tags to the beginning and end of the list item and sub lists.
   * It then processes the list item's content, converting any HTML tags into their corresponding JSX elements.
   *
   * @param {string} e - The HTML content to be processed.
   * @param {string} i - A unique identifier used to generate keys for the JSX elements.
   * @return {JSX.Element} The processed JSX element.
   */
  const outputListElements = (e: string, i: string) => {
    // Default HTML norm props
    const htmlNormProps = {
      domain,
      pageTitle,
    };

    // Default key
    const key = `${i}`;

    /**
     * This regular expression is used to identify which parts of the HTML content
     * should be processed further to convert any HTML tags into their
     * corresponding JSX elements.
     *
     * It matches any of the following:
     *
     * - `<li.*?>(.*?)</li>`: a single list item with any attributes and any content
     * - `<li.*?><(ul|ol).*?>(?:(?!<(ul|ol)>).)*(?:(?!<\/(ul|ol)>).)<\/(ul|ol)></li>`: a list item containing a nested list
     */
    const listItemMatches = e.match(
      /<li(?:(?!<\/li>)(?!<(u|o)l.*?>(?:(?!<(u|o)l).)*(?:<\/(u|o)l>)).)+<\/li>|<li.*?>(.*?)<(?<t2>(ul|ol)).*?>(?:(?!<\k<t2>.*?>).)*(?:(?!<\/\k<t2>>).<\/\k<t2>>)<\/li>/gi
    );

    if (listItemMatches) {
      const liOutput = listItemMatches.map((li: string, x: number) => {
        // New key for list items
        const keyGeneratedFoLi = `${i}_${x}`;

        // Default element
        let curLi: JSX.Element = (
          <Fragment key={`Fragment_${keyGeneratedFoLi}`}></Fragment>
        );

        // This regular expression matches either a <ul> or an <ol> element and all
        // of its content until the closing </ul> or </ol> tag. It does not match
        // any nested <ul> or <ol> elements. This is done by using a negative
        // lookahead assertion "(?:(?!<ul).)*" which matches any character
        // (.) zero or more times (*) as long as it is not followed by "<ul".
        // This effectively prevents the regex from matching any nested lists.
        //
        // The regular expression is used to split the HTML content into separate
        // list items, and then each list item is processed further to convert
        // any HTML tags into their corresponding JSX elements.
        const ulOlRegex =
          /<ul.*?>(?:(?!<ul).)*(?:<\/ul>)|<ol.*?>(?:(?!<ol).)*(?:<\/ol>)/gi;

        if (li.match(ulOlRegex)) {
          // Add span tags to the beginning and end of the list item and sub lists
          const liHtml = li
            .replace(/<li.*?>/gi, "<li><span>")
            .replace(/(<\/li>)/gi, "</span></li>")
            .replace(/<(ul|ol).*?>/gi, "</span><$1>")
            .replace(/<\/(ul|ol)>/gi, "</$1><span>")
            .replace(/<span><\/span>/gi, "");

          // Check if there's a sub list in this list item
          const liUlMatch =
            liHtml.match(ulOlRegex) || liHtml.match(/<span.*?>(.*?)<\/span>$/i);
          curLi = liUlMatch ? (
            <Fragment key={`htmlNorm_li_fragment_${key}`}>
              {liUlMatch.map((liUl, y) => {
                // If it's just a span tag, return the HTMLNorm component
                return liUl.match(/^<span.*?>(.*?)<\/span>$/i) ? (
                  <HtmlInline
                    key={`htmlNorm_li_span_${key}`}
                    htmlString={liUl}
                    keyProvided={`${i}_${x}_${y}`}
                    {...htmlNormProps}
                  />
                ) : (
                  // If it's a nested list, call this function again with the list item html
                  outputListElements(liUl, `${i}_${x}_${y}`)
                );
              })}
            </Fragment>
          ) : (
            // There are no sub lists in this list item so just process
            // the list item's content
            <HtmlInline
              key={`htmlNorm_li_span_${key}`}
              htmlString={liHtml}
              keyProvided={`${i}_${x}`}
              {...htmlNormProps}
            />
          );
        } else {
          // There are no sub lists in this list item so just process
          // the list item's content
          const liHtml = li
            .replace(/<li.*?>/gi, "<li><span>")
            .replace(/(<\/li>)/gi, "</span></li>")
            .replace(/<span><\/span>/gi, "");
          curLi = (
            <HtmlInline
              key={`htmlNorm_li_${key}`}
              htmlString={liHtml}
              keyProvided={`${i}_${x}`}
              {...htmlNormProps}
            />
          );
        }

        // Return the li element
        return <li key={`li_${keyGeneratedFoLi}`}>{curLi}</li>;
      });

      // Return the ol/ul element
      return (
        <Fragment key={`Fragment_${key}`}>
          {e.startsWith("<ul") ? (
            <ul key={`ul_${key}`}>{liOutput}</ul>
          ) : (
            <ol key={`ol_${key}`}>{liOutput}</ol>
          )}
        </Fragment>
      );
    } // If there are no list items, return an empty fragment
    else return <Fragment key={`Fragment_${key}`}></Fragment>;
  };

  // Return the list output
  return outputListElements(html, pre);
};

export default HtmlList;
