import {
  IHtmlInlineProps,
  ILinkElementProps,
  IOuputInnerProps,
} from "@models/component-props";
import React, { Fragment } from "react";

import { Link } from "gatsby";

/**
 * Calls the outputInlineElements function with the provided props.
 * The outputInlineElements function processes a HTML string to convert
 * any HTML tags into their corresponding JSX elements.
 *
 * @param {IHtmlInlineProps} domain - The domain of the HTML content
 * @param {IHtmlInlineProps} htmlString - The HTML string to be processed
 * @param {IHtmlInlineProps} pageTitle - The title of the page
 * @param {IHtmlInlineProps} keyProvided - A unique key for the JSX element
 * @return {JSX.Element} The JSX element representation of the HTML string
 */
const HtmlInline = (props: IHtmlInlineProps) => {
  return outputInlineElements(props);
};

/**
 * This regular expression is used to identify which parts of the HTML content
 * should be processed further to convert any HTML tags into their
 * corresponding JSX elements.
 *
 * It matches any of the following:
 *
 * - `<span.*?>(.*?)<\/span>`: spans with any attributes and any content
 * - `<u.*?>(.*?)<\/u>`: underlined text with any attributes and any content
 * - `<em.*?>(.*?)<\/em>`: emphasized text with any attributes and any content
 * - `<strong.*?>(.*?)<\/strong>`: strong text with any attributes and any content
 * - `<a.*?>(.*?)<\/a>`: links with any attributes and any content
 * - `<hr.*?\/?>`: horizontal rules with any attributes and any content (but not self-closing)
 * - `<br.*?\/?>`: line breaks with any attributes and any content (but not self-closing)
 */
export const inlineTagRegex =
  /<span.*?>(.*?)<\/span>|<u.*?>(.*?)<\/u>|<em.*?>(.*?)<\/em>|<strong.*?>(.*?)<\/strong>|<a.*?>(.*?)<\/a>|<hr.*?\/?>|<br.*?\/?>/gi;

/**
 * This function processes the HTML string provided and checks for inline elements.
 * It then cleans the HTML string and converts the inline elements in the string into JSX elements.
 *
 * @param {IHtmlInlineProps} domain - The domain of the HTML content
 * @param {IHtmlInlineProps} htmlString - The HTML string to be processed
 * @param {IHtmlInlineProps} pageTitle - The title of the page
 * @param {IHtmlInlineProps} keyProvided - A unique key for the JSX element
 * @return {JSX.Element} The JSX element representation of the HTML string
 */
const outputInlineElements = ({
  domain,
  htmlString,
  pageTitle,
  keyProvided,
}: IHtmlInlineProps) => {
  // Default element, a fragment with no HTML
  let element: JSX.Element = (
    <Fragment key={`Fragment_${keyProvided}`}>
      {htmlString.replace(/(<([^>]+)>)/gi, "")}
    </Fragment>
  );

  // If the string matches any of the tags specified, update the element variable
  if (htmlString.match(inlineTagRegex)) {
    const cleanedElementString = cleanElementString(htmlString);

    const cleanedElementMatch = cleanedElementString.match(inlineTagRegex);
    if (cleanedElementMatch) {
      element = (
        <Fragment key={`Fragment_${keyProvided}`}>
          {cleanedElementMatch.map((elementString, x) => {
            const key = `${keyProvided}_${x}`;

            const outputInnerProps = {
              domain,
              pageTitle,
              keyProvided: key,
              normMatches: inlineTagRegex,
              htmlString: elementString,
            };

            return elementString.startsWith("<hr") ? (
              <hr key={`hr_${key}`} />
            ) : elementString.startsWith("<br") ? (
              <br key={`br_${key}`} />
            ) : elementString.startsWith("<strong") ? (
              <strong key={`strong_${key}`}>
                {outputInlineHtml({ ...outputInnerProps, tag: "strong" })}
              </strong>
            ) : elementString.startsWith("<em") ? (
              <em key={`em_${key}`}>
                {outputInlineHtml({ ...outputInnerProps, tag: "em" })}
              </em>
            ) : elementString.startsWith("<u") ? (
              <u key={`u_${key}`}>
                {outputInlineHtml({ ...outputInnerProps, tag: "u" })}
              </u>
            ) : elementString.startsWith("<a") ? (
              getLinkElement({ ...outputInnerProps, tag: "a", x })
            ) : (
              <Fragment key={`span_${key}`}>
                {outputInlineHtml({ ...outputInnerProps, tag: "span" })}
              </Fragment>
            );
          })}
        </Fragment>
      );
    }
  }

  return element;
};

/**
 * Cleans an HTML element string by replacing and wrapping certain tags to ensure consistent formatting.
 *
 * @param {string} elementString - The HTML element string to be cleaned.
 * @return {string} The cleaned HTML element string.
 */
const cleanElementString = (elementString: string) => {
  return (
    elementString
      // Any span tags that just have a class, replace with their content
      .replace(/<span class=".*?">(.*?)<\/span>/gi, "$1")
      // The HTML editor uses span tags for underlining, replace these with u tags
      // Replace span tags with an underline style with a u tag and place a span tag around it
      .replace(
        /<span style="text-decoration:.*?underline;">(.*?)<\/span>/gi,
        "</span><u><span>$1</span></u><span>"
      )
      // Place span tags around em, strong and u tags.
      // This is already done with u tags when converting underline span tags to u tags
      //.replace(/<u.*?>(.*?)<\/u>/gi, "</span><u><span>$1</span></u><span>") //html editor doesn't use u tag, uses span style="text-decoration:none", so this isn't needed
      .replace(/<em.*?>(.*?)<\/em>/gi, "</span><em><span>$1</span></em><span>")
      .replace(
        /<strong.*?>(.*?)<\/strong>/gi,
        "</span><strong><span>$1</span></strong><span>"
      )
      // Place span tags around a link
      .replace(/<a(.*?)>(.*?)<\/a>/gi, "</span><a $1><span>$2</span></a><span>")
      // Add opening span tags to inside tags that contain a link
      // .replace(
      //   /<strong>(.*?)<a(.*?)>(.*?)<\/a>(.*?)<\/strong>/gi,
      //   "<strong><span>$1<a $2>$3</a>$4</span></strong>"
      // )
      // .replace(
      //   /<em>(.*?)<a(.*?)>(.*?)<\/a>(.*?)<\/em>/gi,
      //   "<em><span>$1<a $2>$3</a>$4</span></em>"
      // )
      // .replace(
      //   /<u>(.*?)<a(.*?)>(.*?)<\/a>(.*?)<\/u>/gi,
      //   "<u><span>$1<a $2>$3</a>$4</span></u>"
      // )
      // Place span tags around hr or br tags
      .replace(/<(h|b)r.*?\/*>/gi, "</span><$1r/><span>")
      // Remove any occurrences of duplicate tags inside each other
      .replace(/<span><span>(.*?)<\/span><\/span>/gi, "<span>$1</span>")
      .replace(/<u><u>(.*?)<\/u><\/u>/gi, "<u>$1</u>")
      .replace(/<em><em>(.*?)<\/em><\/em>/gi, "<em>$1</em>")
      .replace(
        /<strong><strong>(.*?)<\/strong><\/strong>/gi,
        "<strong>$1</strong>"
      )
      // Remove any empty span, underline, em, or strong tags
      .replace(/<span><\/span>|<u><\/u>|<em><\/em>|<strong><\/strong>/gi, "")
  );
};

/**
 * Extracts the inner content of a specified HTML tag from a given string.
 *
 * @param {"span" | "strong" | "em" | "u" | "a"} tag - The type of HTML tag to extract from.
 * @param {string} htmlString - The string containing the HTML tag to extract from.
 * @return {string} The inner content of the specified HTML tag.
 */
const getInlineHtml = (
  tag: "span" | "strong" | "em" | "u" | "a",
  htmlString: string
) => {
  const replace = `<${tag}.*?>(.*?)</${tag}>`;
  const regex = new RegExp(replace, "gi");
  return htmlString.replace(regex, "$1");
};

/**
 * Processes the inner content of an HTML element based on the provided tag and HTML string.
 *
 * @param {string} tag - The type of HTML tag to process.
 * @param {string} htmlString - The string containing the HTML tag to process.
 * @param {boolean} keyProvided - Whether a key is provided for the element.
 * @param {RegExp} normMatches - A regular expression to match for normalizing the HTML.
 * @param {object} contentImages - An object containing information about the content images.
 * @param {string} domain - The domain of the webpage.
 * @param {string} pageTitle - The title of the webpage.
 * @return {string} The processed inner content of the HTML element.
 */
const outputInlineHtml = ({
  tag,
  htmlString,
  keyProvided,
  domain,
  pageTitle,
}: IOuputInnerProps) => {
  // Get the inner text of the element
  const inner = getInlineHtml(tag, htmlString);

  // If there's still a match for the specified tags, process the HTML for the inner tags
  return inner.match(inlineTagRegex)
    ? outputInlineElements({
        domain,
        htmlString: inner,
        keyProvided,
        pageTitle,
      })
    : inner // Otherwise return the inner text
        .replace(/(<([^>]+)>)/gi, "")
        .replace(/&lt;/gi, "<")
        .replace(/&gt;/gi, ">");
};

/**
 * Checks if the link is internal and returns either a Link component if it is,
 * or an anchor tag with the relevant attributes if it is not.
 *
 * @param {ILinkElementProps} props - The properties of the link element.
 * @return {JSX.Element} The JSX element representing the link.
 */
const getLinkElement = ({
  domain,
  htmlString,
  keyProvided,
  pageTitle,
  tag,
  x,
}: ILinkElementProps) => {
  let elJsx = <Fragment key={`link_${keyProvided}`}></Fragment>;

  const outputInnerProps = {
    domain,
    htmlString,
    keyProvided: `${keyProvided}_${x}`,
    normMatches: inlineTagRegex,
    pageTitle,
    tag,
  };

  // Check if the link has a href attribute
  const matchHref = htmlString.match(/href="(.*?)"/);
  if (matchHref) {
    // Get the href of the link
    const href = matchHref[0].replace(/href="(.*?)"/gi, "$1").trim();

    // Remove the protocol from the domain for checking if the link is internal
    const domainWoProtocol = domain
      .replace(/^https*:\/\//i, "") // Aesterisk is included so it can be http or https
      .replace("www.", ""); // Remove the www. if it exists

    // Remove the protocol and site domain from the href
    let hrefWoDomain = href
      .replace(domainWoProtocol, "") // Remove the site domain from the href
      .replace(/^https*:\/\//i, "") // Aesterisk is included so it can be http or https
      .replace("www.", ""); // Remove the www. if it exists

    // Make sure it starts with a /
    if (!hrefWoDomain.startsWith("/")) hrefWoDomain = `/${hrefWoDomain}`;

    // Check if the link is internal
    const isInternalLink =
      href.startsWith("/") ||
      href.startsWith(domainWoProtocol) ||
      href.startsWith(`https://${domainWoProtocol}`) ||
      href.startsWith(`http://${domainWoProtocol}`) ||
      href.startsWith(`https://www.${domainWoProtocol}`) ||
      href.startsWith(`http://www.${domainWoProtocol}`);

    // Get the title of the link if it exists
    const matchTitle = htmlString.match(/title="(.*?)"/gi);
    const titleText = matchTitle
      ? matchTitle[0].replace(/title="(.*?)"/gi, "$1").trim()
      : "";

    // Set the title of the link to something relating to the link if no title text is supplied
    const linkTitle =
      titleText.length > 0
        ? titleText
        : !isInternalLink
        ? href.startsWith("tel:")
          ? `Call ${href.replace("tel:", "")}`
          : href.match(/careclub/gi)
          ? "Visit Care Club"
          : "View External Link"
        : hrefWoDomain === "/"
        ? "Visit the home page"
        : hrefWoDomain.startsWith("/heating-and-plumbing/plumbing")
        ? "View our plumbing services"
        : hrefWoDomain.startsWith("/heating-and-plumbing/heating")
        ? "View our heating services"
        : hrefWoDomain.startsWith("/heating-and-plumbing/boilers")
        ? "View our boiler services"
        : hrefWoDomain.startsWith("/heating-and-plumbing/drains")
        ? "View our drain services"
        : hrefWoDomain.startsWith("/electrical")
        ? "View our electrical services"
        : hrefWoDomain.startsWith("/locks")
        ? "View our lock services"
        : hrefWoDomain.startsWith("/about-us/news/")
        ? "View news post"
        : hrefWoDomain.startsWith("/about-us")
        ? "Find out more about us"
        : hrefWoDomain.startsWith("/advice-hub")
        ? "Read our latest advice"
        : `View our ${hrefWoDomain
            .substring(1)
            .split("/")[0]
            .replace(/-/g, " ")}`;

    // Check if the link has a download attribute
    const downloadIncluded = htmlString.match(/download="(.*?)"/gi);

    // Check if the link has a rel attribute
    const matchRel = htmlString.match(/rel="(.*?)"/gi);

    // Check if the link is an internal link and the download attribute is not included
    // Return the link element
    if (isInternalLink && !downloadIncluded) {
      elJsx = (
        <Link key={`link_${keyProvided}`} to={hrefWoDomain} title={linkTitle}>
          {outputInlineHtml(outputInnerProps)}
        </Link>
      );
    } else {
      // Default link settings
      let linkSettings: {
        key: string;
        href: string;
        title: string;
        target?: string;
        rel?: string;
        download?: string;
      } = {
        key: `a_${keyProvided}`,
        href: `${downloadIncluded && isInternalLink ? domain : ""}${href}`,
        title: linkTitle,
      };

      // If it's not a phone number or a download link,
      // set the target to _blank and rel to noopener noreferrer
      if (!href.startsWith("tel:") && !downloadIncluded)
        linkSettings = {
          ...linkSettings,
          target: "_blank",
          rel: `noopener noreferrer ${
            matchRel
              ? matchRel[0]
                  .replace(/rel="(.*?)"/gi, "$1")
                  .replace("noopener", "")
                  .replace("noreferrer", "")
              : ""
          }`,
        };

      // If the download attribute is included, set the rel to download
      if (downloadIncluded)
        linkSettings = {
          ...linkSettings,
          download: "download",
          rel: "nofollow",
        };

      // Return the anchor element
      elJsx = <a {...linkSettings}>{outputInlineHtml(outputInnerProps)}</a>;
    }
  } // If no href attribute is found, return the contents of the element
  else
    elJsx = (
      <Fragment key={`span_${keyProvided}`}>
        {outputInlineHtml(outputInnerProps)}
      </Fragment>
    );

  return elJsx;
};

export default HtmlInline;
