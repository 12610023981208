import { graphql, useStaticQuery } from "gatsby";

import { IAboutUsEngineerProcessHook } from "@models/hooks";

/**
 * Retrieves all about us engineer process items, sorted by order_num in ascending order ( lowest first ),
 * and filtered to only include published items.
 *
 * @returns {IAboutUsEngineerProcessHook} Gatsby data object containing all about us engineer process items.
 */
const useEngineerProcess = () => {
  const data = useStaticQuery<IAboutUsEngineerProcessHook>(graphql`
    {
      allAboutUsEngineerProcess(
        sort: { order: ASC }
        filter: { status: { eq: "published" } }
      ) {
        edges {
          node {
            id
            icon
            title
            tagline
            content
            value_image {
              id
              title
              imageFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `);
  return data;
};

export default useEngineerProcess;
