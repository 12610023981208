import * as styles from "./RecaptchaSkeleton.module.css";

import React from "react";

/**
 * A skeleton for a recaptcha component.
 *
 * Since the recaptcha component contains third party scripts that block the main thread,
 * it is lazy loaded, so this component is used to display a skeleton before it loads.
 *
 * @returns A React component to display a skeleton of a recaptcha component.
 */
const RecaptchaSkeleton = () => {
  return (
    <div className={`skeleton ${styles.recaptchaSkeleton}`}>
      <div className={`skeletonBone ${styles.recaptchaSkeletonBone}`}></div>
    </div>
  );
};

export default RecaptchaSkeleton;
