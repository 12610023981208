import { graphql, useStaticQuery } from "gatsby";

import { ILocationItemHook } from "@models/hooks";

/**
 * Retrieves location items from the static query.
 *
 * @return {Array<{ node: ILocationItemDetails }>} An array of location items.
 */
const useLocationItems = () => {
  const data = useStaticQuery<ILocationItemHook>(graphql`
    {
      allLocationPosts {
        edges {
          node {
            id
            date_created
            date_updated
            title
            excerpt
            slug
            hero {
              id
              title
              imageFile {
                childImageSharp {
                  gatsbyImageData(width: 300, height: 300, layout: FIXED)
                }
              }
            }
          }
        }
      }
    }
  `);
  return data.allLocationPosts.edges;
};
export default useLocationItems;
