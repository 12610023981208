import * as colourStyles from "../SVGStyles/SVGColours.module.css";
import * as styles from "./SVGBoiler.module.css";

import { ISVGComponentProps } from "@models/component-props";
import React from "react";
import { SVGFromIconName } from "@components/svgs";
import { useSVGColour } from "@hooks";

/**
 * A component to render a boiler SVG based on a given direction and colour.
 * This component ensures the styles specifc to this icon are applied.
 *
 * @param {ISVGComponentProps} props
 * @prop {ISVGColours} [colour] - The colour of the SVG.
 * @prop {ISVGProps} [svgProps] - Additional props to pass to the underlying SVG
 * @returns {React.ReactElement} The boiler SVG
 */
const SVGBoiler = ({ colour, svgProps }: ISVGComponentProps) => {
  // The class name based on the colour provided
  const className = `${styles.svgBoiler} ${useSVGColour(
    colourStyles,
    colour
  )} ${svgProps.className || ""}`;

  // Return the SVG code based on the icon and the class name
  return (
    <SVGFromIconName icon={"boiler"} svgProps={{ ...svgProps, className }} />
  );
};

export default SVGBoiler;
