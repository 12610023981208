import queryString from "query-string";

/**
 * Extracts and returns the tag and sort query string parameters from the
 * current location.search, if provided. If no location is provided, or if
 * neither parameter is present, returns default values of an empty string for
 * tag and "date_desc" for sort.
 * @param {Location | undefined} location - The location object from which to
 *   extract the query string parameters.
 * @returns {{tag: string, sort: string}} An object with two properties: tag,
 *   the value of the tag query string parameter, and sort, the value of the
 *   sort query string parameter.
 */
const useBlogQueryString = (location: Location | undefined) => {
  // If location is defined, extract the query string parameters from it
  // otherwise return default values
  if (location) {
    // Parse the query string from the URL
    const parsedQuery = queryString.parse(location.search);

    // Extract the tag and sort query string parameters from the parsed query string
    const { tag, sort } = parsedQuery;

    // Return an object with the tag and sort query string parameters
    return {
      // If tag is an array, return the first element in the array, otherwise return tag
      tag: Array.isArray(tag) ? tag[0] || "" : tag || "",

      // If sort is an array and is valid, return the first element in the array, otherwise return sort
      sort: Array.isArray(sort)
        ? sort[0] && ["date_asc", "date_desc"].includes(sort[0])
          ? sort[0]
          : "date_desc"
        : sort && ["date_asc", "date_desc"].includes(sort)
        ? sort
        : "date_desc",
    };
  } else return { tag: "", sort: "date_desc" };
};
export default useBlogQueryString;
