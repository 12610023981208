import { IsHomeAction, IsHomeContext } from "@store";

import { useContext } from "react";

let customDispatch: ((_action: IsHomeAction) => void) | undefined;

/**
 * Initializes the custom dispatch function. To be used on the route
 * update function in gatsby-browser as the context is not available
 * there since it's not a react component.
 *
 * @param {(_action: IsHomeAction) => void} dispatch - The dispatch function to be used for custom dispatching.
 * @return {void} No return value.
 */
export const initializeCustomDispatch = (
  dispatch: (_action: IsHomeAction) => void
) => {
  customDispatch = dispatch;
};

/**
 * Returns the custom dispatch function.
 *
 * @return {(_action: IsHomeAction) => void} The custom dispatch function.
 */
export const getCustomDispatch = () => customDispatch;

/**
 * Custom hook to use the IsHome context.
 *
 * @throws Will throw an error if the hook is used outside of a IsHomeProvider.
 * @returns {IsHomeState} The context value containing the state and setter function.
 */
export const useIsHome = () => {
  const context = useContext(IsHomeContext);
  if (context === undefined) {
    throw new Error("useIsHome must be used within a IsHomeProvider");
  }
  return context;
};
