import { graphql, useStaticQuery } from "gatsby";

import { IAccreditationsHook } from "@models/hooks";

/**
 * Retrieves all accreditations for the site, sorted by order_num.
 *
 * @returns {IAccreditationsHook} Gatsby data object containing all accreditations.
 */
const useAccreditations = () => {
  const data = useStaticQuery<IAccreditationsHook>(graphql`
    {
      allAccreditations(sort: { order_num: ASC }) {
        edges {
          node {
            id
            title
            order_num
            logo {
              id
              title
              imageFile {
                childImageSharp {
                  gatsbyImageData(height: 62)
                }
              }
            }
          }
        }
      }
    }
  `);
  return data;
};

export default useAccreditations;
