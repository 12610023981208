import * as styles from "./IconSkeleton.module.css";

import React from "react";

/**
 * A skeleton component to show in place of an icon while it is loading.
 *
 * @param {number} size The size of the skeleton in pixels.
 * @param {number} [width] The width of the skeleton in pixels. Defaults to size.
 * @param {number} [height] The height of the skeleton in pixels. Defaults to size.
 * @param {string} [className] Additional class names to apply to the component.
 * @returns {ReactElement} The icon skeleton component.
 */
const IconSkeleton = ({
  size,
  width = undefined,
  height = undefined,
  className = "",
}: {
  size: number;
  width?: number;
  height?: number;
  className?: string;
}) => {
  return (
    <div
      className={`skeleton ${styles.iconSkeleton} ${className}`}
      style={{ width: width || size, height: height || size }}
    >
      <div className={`skeletonBone ${styles.iconSkeletonBone}`}></div>
    </div>
  );
};

export default IconSkeleton;
