import { IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";

/**
 * Used instead of GatsbyImage for above the fold images.
 * GatsbyImage is slower as it uses JavaScript to lazy load the images, even when eager loading:
 * https://robertmarshall.dev/blog/do-not-use-gatsby-image-above-the-fold/
 * @param props The hero image returned by the post, the image alt, and
 * any additional class names required
 * @returns A div with either a picture tag, an img tag, or nothing if no image is supplied
 */
const ManualImage = ({
  image = null,
  imageTitle = "",
  className,
}: {
  imageTitle?: string;
  image?: IGatsbyImageData | null;
  className?: string;
}) => {
  // The source of the picture tag
  const imageSource =
    image && image.images.sources && image.images.sources.length > 0 ? (
      <source
        type={image.images.sources[0].type}
        srcSet={image.images.sources[0].srcSet}
        sizes={image.images.sources[0].sizes}
      />
    ) : undefined;

  // The img in the picture tag / the img tag to use if sources is undefined
  const imageFallback =
    image && image.images.fallback ? (
      <img
        width={image.width}
        height={image.height}
        sizes={image.images.fallback.sizes}
        decoding="async"
        loading="eager"
        src={image.images.fallback.src}
        srcSet={image.images.fallback.srcSet}
        alt={imageTitle}
      />
    ) : undefined;

  // For full width images, an element with padding is needed so the image doesn't
  // get cut off part way.
  const imagePadding =
    image?.layout === "fullWidth" ? (
      <div
        aria-hidden="true"
        style={{ paddingTop: `${image.height * 100}%` }}
      ></div>
    ) : (
      ""
    );
  return (
    <div className={className}>
      {imagePadding}
      {imageSource && imageFallback ? (
        // Since we're above the fold, the performance is better when the picture
        // tag is generated manually. GatsbyImage works better for images below the fold
        // as it involves JavaScript for lazy loading.
        <picture>
          {imageSource}
          {imageFallback}
        </picture>
      ) : (
        // If the image sources aren't defined, this usually means that a webp image has
        // been uploaded to Directus. The users shouldn't really upload webp images as there's
        // no fallback to jpg/png for older browsers when doing this, which is why the sources
        // parameter doesn't get populated (only the data for the image tag is supplied).
        // If the fallback isn't defined, then no image was passed through, so an empty string can be supplied.
        imageFallback || ""
      )}
    </div>
  );
};

export default ManualImage;
