import * as buttonLinkStyles from "./ButtonLink.module.css";
import * as buttonStyles from "../Button.module.css";

import { ButtonStyle, IButtonLinkProps } from "@models/component-props";
import { usePhoneAndAccountCode, useSiteMetadata } from "@hooks";

import { Link } from "gatsby";
import { LogToGrafana } from "@classes";
import React from "react";
import { ScrollingLink } from "@components/ui";
import { useLocation } from "@reach/router";

type LinkSettings = {
  title: string;
  className: string;
  href?: string;
  target?: string;
  to?: string;
  rel?: string;
};

/**
 * Renders a link with a button style. Handles internal and external links,
 * phone numbers, and scrolling links.
 *
 * @param {object} props - The component props.
 * @param {string} props.link - The link URL.
 * @param {string} props.buttonText - The button text.
 * @param {string} props.title - The link title.
 * @param {ButtonStyle} [props.buttonStyle=ButtonStyle.Primary] - The button style.
 * @param {string} [props.className=""] - Additional class names.
 * @return {JSX.Element} The rendered link element.
 */
const ButtonLink = ({
  link,
  title,
  buttonStyle = ButtonStyle.Primary,
  className = "",
  onClick = undefined,
}: IButtonLinkProps) => {
  // Get necessary data from hooks
  const location = useLocation();
  const siteMetadata = useSiteMetadata();
  const { phoneNumber } = usePhoneAndAccountCode();
  const logToGrafana = new LogToGrafana();

  // Remove the protocol and "www." from the domain
  const domainWithoutProtocol = siteMetadata.domain
    .replace(/^https*:\/\//i, "")
    .replace("www.", "");

  // Check if the link is internal or external
  const isInternalLink =
    link.startsWith("/") || link.includes(domainWithoutProtocol);

  // Replace the "#phone#" placeholder with the phone number
  const formattedPhoneNumber = phoneNumber.replace(/\s/g, "");
  const formattedLink = link.replace("#phone#", `tel:${formattedPhoneNumber}`);

  // Clean up the title
  const cleanedTitle = title.replace(/#phone#/gi, phoneNumber);

  // Map button styles to CSS classes
  const buttonClassNames = {
    [ButtonStyle.Primary]: buttonStyles.button1,
    [ButtonStyle.Secondary]: buttonStyles.button2,
    [ButtonStyle.Tertiary]: buttonStyles.button3,
    [ButtonStyle.TransparentBackgroundWhiteBorder]: buttonStyles.button4,
    [ButtonStyle.TransparentBackgroundGreenBorder]: buttonStyles.button5,
  };

  // Determine the CSS class for the button based on the button style
  const buttonClassName = buttonClassNames[buttonStyle] || buttonStyles.button1;

  // Set up the link settings
  const linkSettings: LinkSettings = {
    title: cleanedTitle,
    className: `${buttonStyles.button} ${buttonClassName} ${buttonLinkStyles.buttonLink} ${className}`,
  };

  if (!isInternalLink) {
    linkSettings.href = formattedLink;

    if (!formattedLink.startsWith("tel")) {
      linkSettings.target = "_blank";
      linkSettings.rel = "noreferrer";
    }
  } else {
    linkSettings.to = formattedLink.startsWith("/")
      ? formattedLink
      : `/${formattedLink}`;
  }

  // Handle click event for tel links
  const handleTelClick = () => {
    logToGrafana.logClickToCallEvent({
      ...linkSettings,
      clickOrigin: location.href,
    });
    if (onClick) onClick();
  };

  return (
    <>
      {isInternalLink ? (
        // Use Gatsby Link when link is internal
        <Link
          to={linkSettings.to!}
          title={linkSettings.title}
          className={linkSettings.className}
          onClick={onClick}
        >
          {linkSettings.title}
        </Link>
      ) : formattedLink.startsWith("#") ? (
        // Use scrolling link when link is an anchor
        <ScrollingLink
          idToScrollTo={formattedLink}
          className={linkSettings.className}
          title={linkSettings.title}
          onClick={onClick}
        >
          {linkSettings.title}
        </ScrollingLink>
      ) : (
        // Use anchor tag when link is external
        <a
          href={linkSettings.href}
          title={linkSettings.title}
          className={linkSettings.className}
          target={linkSettings.target}
          rel={linkSettings.rel}
          onClick={formattedLink.startsWith("tel") ? handleTelClick : onClick}
        >
          {linkSettings.title}
        </a>
      )}
    </>
  );
};

export default ButtonLink;
