import * as colourStyles from "../SVGStyles/SVGColours.module.css";
import * as styles from "./SVGCircle.module.css";

import {
  ISVGCircleComponentProps,
  ISVGIconType,
} from "@models/component-props";

import React from "react";
import { SVGFromIconName } from "@components/svgs";

/**
 * A component to render a circle SVG based on a given symbol inside the circle and a colour
 *
 * @param {ISVGCircleComponentProps} props
 * @prop {string} symbolInside - The symbol inside the circle: "tick", "cross" or "question-mark"
 * @prop {ISVGColours} [colour] - The colour of the circle.
 * @prop {ISVGProps} [svgProps] - Additional props to pass to the underlying SVG
 * @returns {React.ReactElement} The circle SVG with the given symbol inside
 */
const SVGCircle = ({
  symbolInside,
  colour,
  svgProps,
}: ISVGCircleComponentProps) => {
  // The icon name based on the symbol inside the circle
  const icon: ISVGIconType = `${symbolInside}-inside-circle`;

  // The class name based on the colour provided
  // Circle icons can be red and amber as well as the other colours
  // so the useSVGColour isn't used here
  const className = `${styles.svgCircle} ${
    colour === "blue"
      ? colourStyles.svgBlue
      : colour === "dark-blue"
      ? colourStyles.svgDarkBlue
      : colour === "dark-green"
      ? colourStyles.svgDarkGreen
      : colour === "green"
      ? colourStyles.svgGreen
      : colour === "light-blue"
      ? colourStyles.svgLightBlue
      : colour === "white"
      ? colourStyles.svgWhite
      : colour === "black"
      ? colourStyles.svgBlack
      : colour === "red"
      ? colourStyles.svgRed
      : colour === "amber"
      ? colourStyles.svgAmber
      : ""
  } ${svgProps.className || ""}`;

  // Return the SVG code based on the icon and the class name
  return <SVGFromIconName icon={icon} svgProps={{ ...svgProps, className }} />;
};

export default SVGCircle;
