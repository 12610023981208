import { ApplicationFormType, IFormField } from "@models/forms";
import React, { ReactNode, createContext, useReducer } from "react";

/**
 * Interface for the action to reset the application form state.
 */
export interface ResetApplicationFormAction {
  type: "RESET_FORM";
}

/**
 * Interface for the action to set a value in the application form state.
 */
export interface SetApplicationFormValueAction {
  type: "SET_VALUE";
  payload: {
    key: keyof ApplicationFormType;
    value: IFormField;
  };
}

/**
 * Combination of the action types for the application form.
 */
type ApplicationFormAction =
  | SetApplicationFormValueAction
  | ResetApplicationFormAction;

/**
 * Interface for the context value containing the state and dispatch function.
 */
interface ApplicationFormContextValue {
  state: ApplicationFormType;
  dispatch: React.Dispatch<ApplicationFormAction>;
}

export const allOpportunityTypes = ["partner", "engineer", "head-office"];
const engineerTypes = ["partner", "engineer"];
const headOfficeTypes = ["head-office"];

const initialApplicationFormState: ApplicationFormType = {
  hdnDate: { valid: true, value: "", type: allOpportunityTypes }, // Defaults to true as there is a separate validity check for these fields
  hdnSite: { valid: true, value: "", type: allOpportunityTypes }, // Defaults to true as there is a separate validity check for these fields
  recaptchaToken: { valid: true, value: "", type: allOpportunityTypes }, // Defaults to true as there is a separate validity check for these fields
  website: { valid: null, value: "", type: allOpportunityTypes },
  hdnType: { valid: null, value: "", type: allOpportunityTypes },
  txtForename: { valid: null, value: "", type: allOpportunityTypes },
  txtSurname: { valid: null, value: "", type: allOpportunityTypes },
  txtEmail: { valid: null, value: "", type: allOpportunityTypes },
  txtPhone: { valid: null, value: "", type: allOpportunityTypes },
  txtProperty: { valid: null, value: "", type: engineerTypes },
  txtStreet: { valid: null, value: "", type: engineerTypes },
  txtTown: { valid: null, value: "", type: engineerTypes },
  txtPostcode: { valid: null, value: "", type: engineerTypes },
  txtCoverLetter: { valid: null, value: "", type: headOfficeTypes },
  fileCV: { valid: null, value: false, type: ["head-office", "engineer"] },
  chkTradesPlumbing: { valid: null, value: false, type: engineerTypes },
  chkTradesDrainage: { valid: null, value: false, type: engineerTypes },
  chkTradesHeating: { valid: null, value: false, type: engineerTypes },
  chkTradesGasBoilerInstall: { valid: null, value: false, type: engineerTypes },
  chkTradesLocksmith: { valid: null, value: false, type: engineerTypes },
  chkTradesElectrician: { valid: null, value: false, type: engineerTypes },
  selYearsExperience: { valid: null, value: "", type: allOpportunityTypes },
};

/**
 * Context to provide application form state and dispatch function.
 */
const ApplicationFormContext = createContext<
  ApplicationFormContextValue | undefined
>(undefined);

/**
 * Reducer function to handle application form actions.
 *
 * @param state - The current state of the application form.
 * @param action - The action to be processed.
 * @returns The new state of the application form.
 */
const applicationFormReducer = (
  state: ApplicationFormType,
  action: ApplicationFormAction
): ApplicationFormType => {
  switch (action.type) {
    case "SET_VALUE":
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          valid: action.payload.value.valid,
          value: action.payload.value.value,
        },
      };
    case "RESET_FORM":
      return initialApplicationFormState;
    default:
      return state;
  }
};

/**
 * Provider component to wrap the application form context around children components.
 *
 * @param children - The children components to be wrapped.
 * @returns The provider component with the context.
 */
const ApplicationFormProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(
    applicationFormReducer,
    initialApplicationFormState
  );

  return (
    <ApplicationFormContext.Provider value={{ state, dispatch }}>
      {children}
    </ApplicationFormContext.Provider>
  );
};

export { ApplicationFormProvider, ApplicationFormContext };
