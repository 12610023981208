import { graphql, useStaticQuery } from "gatsby";

import { ILocationPhoneAndAccountCodeHook } from "@models/hooks";

/**
 * Retrieves all location phone numbers and account codes from the GraphQL API.
 *
 * @returns {ILocationPhoneAndAccountCodeDetails[]} An array of objects containing the id, slug, phone number and account code of each location.
 */
const useLocationPhoneAndAccountCodes = () => {
  const data = useStaticQuery<ILocationPhoneAndAccountCodeHook>(graphql`
    {
      allLocationPosts {
        edges {
          node {
            id
            slug
            phone_number
            account_code
          }
        }
      }
    }
  `);
  return data.allLocationPosts.edges.map((x) => x.node);
};
export default useLocationPhoneAndAccountCodes;
