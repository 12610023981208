import * as styles from "./FileInput.module.css";

import { FormDispatches, FormTypes } from "@models/forms";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { useFormDispatch } from "@hooks";

/**
 * A file input component that can be used to create a form field with a file input.
 * The component will validate the input based on the accept and required properties
 * and will dispatch the value to the form state if the dispatch is not null. The
 * component will also show an error message if the input is invalid.
 *
 * @param {string} title The label text for the input element.
 * @param {string} name The name of the form field.
 * @param {string} placeholder The placeholder text for the input element.
 * @param {FormDispatches} [dispatch] The dispatch function to use to update the form state.
 * @param {FormTypes} [formType] The type of form to update.
 * @param {(_files: FileList | null) => void} setFileState The function to call
 *    to set the state of the selected files.
 * @param {boolean} [required] Whether the field is required.
 * @param {boolean} [incompleteForm] Whether the form is incomplete.
 * @param {{mime: string; ext: string}[]} [accept] The accepted file types.
 * @return {JSX.Element} The file input component.
 */
const FileInput = ({
  title,
  name,
  placeholder,
  dispatch = null, //used when part of a form
  formType = undefined,
  setFileState = undefined,
  required = true,
  incompleteForm = false,
  accept = [],
}: {
  title: string;
  name: string;
  placeholder: string;
  dispatch?: FormDispatches;
  formType?: FormTypes;
  setFileState?: (_files: FileList | null) => void;
  required?: boolean;
  incompleteForm?: boolean;
  accept?: {
    mime: string;
    ext: string;
  }[];
}) => {
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [placeholderText, setPlaceholderText] = useState(placeholder); // Placeholder changes when files are selected
  const [errorText, setErrorText] = useState("");

  // Ref for the input element so we can check if it's valid
  const inputRef = useRef<HTMLInputElement>(null);

  // Accepted file types
  const acceptMimes = accept.map((a) => {
    return a.mime;
  });
  const acceptExt = accept.map((a) => {
    return a.ext;
  });

  // Check if the input is valid
  const checkIsValid = useCallback(() => {
    let curIsValid = true;
    let curErrorText = "";
    const files = inputRef.current ? inputRef.current.files : null;
    // If required and no files are selected, set as invalid
    if (required && ((files && files.length === 0) || !files)) {
      curIsValid = false;
      curErrorText = "This field is required";
    } else if (!acceptMimes.includes((files as FileList)[0].type)) {
      // If the file type is not valid, set as invalid
      curIsValid = false;
      curErrorText = `This field only accepts ${acceptExt
        .slice(0, acceptExt.length - 1)
        .join(", ")} and ${acceptExt[acceptExt.length - 1]} files`;
    } else if ((files as FileList)[0].size > 10485760) {
      // If the file is too large (over 10MB), set as invalid
      curIsValid = false;
      curErrorText =
        "The file you provided is too large, please supply a file smaller than 10MB";
    }
    // Set the states
    setIsValid(curIsValid);
    setErrorText(curErrorText);
    setPlaceholderText(curIsValid ? (files as FileList)[0].name : placeholder);
    if (setFileState) setFileState(curIsValid ? files : null);

    // Update the form
    if (dispatch && formType)
      useFormDispatch({
        name,
        value: {
          valid: curIsValid,
          value: curIsValid ? (files as FileList)[0] : false,
        },
        formType,
        dispatch,
      });
  }, [placeholder, required, name, setFileState, acceptExt, acceptMimes]);

  useEffect(() => {
    // Sets any non-required fields that are blank as valid
    if (
      !required &&
      (!inputRef.current ||
        (inputRef.current.files && inputRef.current.files.length === 0))
    ) {
      // Set the states
      setIsValid(true);
      setErrorText("");
      setPlaceholderText(placeholder);
      if (setFileState) setFileState(null);

      // Update the form
      if (dispatch && formType)
        useFormDispatch({
          name,
          value: { valid: true, value: false },
          formType,
          dispatch,
        });
    }

    // If the form is incomplete, check if the input is valid
    if (incompleteForm) checkIsValid();
  }, [required, incompleteForm, placeholder, setFileState]);

  // Check if the input is valid on change
  const onChangeHandler = () => {
    checkIsValid();
  };

  // Set the attributes for the input
  let attr: {
    ref: React.RefObject<HTMLInputElement>;
    type: string;
    name: string;
    className: string;
    onChange: () => void;
    accept?: string;
  } = {
    ref: inputRef,
    type: "file",
    name,
    className: `${styles.input} ${
      isValid ? styles.inputValid : isValid === false ? styles.inputInvalid : ""
    }`,
    onChange: onChangeHandler,
  };

  // Set the accept attribute for the input if it exists
  if (accept.length > 0)
    attr = {
      ...attr,
      accept: acceptMimes.join(","),
    };

  return (
    <div className={styles.inputContainer}>
      {/* Label for the input */}
      <label htmlFor={name} className={styles.inputTitle}>
        {title}
      </label>

      {/* Area for drag and drop */}
      <div className={styles.inputPlaceholder}>
        <p className={styles.inputPlaceholderText}>{placeholderText}</p>
      </div>

      {/* Input field, not shown to the user, they see the area for drag and drop */}
      <input {...attr} />

      {/* Error message */}
      {!isValid && <span className={styles.inputError}>{errorText}</span>}
    </div>
  );
};

export default FileInput;
