import { graphql, useStaticQuery } from "gatsby";

import { IOpportunitiesHook } from "@models/hooks";

/**
 * Retrieves all opportunities and returns them sorted in descending order by the date they were created.
 *
 * @param {string} [type=""] - The type of career opportunity to retrieve. If empty, all opportunities are retrieved.
 *
 * @returns {Array<{node: IOpportunity}>} - The list of opportunities.
 */
const useOpportunities = (type = "") => {
  const data = useStaticQuery<IOpportunitiesHook>(graphql`
    {
      allCareerOpportunityPosts {
        edges {
          node {
            id
            title
            slug
            type
            engineer_sub_type
            head_office_sub_type
            use_default_content
            excerpt
            location
            latitude
            longitude
            date_created
          }
        }
      }
    }
  `);

  // Filter the opportunities based on the type
  // If no type is provided, all opportunities are returned
  const opportunities =
    type.length > 0
      ? data.allCareerOpportunityPosts.edges.filter(
          ({ node: o }) => o.type === type
        )
      : data.allCareerOpportunityPosts.edges;

  // Return the opportunities, sorted in descending order by the date they were created
  return opportunities.sort(({ node: a }, { node: b }) => {
    return a.date_created > b.date_created
      ? -1
      : a.date_created < b.date_created
      ? 1
      : 0;
  });
};
export default useOpportunities;
