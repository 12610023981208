import { IFormField, ReviewsFormType } from "@models/forms";
import React, { ReactNode, createContext, useReducer } from "react";

const allTypes = ["nps", "additional-feedback"];
const feedbackTypes = ["additional-feedback"];
const npsTypes = ["nps"];

const initialReviewsFormState: ReviewsFormType = {
  hdnDate: { valid: true, value: "", type: allTypes }, // Defaults to true as there is a separate validity check for these fields
  hdnSite: { valid: true, value: "", type: allTypes }, // Defaults to true as there is a separate validity check for these fields
  recaptchaToken: { valid: true, value: "", type: allTypes }, // Defaults to true as there is a separate validity check for these fields
  jobReviewId: { valid: null, value: "", type: allTypes },
  overallRating: { valid: null, value: "", type: npsTypes },
  engineerRating: { valid: null, value: "", type: npsTypes },
  comments: { valid: null, value: "", type: npsTypes },
  valueRating: { valid: null, value: "", type: feedbackTypes },
  easeOfBookingRating: { valid: null, value: "", type: feedbackTypes },
  communicationRating: { valid: null, value: "", type: feedbackTypes },
  mainAreaOfConcern: { valid: null, value: "", type: feedbackTypes },
  otherAreaOfConcern: { valid: null, value: "", type: feedbackTypes },
  specificDetails: { valid: null, value: "", type: feedbackTypes },
  howWeCanImprove: { valid: null, value: "", type: feedbackTypes },
  wantsToBeContacted: { valid: null, value: false, type: feedbackTypes },
};

/**
 * Interface for the action to reset the reviews form state.
 */
export interface ResetReviewsFormAction {
  type: "RESET_FORM";
}

/**
 * Interface for the action to set a value in the reviews form state.
 */
export interface SetReviewsFormValueAction {
  type: "SET_VALUE";
  payload: {
    key: keyof ReviewsFormType;
    value: IFormField;
  };
}
type ReviewsFormAction = SetReviewsFormValueAction | ResetReviewsFormAction;

/**
 * Interface for the context value containing the state and dispatch function.
 */
interface ReviewsFormContextValue {
  state: ReviewsFormType;
  dispatch: React.Dispatch<ReviewsFormAction>;
}

/**
 * Context to provide reviews form state and dispatch function.
 */
const ReviewsFormContext = createContext<ReviewsFormContextValue | undefined>(
  undefined
);

/**
 * Reducer function to handle reviews form actions.
 *
 * @param state - The current state of the reviews form.
 * @param action - The action to be processed.
 * @returns The new state of the reviews form.
 */
const reviewsFormReducer = (
  state: ReviewsFormType,
  action: ReviewsFormAction
): ReviewsFormType => {
  switch (action.type) {
    case "SET_VALUE":
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          valid: action.payload.value.valid,
          value: action.payload.value.value,
        },
      };
    case "RESET_FORM":
      return initialReviewsFormState;
    default:
      return state;
  }
};

/**
 * Provider component to wrap the reviews form context around children components.
 *
 * @param children - The children components to be wrapped.
 * @returns The provider component with the context.
 */
const ReviewsFormProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(
    reviewsFormReducer,
    initialReviewsFormState
  );

  return (
    <ReviewsFormContext.Provider value={{ state, dispatch }}>
      {children}
    </ReviewsFormContext.Provider>
  );
};

export { ReviewsFormProvider, ReviewsFormContext };
