import { graphql, useStaticQuery } from "gatsby";

import { IReviewsIOLogoHook } from "@models/hooks";

const useReviewsIOLogo = () => {
  const data = useStaticQuery<IReviewsIOLogoHook>(graphql`
    {
      allFile(
    filter: {absolutePath: {regex: "/\\/src\\/images\\/reviewsio-logo.*?\\.png/g"}}
  ) {
    edges {
      node {
        id
        name
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
    }
  `);
  return data;
};

export default useReviewsIOLogo;
