import React from "react";

/**
 * ScrollingLink component.
 *
 * Renders a scrolling link that scrolls to a specific element on the page
 * when clicked.
 *
 * @param {Object} props - The component props.
 * @param {string} props.idToScrollTo - The ID of the element to scroll to.
 * @param {string} [props.className=""] - The CSS class name for the link.
 * @param {React.ReactNode | string} props.children - The content of the link.
 * @param {string} [props.title] - The title attribute of the link.
 * @return {JSX.Element} The scrolling link component.
 */
const ScrollingLink = ({
  idToScrollTo,
  className = "",
  children,
  title,
  onClick = undefined,
}: {
  idToScrollTo: string;
  className?: string;
  title?: string;
  children: React.ReactNode | string;
  onClick?: () => void;
}): JSX.Element => {
  /**
   * Handles the click event of the link.
   *
   * Prevents the default behavior of the link (scrolling to the top of the page),
   * stops the event propagation, and scrolls to the specified element.
   *
   * @param {React.MouseEvent<HTMLAnchorElement>} e - The click event.
   * @return {void} This function does not return anything.
   */
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    onClickToScrollHandler(idToScrollTo);
    if (onClick) onClick();
  };

  return (
    <a
      className={className}
      href={idToScrollTo}
      title={title}
      onClick={handleClick}
    >
      {children}
    </a>
  );
};

/**
 * Scrolls to the element with the specified ID and updates the URL.
 *
 * @param {string} idToScrollTo - The ID of the element to scroll to.
 * @return {void} This function does not return anything.
 */
export const onClickToScrollHandler = (idToScrollTo: string) => {
  // Get the element to scroll to by its ID, removing the "#" prefix if present.
  const element = document.getElementById(idToScrollTo.replace("#", ""));

  // If the element exists, perform the scrolling and URL updating.
  if (element) {
    // Get the header element for calculating the offset.
    const header = document.getElementById("header");

    // Calculate the offset of the element relative to the top of the page.
    const offsetTop = element.offsetTop;

    // Calculate the height of the header element.
    const headerHeight = header ? header.offsetHeight : 0;

    // Scroll the window to the calculated position.
    // This should be a smooth animated scroll but if there are too many
    // tabs open or the user's GPU is struggling, a jump is made instead.
    window.scroll({ top: offsetTop - headerHeight, behavior: "smooth" });

    // Update the URL to the specified ID.
    window.history.pushState(null, "", `${idToScrollTo}`);
  }
};

export default ScrollingLink;
