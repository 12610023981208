import { IPostListItemExtended } from "@models/hooks";
import useProperCase from "../hooks/useProperCase";

class PostListMethods {
  #posts: IPostListItemExtended[];
  #maxPages: number;
  #filteredPosts: IPostListItemExtended[] = [];

  constructor(posts: IPostListItemExtended[]) {
    this.#posts = posts;
    this.#filteredPosts = posts;
    this.#maxPages = this.getMaxPageNumber();
  }

  /**
   * The number of posts that can appear on a page
   */
  get postCount() {
    return 12;
  }

  /**
   * Generates a string array of all tags that can be used for filtering blog posts.
   * @returns String array containing all tags used in all posts
   */
  generateTagList = () => {
    const tagSet = new Set<string>();
    const allTags = this.#posts.flatMap((post) => post.tags).join(", ");
    const properCaseTags = useProperCase(allTags)
      .replace(/\s+,\s+|\s+,|,\s+/g, ",")
      .split(",");
    properCaseTags.forEach((tag) => {
      tagSet.add(tag);
    });
    return Array.from(tagSet).sort();
  };

  /**
   * Determines if the page number is valid by checking it's a number,
   * greater than 0, and not greater than the maximum amount of pages
   * allowed.
   * @param page The current page number which comes from the URL.
   * @returns Boolean indicating if the page number is valid.
   */
  isPageNumberInvalid = (page: number) => {
    return isNaN(page) || (!isNaN(page) && (page < 1 || page > this.#maxPages));
  };

  /**
   * Gets a valid path for the page number provided.
   * Provides the correct path if an invalid path has been provided.
   * @param page The current page number which comes from the URL.
   * @param pageType The type of page the user is currently on (defaults to a blank string)
   * @returns The correct path to redirect to if an invalid page number has been provided or
   * the current page path.
   */
  getValidPage = (
    page: number,
    pageType: "" | "advice-hub" | "about-us/news" | "locations" = ""
  ) => {
    const pathStart = pageType.length > 0 ? `/${pageType}` : "";
    return isNaN(page)
      ? pathStart
      : page <= 1
      ? `${pathStart}`
      : page > this.#maxPages
      ? `${pathStart}/page/${this.#maxPages}`
      : `${pathStart}/page/${page}`;
  };

  /**
   * Sorts the blog posts by either date ascending or date descending.
   * @param sort Either date ascending or date descending.
   * @param posts Optional array of IBlogListItemExtended objects so a new array can be
   * sorted if necessary. Defaults to the array used in the constructor.
   * @returns Array of blog posts in the order specified.
   */
  sortPosts = (sort: string, posts = this.#posts) => {
    return posts.sort((a, b) => {
      if (sort === "date_asc") {
        return a.date_created > b.date_created
          ? 1
          : a.date_created < b.date_created
          ? -1
          : 0;
      } else {
        return a.date_created > b.date_created
          ? -1
          : a.date_created < b.date_created
          ? 1
          : 0;
      }
    });
  };

  /**
   * Filters the blog posts by tag.
   * @param tag The tag to filter by. If the tag is an empty string then all posts are returned.
   * @param posts Optional array of IBlogListItemExtended objects so a new array can be
   * filtered if necessary. Defaults to the array used in the constructor.
   * @returns Array of blog posts that contain tags matching the tag provided.
   */
  filterPostsByTag = (tag: string, posts = this.#posts) => {
    if (tag.length === 0) return posts;

    // Decode the tag URL encoding
    const decodedTag = decodeURIComponent(tag);
    return tag.trim().length === 0
      ? posts
      : posts.filter(
          (p) =>
            p.tags.filter((t) => useProperCase(t.trim()) === decodedTag)
              .length > 0
        );
  };

  /**
   * Gets the posts that should appear on the current page.
   * @param page The current page number which comes from the URL.
   * @param tag The tag to filter by. If the tag is an empty string then all posts are returned.
   * @param sort Either date ascending or date descending. Defaults to date descending.
   * @returns Array of blog posts that is limited to the post count and the current page number.
   */
  getPostsForPage = (
    page: number,
    tag: string = "",
    sort: string = "date_desc"
  ) => {
    const sortedPosts = this.sortPosts(sort);
    this.#filteredPosts = this.filterPostsByTag(tag, sortedPosts);
    return this.#filteredPosts.slice(
      page * this.postCount - this.postCount,
      page * this.postCount
    );
  };

  /**
   * Gets the maximum page number based on the post count and any filters that have been applied.
   * @param tag The tag to filter by. If the tag is an empty string then all posts are used to generate the max page number.
   * @returns The maximum page number.
   */
  getMaxPageNumber = () => {
    return Math.ceil(this.#filteredPosts.length / this.postCount);
  };
}
export default PostListMethods;
