import * as colourStyles from "../SVGStyles/SVGColours.module.css";
import * as styles from "./SVGWaterTank.module.css";

import { ISVGComponentProps } from "@models/component-props";
import React from "react";
import { SVGFromIconName } from "@components/svgs";
import { useSVGColour } from "@hooks";

/**
 * A component to render a water-tank SVG based on a given colour.
 * This component ensures the styles specifc to this icon are applied.
 *
 * @param {ISVGComponentProps} props
 * @prop {ISVGColours} [colour] - The colour of the water-tank.
 * @prop {ISVGProps} [svgProps] - Additional props to pass to the underlying SVG
 * @returns {React.ReactElement} The water-tank SVG
 */
const SVGWaterTank = ({ colour, svgProps }: ISVGComponentProps) => {
  // The class name based on the colour provided
  const className = `${styles.svgWaterTank} ${useSVGColour(
    colourStyles,
    colour
  )} ${svgProps.className || ""}`;

  // Return the SVG code based on the icon and the class name
  return (
    <SVGFromIconName
      icon={"water-tank"}
      svgProps={{ ...svgProps, className }}
    />
  );
};

export default SVGWaterTank;
