import { ICareerOpportunityPostDetails } from "@models/pages";
import { opportunitiesContent } from "@constants";

class OpportunityPostMethods {
  #post: ICareerOpportunityPostDetails;
  #defaultObject: {
    type: string;
    excerpt: string;
    schemaDescription: string;
    content: string;
  };
  #isPartnerOrEngineer: boolean;
  #isUsingDefaultContent: boolean;

  constructor(post: ICareerOpportunityPostDetails) {
    this.#post = post;

    // Default content for current post type
    this.#defaultObject =
      post.type === "head-office"
        ? opportunitiesContent.filter(
            (o) => o.type === post.head_office_sub_type
          )[0]
        : opportunitiesContent.filter((o) => o.type === post.type)[0];

    // True if the post type is a partner or engineer
    this.#isPartnerOrEngineer =
      post.type === "partner" || post.type === "engineer";

    // Opportunities for engineers/some head office roles are usually the same,
    // we provide default content here so these descriptions don't have to be
    // typed out on Directus. This may cause duplicate content issues though so
    // it's something we may need to consider altering/removing at a future date.
    this.#isUsingDefaultContent =
      this.#post.use_default_content &&
      (this.#isPartnerOrEngineer ||
        (this.#post.type === "head-office" &&
          (this.#post.head_office_sub_type === "phone-receiver" ||
            this.#post.head_office_sub_type === "spanner")));
  }

  /**
   * Checks if the location for the post is in the meta description.
   * @returns Boolean indicating if the location provided is in the meta description.
   */
  #hasLocationInMetaDescription = () => {
    const locationRegExp = new RegExp(this.#post.location, "gi");
    return this.#post.meta_description.match(locationRegExp);
  };

  /**
   * Adds the location for the post at the beginning of the meta description.
   * This helps to prevent duplicate content issues.
   * @returns Meta description with location details at the beginning.
   */
  #addLocationToMetaDescription = () => {
    return `Opportunity in ${this.#post.location}: ${
      this.#post.meta_description
    }`;
  };

  /**
   * The HTML used in the description for the job schema can't contain a
   * lot of HTML that would normally used to help style the page
   * (i.e. strong, em, or u tags), only simple tags that format the content
   * (i.e. p, ul, or li tags).
   * @returns The opportunity content with limited HTML tags.
   */
  #cleanContentForSchemaDescription = () => {
    return this.#post.content
      .replace(/<\/*([a-z0-9]+) .*>/gi, "<$1>") // Removes any attributes from tags
      .replace(/<(\/*)(blockquote|h[1-6])>/gi, "<$1p>") // Replaces blockquotes and h tags with p tags
      .replace(/<(\/*)(strong|em|u|span|br|hr|a|img)>/gi, ""); // Removes unsupported tags.
  };

  /**
   * Gets the relevant content for the opportunity
   * @returns Content from opportunitiesContent if using default content,
   * the content from Directus if not using default content.
   */
  getContent = () => {
    return this.#isUsingDefaultContent
      ? this.#defaultObject.content
      : this.#post.content;
  };

  /**
   * Gets the relevant excerpt for the opportunity
   * @returns Excerpt from opportunitiesContent if using default content,
   * the excerpt from Directus if not using default content.
   */
  getExcerpt = () => {
    return this.#isUsingDefaultContent
      ? this.#defaultObject.excerpt
      : this.#post.excerpt;
  };

  /**
   * Gets the relevant description of the role to be used in the job schema.
   * This should contain HTML, but the tags used are greatly limited.
   * @returns Schema description from opportunitiesContent if using default content,
   * a simplified version of the content from Directus if not using default content.
   */
  getSchemaDescription = () => {
    return this.#isUsingDefaultContent
      ? this.#defaultObject.schemaDescription
      : this.#cleanContentForSchemaDescription();
  };

  /**
   * Gets the meta description for the opportunity. If the post is for a partner/engineer
   * and the location hasn't been supplied in the description, this adds the location to
   * help prevent duplicate content.
   * @returns The meta description from Directus with the location of the opportunity at the
   * beginning if necessary.
   */
  getMetaDescription = () => {
    return this.#isPartnerOrEngineer && !this.#hasLocationInMetaDescription()
      ? this.#addLocationToMetaDescription()
      : this.#post.meta_description;
  };
}
export default OpportunityPostMethods;
