import useProperCase from "./useProperCase";

/**
 * Converts a slug string into a title string by replacing hyphens with spaces and proper-casing the result.
 * @param {string} value The slug string to convert.
 * @returns {string} The title string.
 */
const useSlugToTitle = (value: string) => {
  const valueWithoutHyphens = value.replace(/-/g, " ");
  return useProperCase(valueWithoutHyphens);
};

export default useSlugToTitle;
