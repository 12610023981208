import { ReviewsFormContext } from "@store";
import { useContext } from "react";

export const useReviewsFormContext = () => {
  const context = useContext(ReviewsFormContext);
  if (context === undefined) {
    throw new Error(
      "useReviewsFormContext must be used within a ReviewsFormProvider"
    );
  }
  return context;
};
