import { IFormField, RequestACallbackFormType } from "@models/forms";
import React, { ReactNode, createContext, useReducer } from "react";

/*
 * Interface for the action to reset the request a callback state.
 */
export interface ResetRequestACallbackAction {
  type: "RESET_FORM";
}

/**
 * Interface for the action to set a value in the request a callback state.
 */
export interface SetRequestACallbackValueAction {
  type: "SET_VALUE";
  payload: {
    key: keyof RequestACallbackFormType;
    value: IFormField;
  };
}

type RequestACallbackAction =
  | SetRequestACallbackValueAction
  | ResetRequestACallbackAction;

// Define the types for the context value
interface RequestACallbackContextValue {
  state: RequestACallbackFormType;
  dispatch: React.Dispatch<RequestACallbackAction>;
}

const initialRACState: RequestACallbackFormType = {
  accountCode: { valid: null, value: "" },
  website: { valid: null, value: "" },
  hdnDate: { valid: true, value: "" }, // Defaults to true as there is a separate validity check for these fields
  hdnSite: { valid: true, value: "" }, // Defaults to true as there is a separate validity check for these fields
  recaptchaToken: { valid: true, value: "" }, // Defaults to true as there is a separate validity check for these fields
  txtName: { valid: null, value: "" },
  txtPostcode: { valid: null, value: "" },
  txtEmail: { valid: null, value: "" },
  txtPhone: { valid: null, value: "" },
  selService: { valid: null, value: "" },
  txtRequirements: { valid: null, value: "" },
  chkTerms: { valid: null, value: false },
};

const RequestACallbackContext = createContext<
  RequestACallbackContextValue | undefined
>(undefined);

/**
 * Reducer function to handle actions related to the request-a-callback form state.
 *
 * @param {RequestACallbackFormType} state - The current state of the form.
 * @param {RequestACallbackAction} action - The action to be performed on the state.
 * @returns {RequestACallbackFormType} The new state of the form.
 */
const requestACallbackReducer = (
  state: RequestACallbackFormType,
  action: RequestACallbackAction
): RequestACallbackFormType => {
  switch (action.type) {
    case "SET_VALUE":
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          valid: action.payload.value.valid,
          value: action.payload.value.value,
        },
      };
    case "RESET_FORM":
      return initialRACState;
    default:
      return state;
  }
};

/**
 * Provider component for the request-a-callback context.
 *
 * @param {Object} props - The props for the provider component.
 * @param {ReactNode} props.children - The child components to be wrapped by the provider.
 * @returns {JSX.Element} The provider component with the context value.
 */
const RequestACallbackProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(
    requestACallbackReducer,
    initialRACState
  );

  return (
    <RequestACallbackContext.Provider value={{ state, dispatch }}>
      {children}
    </RequestACallbackContext.Provider>
  );
};

export { RequestACallbackProvider, RequestACallbackContext };
