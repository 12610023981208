import * as styles from "./SliderDots.module.css";

import { ISVGColours } from "@models/component-props";
import React from "react";

/**
 * A component to render a set of dots for a slider.
 *
 * @param {React.ReactNode} dots - The dots to render. This is usually a set of
 *   `li`s.
 * @param {ISVGColours} [colour] - The colour of the dots. One of the following:
 *   - "black"
 *   - "blue"
 *   - "dark-blue"
 *   - "dark-green"
 *   - "green"
 *   - "light-blue"
 *   - "white"
 *   Default is "blue".
 * @param {string} [className] - An additional class name to apply to the
 *   containing `ul`.
 * @param {string} [dotsClassName] - An additional class name to apply to each
 *   individual dot.
 * @returns {React.ReactElement} The rendered dots.
 */
const SliderDots = ({
  dots,
  colour = "blue",
  className = "",
  dotsClassName = "",
}: {
  dots: React.ReactNode;
  colour?: ISVGColours;
  className?: string;
  dotsClassName?: string;
}) => {
  // The class name based on the colour provided
  const colourClass =
    colour === "black"
      ? styles.sliderDotsBlack
      : colour === "blue"
      ? styles.sliderDotsBlue
      : colour === "dark-blue"
      ? styles.sliderDotsDarkBlue
      : colour === "dark-green"
      ? styles.sliderDotsDarkGreen
      : colour === "green"
      ? styles.sliderDotsGreen
      : colour === "light-blue"
      ? styles.sliderDotsLightBlue
      : styles.sliderDotsWhite;

  // Return the rendered dots
  return (
    <ul
      className={`${styles.sliderDots} ${colourClass} ${className} ${dotsClassName}`}
    >
      {dots}
    </ul>
  );
};

export default SliderDots;
