import { IOpportunity } from "@models/hooks";
import axios from "axios";

class OpportunitiesListMethods {
  #opportunities: { node: IOpportunity }[];
  #type: "head-office" | "engineer" | "partner" | "";
  #filteredOpportunities: { node: IOpportunity }[] = [];
  #maxPages: number;

  constructor(
    opportunities: { node: IOpportunity }[],
    type: "head-office" | "engineer" | "partner" | ""
  ) {
    this.#opportunities = opportunities;
    this.#filteredOpportunities = this.#opportunities;
    this.#type = type;
    this.#maxPages = this.getDefaultMaxPageNumber();
  }

  /**
   * Converts degrees to radians for calculations regarding latitude and longitude.
   * @param deg The number in degrees.
   * @returns The value of deg converted to radians.
   */
  #degreesToRadians = (deg: number) => {
    return (deg * Math.PI) / 180.0;
  };

  /**
   * Searches the here API for the location provided. By default, only locations in the UK are searched for.
   * @param location The location to search for entered by the user.
   * @returns The latitude and longitude of the first location in the response, the radians and cos of the
   * latitde, and any error messages that may have occurred
   */
  #searchForLocation = async (location: string) => {
    let locationDetails: {
      latitude: number;
      longitude: number;
      error: string;
    } = {
      latitude: 0,
      longitude: 0,
      error: "",
    };
    return await axios
      .get(
        `${process.env.GATSBY_HERE_API_URL}?apikey=${process.env.GATSBY_HERE_API_KEY}&qq=city=${location};country=United Kingdom`
      )
      .then((response) => {
        if (response.data.items && response.data.items.length > 0) {
          locationDetails = {
            ...locationDetails,
            latitude: response.data.items[0].position.lat as number,
            longitude: response.data.items[0].position.lng as number,
          };
        } else {
          locationDetails = {
            ...locationDetails,
            error: "Location could not be found",
          };
        }
        return locationDetails;
      })
      .catch((_error) => {
        locationDetails = {
          ...locationDetails,
          error:
            "There was an issue finding the location you entered, please check the location and try again",
        };
        return locationDetails;
      });
  };

  /**
   *
   * @param latitude
   * @param longitude
   * @returns
   */
  #getCoordinates = (latitude: number, longitude: number) => {
    const latitudeRadians = this.#degreesToRadians(latitude);
    return {
      latitude,
      longitude,
      latitudeRadians,
      latitudeCos: Math.cos(latitudeRadians),
    };
  };

  /**
   *
   * @param opportunityCoordinates
   * @param searchCoordinates
   * @returns
   */
  #getCoordinatesSinValues = (
    opportunityCoordinates: {
      latitude: number;
      longitude: number;
    },
    searchCoordinates: {
      latitude: number;
      longitude: number;
    }
  ) => {
    const latitudeRadians = this.#degreesToRadians(
      opportunityCoordinates.latitude - searchCoordinates.latitude
    );
    const longitudeRadians = this.#degreesToRadians(
      opportunityCoordinates.longitude - searchCoordinates.longitude
    );
    return {
      latitudeSin: Math.sin(latitudeRadians / 2),
      longitudeSin: Math.sin(longitudeRadians / 2),
    };
  };

  /**
   *
   * @param opportunityCoordinates
   * @param searchCoordinates
   * @param coordinateSins
   * @returns
   */
  #getDistanceInKm = (
    opportunityCoordinates: {
      latitude: number;
      longitude: number;
      latitudeRadians: number;
      latitudeCos: number;
    },
    searchCoordinates: {
      latitude: number;
      longitude: number;
      latitudeRadians: number;
      latitudeCos: number;
    },
    coordinateSins: { latitudeSin: number; longitudeSin: number }
  ) => {
    // Uses the haversine formula for working out if a location is nearby with latitude and longitude coordinates:
    // https://www.movable-type.co.uk/scripts/latlong.html
    const a =
      coordinateSins.latitudeSin * coordinateSins.latitudeSin +
      searchCoordinates.latitudeCos *
        opportunityCoordinates.latitudeCos *
        coordinateSins.longitudeSin *
        coordinateSins.longitudeSin;

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    // 6371 - radius of earth in km
    return 6371 * c;
  };

  /**
   *
   * @param searchLocation
   * @returns
   */
  #filterOpportunitiesByLocation = (searchLocation: {
    latitude: number;
    longitude: number;
  }) => {
    return this.#opportunities
      .map(({ node: o }) => {
        // Gets latitude in radians and cos as well as returning original coordinates
        const searchCoordinates = this.#getCoordinates(
          searchLocation.latitude,
          searchLocation.longitude
        );
        const opportunityCoordinates = this.#getCoordinates(
          o.type === "head-office" ? 52.46613054751715 : o.latitude,
          o.type === "head-office" ? -1.9857021594103639 : o.longitude
        );

        // Gets ths sin values to help work out the distance between locations
        const coordinateSins = this.#getCoordinatesSinValues(
          {
            latitude: opportunityCoordinates.latitude,
            longitude: opportunityCoordinates.longitude,
          },
          {
            latitude: searchCoordinates.latitude,
            longitude: searchCoordinates.longitude,
          }
        );

        // Gets the distance in km
        const distance = this.#getDistanceInKm(
          opportunityCoordinates,
          searchCoordinates,
          coordinateSins
        );

        // Returns the opportunity if it's within 20 miles of the searched location.
        // 32 km = 20 miles.
        return distance <= 32.0 ? { node: o } : null;
      })
      .flat()
      .filter((n) => n) as { node: IOpportunity }[];
  };

  #filterOpportunitiesByCategory = (
    category: string,
    filteredOpportunities: { node: IOpportunity }[]
  ) => {
    return this.#type === "head-office"
      ? filteredOpportunities.filter(
          ({ node: o }) => o.head_office_sub_type === category
        )
      : this.#type === "partner" || this.#type === "engineer"
      ? filteredOpportunities.filter(({ node: o }) =>
          (o.engineer_sub_type as string[]).includes(category)
        )
      : category === "partner" || category === "engineer"
      ? filteredOpportunities.filter(({ node: o }) => o.type === category)
      : filteredOpportunities.filter(
          ({ node: o }) => o.head_office_sub_type === category
        );
  };

  /**
   * The number of posts that can appear on a page
   */
  get postCount() {
    return 12;
  }

  /**
   * The default error to be displayed if there are no opportunitiesn to display.
   * This happens before any filtering is done in case there are no opportunities available
   * (which may be common for head office jobs).
   */
  get defaultError() {
    return this.#opportunities.length === 0
      ? `We're sorry, there are currently no ${
          this.#type === "head-office"
            ? "head-office"
            : this.#type === "partner"
            ? "sub-contractor"
            : this.#type === "engineer"
            ? "employed engineer"
            : ""
        } opportunities. We do advertise opportunities regularly though so make sure to come back again.`
      : "";
  }

  /**
   * The options for the category field, the options returned are based on the type provided.
   */
  get categoryOptions() {
    return this.#type === "partner" || this.#type === "engineer"
      ? [
          { key: "Plumbing", value: "water-droplet" },
          { key: "Heating", value: "boiler" },
          { key: "Electrician", value: "lightning-bolt" },
          { key: "Drainage", value: "plug-hole" },
          { key: "Locksmith", value: "keyhole" },
        ]
      : this.#type === "head-office"
      ? [
          { key: "Acquisitions", value: "phone-receiver" },
          { key: "Logistics", value: "spanner" },
          { key: "Care Club", value: "care-club-heart-inside-diamond" },
          { key: "Accounts", value: "clipboard" },
          { key: "HR", value: "team-of-people" },
          { key: "IT", value: "jigsaw-puzzle-piece" },
          { key: "Marketing", value: "mobile-phone" },
        ]
      : [
          { key: "Sub-Contractor Engineer", value: "partner" },
          { key: "Employed Engineer", value: "engineer" },
          { key: "Acquisitions", value: "phone-receiver" },
          { key: "Logistics", value: "spanner" },
          { key: "Care Club", value: "care-club-heart-inside-diamond" },
          { key: "Accounts", value: "clipboard" },
          { key: "HR", value: "team-of-people" },
          { key: "IT", value: "jigsaw-puzzle-piece" },
          { key: "Marketing", value: "mobile-phone" },
        ];
  }

  /**
   * Determines if the page number is valid by checking it's a number,
   * greater than 0, and not greater than the maximum amount of pages
   * allowed.
   * @param page The current page number which comes from the URL.
   * @returns Boolean indicating if the page number is valid.
   */
  isPageNumberInvalid = (page: number) => {
    return isNaN(page) || (!isNaN(page) && (page < 1 || page > this.#maxPages));
  };

  /**
   * Gets a valid path for the page number provided.
   * Provides the correct path if an invalid path has been provided.
   * @param page The current page number which comes from the URL.
   * @returns The correct path to redirect to if an invalid page number has been provided or
   * the current page path.
   */
  getValidPage = (page: number) => {
    const pathStart = `/careers/opportunities${
      this.#type.length > 0 ? `/${this.#type}` : ""
    }`;
    return isNaN(page)
      ? pathStart
      : page <= 1
      ? `${pathStart}`
      : page > this.#maxPages
      ? `${pathStart}/page/${this.#maxPages}`
      : `${pathStart}/page/${page}`;
  };

  /**
   * Filters the opportunities based on the provided category and location.
   * If both category and location are empty strings, the original opportunities are returned.
   * If only location is provided, opportunities are filtered based on the location after a search.
   * If only category is provided, opportunities are filtered based on the category.
   * If both category and location are provided, opportunities are first filtered based on the location
   * and then on the category.
   *
   * @param {string} category - The category to filter the opportunities by.
   * @param {string} location - The location to filter the opportunities by.
   * @return {Promise<IOpportunity[]>} A promise which resolves to an array of opportunities that
   * match the provided category and/or location.
   * @throws {Error} If there is an error in searching for the location.
   */
  filterOpportunities = async (category: string, location: string) => {
    const locationTrim = location.trim();
    if (category.length === 0 && locationTrim.length === 0) {
      this.#filteredOpportunities = this.#opportunities;
      return this.#opportunities;
    }

    let filterOpportunities = this.#opportunities;
    if (locationTrim.length > 0) {
      const searchLocation = await this.#searchForLocation(locationTrim);
      if (searchLocation.error.length > 0)
        throw new Error(searchLocation.error);
      filterOpportunities = this.#filterOpportunitiesByLocation({
        latitude: searchLocation.latitude,
        longitude: searchLocation.longitude,
      });
    }
    if (category.length > 0) {
      filterOpportunities = this.#filterOpportunitiesByCategory(
        category,
        filterOpportunities
      );
    }

    this.#filteredOpportunities = filterOpportunities;
    return filterOpportunities;
  };

  /**
   * Retrieves a subset of opportunities for a given page based on the provided category and location.
   *
   * @param {number} page - The page number to retrieve opportunities for.
   * @param {string} [category=""] - The category to filter the opportunities by. Defaults to an empty string.
   * @param {string} [location=""] - The location to filter the opportunities by. Defaults to an empty string.
   * @return {Promise<IOpportunity[]>} A Promise that resolves to an array of opportunities for the given page.
   */
  getPostsForPage = async (
    page: number,
    category: string = "",
    location: string = ""
  ) => {
    const filteredOpportunities = await this.filterOpportunities(
      category,
      location
    );
    return filteredOpportunities.slice(
      page * this.postCount - this.postCount,
      page * this.postCount
    );
  };

  /**
   * Gets the maximum page number based on the post count.
   * @returns The maximum page number.
   */
  getDefaultMaxPageNumber = () => {
    return Math.ceil(this.#opportunities.length / this.postCount);
  };

  /**
   * Gets the maximum page number based on the post count
   * and any filters that have been applied.
   * @returns The maximum page number.
   */
  getMaxPageNumber = () => {
    return Math.ceil(this.#filteredOpportunities.length / this.postCount);
  };
}
export default OpportunitiesListMethods;
