import { IPostListItemExtended, IPostListItemsHook } from "@models/hooks";
import { graphql, useStaticQuery } from "gatsby";

/**
 * Retrieves the latest 6 blog items that are classified as 'advice-hub' and orders them by the
 * date they were created in descending order (newest first). The returned objects are then
 * extended with a style type and a cta.
 *
 * @return {IPostListItemExtended[]} A list of blog items with extended properties.
 */
const useBlogLatestAdvice = () => {
  const data = useStaticQuery<IPostListItemsHook>(graphql`
    {
      allBlog(
        limit: 6
        filter: { page_type: { eq: "advice-hub" } }
        sort: { date_created: DESC }
      ) {
        edges {
          node {
            id
            title
            slug
            excerpt
            tags
            date_created
            date_updated
            related_services {
              services_id {
                id
                type
                title
                slug
              }
            }
            hero {
              id
              title
              imageFile {
                childImageSharp {
                  gatsbyImageData(height: 300, width: 300, layout: FIXED)
                }
              }
            }
          }
        }
      }
    }
  `);

  // Return the items add the style type and cta
  return data.allBlog.edges.map(({ node: b }) => {
    return {
      ...b,
      styleType: 1,
      cta: "",
      page_type: "advice-hub",
    } as IPostListItemExtended;
  });
};

export default useBlogLatestAdvice;
