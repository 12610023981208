import {
  ApplicationFormProvider,
  IsHomeProvider,
  RequestACallbackProvider,
  ReviewsFormProvider,
  ReviewsIOContextProvider,
} from "@store";

import React from "react";

/**
 * A convenience component that wraps the main application in all the
 * necessary Context Providers.
 *
 * @param {{ children: ReactNode }} props
 * @prop {ReactNode} children The main application component tree.
 */
const CombinedProvider = ({ children }: { children: React.ReactNode }) => (
  <IsHomeProvider>
    <RequestACallbackProvider>
      <ApplicationFormProvider>
        <ReviewsFormProvider>
          <ReviewsIOContextProvider>{children}</ReviewsIOContextProvider>
        </ReviewsFormProvider>
      </ApplicationFormProvider>
    </RequestACallbackProvider>
  </IsHomeProvider>
);

export default CombinedProvider;
