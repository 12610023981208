import {
  ISVGIconComponentProps,
  ISVGPathFillIconType,
  ISVGPathStrokeIconType,
  ISVGPolygonFillIconType,
  ISVGPolygonStrokeIconType,
  allPathFillIconTypes,
  allPathStrokeIconTypes,
  allPolygonFillIconTypes,
  allPolygonStrokeIconTypes,
} from "@models/component-props";
import React, { useMemo } from "react";
import {
  SVGArrow,
  SVGBoiler,
  SVGBoilerWithLargeDisplay,
  SVGCareClubHeartInsideDiamond,
  SVGCircle,
  SVGClipboard,
  SVGClock,
  SVGClockWithThinBorder,
  SVGMobilePhone,
  SVGPathFill,
  SVGPathStroke,
  SVGPhoneReceiver,
  SVGPlugHole,
  SVGPolygonFill,
  SVGPolygonStroke,
  SVGRadiator,
  SVGSpanner,
  SVGWaterTank,
} from "@components/svgs";

import { svgDimensionsArray } from "@constants";
import { useSVGDimensions } from "@hooks";

/**
 * A reusable SVG icon component that takes an icon name as a prop and renders the
 * correct SVG icon, with the correct dimensions and styling. The component also
 * takes a colour prop which is used to apply a fill or stroke to the icon.
 *
 * @param {ISVGIconComponentProps} props
 * @returns {React.ReactElement}
 */
const SVGIcon = ({
  icon,
  colour,
  className = "",
  width,
  height,
}: ISVGIconComponentProps) => {
  // Find what the default dimensions are for the icon provided
  const defaultDimensions = useMemo(
    () => svgDimensionsArray.find((x) => x.icon === icon),
    [icon]
  );

  // Get the dimensions required for the icon
  const svgDimensions = useSVGDimensions({
    dimensionsProvided: { width, height },
    defaultDimensions: defaultDimensions!.dimensions,
    widthHeightRatio: defaultDimensions!.ratio,
  });

  // Check if the icon requires path fill styling
  const isPathFill = (value: string): value is ISVGPathFillIconType => {
    return allPathFillIconTypes.includes(value as ISVGPathFillIconType);
  };

  // Check if the icon requires path stroke styling
  const isPathStroke = (value: string): value is ISVGPathStrokeIconType => {
    return allPathStrokeIconTypes.includes(value as ISVGPathStrokeIconType);
  };

  // Check if the icon requires polygon fill styling
  const isPolygonFill = (value: string): value is ISVGPolygonFillIconType => {
    return allPolygonFillIconTypes.includes(value as ISVGPolygonFillIconType);
  };

  // Check if the icon requires polygon stroke styling
  const isPolygonStroke = (
    value: string
  ): value is ISVGPolygonStrokeIconType => {
    return allPolygonStrokeIconTypes.includes(
      value as ISVGPolygonStrokeIconType
    );
  };

  // Set the SVG props
  const svgProps = {
    className,
    width: svgDimensions.width,
    height: svgDimensions.height,
    viewBox: defaultDimensions!.viewBox,
    "aria-label": defaultDimensions!["aria-label"],
  };

  // Get the icon to return with the necessary styling produced in the component
  const svgIcon = useMemo(() => {
    return isPathFill(icon) ? (
      <SVGPathFill
        icon={icon as ISVGPathFillIconType}
        colour={colour}
        svgProps={svgProps}
      />
    ) : isPathStroke(icon) ? (
      <SVGPathStroke
        icon={icon as ISVGPathStrokeIconType}
        colour={colour}
        svgProps={svgProps}
      />
    ) : isPolygonFill(icon) ? (
      <SVGPolygonFill
        icon={icon as ISVGPolygonFillIconType}
        colour={colour}
        svgProps={svgProps}
      />
    ) : isPolygonStroke(icon) ? (
      <SVGPolygonStroke
        icon={icon as ISVGPolygonStrokeIconType}
        colour={colour}
        svgProps={svgProps}
      />
    ) : icon === "arrow-left" ? (
      <SVGArrow direction="left" colour={colour} svgProps={svgProps} />
    ) : icon === "arrow-right" ? (
      <SVGArrow direction="right" colour={colour} svgProps={svgProps} />
    ) : icon === "cross-inside-circle" ? (
      <SVGCircle symbolInside="cross" colour={colour} svgProps={svgProps} />
    ) : icon === "question-mark-inside-circle" ? (
      <SVGCircle
        symbolInside="question-mark"
        colour={colour}
        svgProps={svgProps}
      />
    ) : icon === "tick-inside-circle" ? (
      <SVGCircle symbolInside="tick" colour={colour} svgProps={svgProps} />
    ) : icon === "exclamation-mark-inside-circle" ? (
      <SVGCircle
        symbolInside="exclamation-mark"
        colour={colour}
        svgProps={svgProps}
      />
    ) : icon === "boiler" ? (
      <SVGBoiler colour={colour} svgProps={svgProps} />
    ) : icon === "boiler-with-large-display" ? (
      <SVGBoilerWithLargeDisplay colour={colour} svgProps={svgProps} />
    ) : icon === "care-club-heart-inside-diamond" ? (
      <SVGCareClubHeartInsideDiamond colour={colour} svgProps={svgProps} />
    ) : icon === "clipboard" ? (
      <SVGClipboard colour={colour} svgProps={svgProps} />
    ) : icon === "clock" ? (
      <SVGClock colour={colour} svgProps={svgProps} />
    ) : icon === "clock-with-thin-border" ? (
      <SVGClockWithThinBorder colour={colour} svgProps={svgProps} />
    ) : icon === "mobile-phone" ? (
      <SVGMobilePhone colour={colour} svgProps={svgProps} />
    ) : icon === "phone-receiver" ? (
      <SVGPhoneReceiver colour={colour} svgProps={svgProps} />
    ) : icon === "plug-hole" ? (
      <SVGPlugHole colour={colour} svgProps={svgProps} />
    ) : icon === "radiator" ? (
      <SVGRadiator colour={colour} svgProps={svgProps} />
    ) : icon === "spanner" ? (
      <SVGSpanner colour={colour} svgProps={svgProps} />
    ) : icon === "water-tank" ? (
      <SVGWaterTank colour={colour} svgProps={svgProps} />
    ) : (
      <></>
    );
  }, [icon]);

  // Return the icon
  return <>{svgIcon}</>;
};

export default SVGIcon;
