import { graphql, useStaticQuery } from "gatsby";

import { IValuesHook } from "@models/hooks";

/**
 * Retrieves all about us values items, sorted by order_num in ascending order ( lowest first ),
 * and filtered to only include published items.
 *
 * @returns {IValuesHook} Gatsby data object containing all about us values items.
 */
const useValues = () => {
  const data = useStaticQuery<IValuesHook>(graphql`
    {
      allAboutUsValues(
        sort: { order: ASC }
        filter: { status: { eq: "published" } }
      ) {
        edges {
          node {
            id
            icon
            title
            tagline
            content
            value_image {
              id
              title
              imageFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `);
  return data;
};

export default useValues;
