import * as styles from "./LoadingSpinner.module.css";

import React from "react";

/**
 * A functional component that renders a loading spinner with a loading message.
 * The spinner is styled using CSS classes from the associated module stylesheet.
 * This component can be used to indicate that content is being loaded.
 */
const LoadingSpinner = () => {
  return (
    <section className="inner content">
      <div className={styles.loadingSpinner}></div>
      <p className={`h3 ${styles.loadingText}`}>Loading...</p>
    </section>
  );
};

export default LoadingSpinner;
