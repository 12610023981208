import * as styles from "./RatingButton.module.css";

import React from "react";

/**
 * A button component representing a rating option, which updates the current rating value when clicked.
 *
 * @param {number} index - The index of the button, representing the rating value.
 * @param {number} [currentValue] - The currently selected rating value.
 * @param {React.Dispatch<React.SetStateAction<number | undefined>>} setValue - A function to update the current rating value when the button is clicked.
 * @returns {JSX.Element} The rendered rating button component.
 */
const RatingButton = ({
  index,
  currentValue,
  setValue,
  maxRating = 5,
}: {
  index: number;
  currentValue?: number;
  setValue: React.Dispatch<React.SetStateAction<number | undefined>>;
  maxRating?: 5 | 10;
}) => {
  return (
    <button
      type="button"
      aria-label={`${index}`}
      className={`${styles.ratingButton} ${
        maxRating === 5 ? styles.ratingButtonMaxRating5 : ""
      } ${index === currentValue ? styles.ratingButtonSelected : ""}`}
      onClick={() => setValue(index)}
    >
      {index}
    </button>
  );
};

export default RatingButton;
