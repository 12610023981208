import * as styles from "./Checkbox.module.css";

import { FormDispatches, FormTypes } from "@models/forms";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { useFormDispatch } from "@hooks";

/**
 * A checkbox component that can be used to create a form field with a checkbox.
 *
 * @param {React.ReactNode} children The label text for the checkbox
 * @param {string} name The name of the form field
 * @param {string} value The value of the checkbox
 * @param {FormDispatches} [dispatch] The dispatch function to use to update the form state.
 * @param {FormTypes} [formType] The type of form to update.
 * @param {boolean} [required] Whether the field is required.
 * @param {boolean} [incompleteForm] Whether the form is incomplete.
 * @return {JSX.Element} The checkbox component
 */
const Checkbox = ({
  children,
  name,
  value,
  dispatch = null, //used when part of a form
  formType = undefined,
  required = true,
  incompleteForm = false,
}: {
  children: React.ReactNode;
  name: string;
  value: string;
  dispatch?: FormDispatches;
  formType?: FormTypes;
  required?: boolean;
  incompleteForm?: boolean;
}) => {
  // IsValid State
  const [isValid, setIsValid] = useState<boolean | null>(null);

  // Reference to the checkbox itself
  const checkboxRef = useRef<HTMLInputElement>(null);

  // Sets the state as valid if the checkbox is checked and required
  const checkIsValid = useCallback(() => {
    if (required)
      setIsValid(checkboxRef.current ? checkboxRef.current.checked : false);
  }, [required, checkboxRef]);

  // Checks if the checkbox is required and if it isn't, sets it as valid
  // Otherwise, it checks if the form is incomplete and if it is, check field is valid
  useEffect(() => {
    if (!required) {
      // Sets the field as valid if it is not required
      setIsValid(true);

      // If the dispatch and form type are provided, update the form state
      // with the current value of the checkbox
      if (dispatch && formType)
        useFormDispatch({
          name,
          value: {
            valid: true,
            value: checkboxRef.current ? checkboxRef.current.checked : false,
          },
          formType,
          dispatch,
        });
    } else if (incompleteForm) checkIsValid();
  }, [required, incompleteForm]);

  /**
   * Handles the change event of the checkbox component.
   *
   * The function does two things:
   * 1. It checks the validity of the checkbox component by calling the
   *    `checkIsValid` function.
   * 2. If the component is part of a form, it updates the form state by
   *    calling the `useFormDispatch` hook with the name, value and form type
   *    as arguments.
   */
  const onChangeHandler = () => {
    // Check the validity of the checkbox
    checkIsValid();

    // If the dispatch and form type are provided, update the form state
    // with the current value of the checkbox
    if (dispatch && formType)
      useFormDispatch({
        name,
        value: {
          valid: required
            ? checkboxRef.current
              ? checkboxRef.current.checked
              : false
            : true,
          value: checkboxRef.current ? checkboxRef.current.checked : false,
        },
        formType,
        dispatch,
      });
  };

  return (
    <div className={styles.checkboxContainer}>
      {/* Label for checkbox */}
      <label htmlFor={name} className={styles.checkboxLabel}>
        <div className={styles.checkboxField}>
          {/* 
          Checkbox input, not shown to the user, they see 
          the span with the tick and the text 
          */}
          <input
            ref={checkboxRef}
            id={name}
            className={styles.checkboxFieldInput}
            name={name}
            type="checkbox"
            value={value}
            onChange={onChangeHandler}
          />
          {/* Span with image of tick when checked */}
          <span className={styles.checkboxFieldSpan}></span>
        </div>

        {/* Checkbox label text */}
        {children}
      </label>

      {/* Error message */}
      {isValid === false && (
        <span className={styles.checkboxError}>This field is required</span>
      )}
    </div>
  );
};

export default Checkbox;
