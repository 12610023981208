import {
  ApplicationFormType,
  FormDispatches,
  FormTypes,
  IFormField,
  RequestACallbackFormType,
  ReviewsFormType,
} from "@models/forms";
import {
  SetApplicationFormValueAction,
  SetRequestACallbackValueAction,
  SetReviewsFormValueAction,
} from "@store";

/**
 * This hook is used to dispatch an action to either the reviews, application or the
 * request a callback form. The action sets the value of the specified form field
 * to the provided value. If the dispatch is null or if the formType is not provided,
 * this hook does nothing.
 *
 * @param {string} name The name of the form field to be updated.
 * @param {IFormField} value The new value of the form field.
 * @param {FormTypes} [formType] The type of form to update. If not provided, the hook does nothing.
 * @param {FormDispatches} [dispatch] The dispatch function to use to update the form state. If not
 * provided, the hook does nothing.
 */
const useFormDispatch = ({
  name,
  value,
  formType,
  dispatch = null,
}: {
  name: string;
  value: IFormField;
  formType?: FormTypes;
  dispatch?: FormDispatches;
}) => {
  // Only process if dispatch is not null and formType is provided
  if (dispatch && formType) {
    // Dispatch the action based on the form type
    if (formType === "ApplicationForm") {
      const newDispatch =
        dispatch as React.Dispatch<SetApplicationFormValueAction>;
      newDispatch({
        type: "SET_VALUE",
        payload: {
          key: name as keyof ApplicationFormType,
          value,
        },
      });
    } else if (formType === "RequestACallbackForm") {
      const newDispatch =
        dispatch as React.Dispatch<SetRequestACallbackValueAction>;
      newDispatch({
        type: "SET_VALUE",
        payload: {
          key: name as keyof RequestACallbackFormType,
          value,
        },
      });
    } else if (formType === "ReviewsForm") {
      const newDispatch = dispatch as React.Dispatch<SetReviewsFormValueAction>;
      newDispatch({
        type: "SET_VALUE",
        payload: {
          key: name as keyof ReviewsFormType,
          value,
        },
      });
    }
  }
};
export default useFormDispatch;
