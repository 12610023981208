import { ApplicationFormType, IFormField } from "@models/forms";
import {
  BackendControllers,
  BackendProps,
  ILogGrafanaFormError,
  ILogGrafanaFormEvent,
  ILogGrafanaRequestError,
  errorMessages,
} from "@models";

import LogToGrafana from "./LogToGrafana";
import axios from "axios";

/*
Helper class for managing calls to the back end
*/
class BackendMethods {
  #logToGrafana: LogToGrafana;
  constructor() {
    this.#logToGrafana = new LogToGrafana();
  }
  /**
   * Returns the current date formatted as a string in the format 'YYYY-MM-DD'.
   * This is used for setting the date in the authentication header.
   */
  get currentDate() {
    // Get the current date for the auth header
    const curDate = new Date();
    const curMonth = curDate.getMonth() + 1;
    const curDay = curDate.getDate();
    const curDateStr = `${curDate.getFullYear()}-${
      curMonth < 10 ? `0${curMonth}` : curMonth
    }-${curDay < 10 ? `0${curDay}` : curDay}`;
    return curDateStr;
  }

  /**
   * The object to be used as the Authorization header in the back end API requests.
   * The password is dynamically generated by replacing "curDateStr" in the environment variable
   * GATSBY_BACKEND_PASSWORD with the current date in the format 'YYYY-MM-DD'.
   * @returns {{username: string, password: string}} The object to be used as the Authorization header.
   */
  get authHeader() {
    return {
      username: process.env.GATSBY_BACKEND_USERNAME!,
      password: process.env.GATSBY_BACKEND_PASSWORD!.replace(
        "curDateStr",
        this.currentDate
      ),
    };
  }

  /**
   * Filters the provided object of form fields to only include those that are relevant
   * to the provided sub form type. If no sub form type is provided, the original object
   * is returned.
   *
   * @param params - The object of form fields to be filtered.
   * @param subFormType - An optional string specifying the sub type of the form.
   * If provided, only fields relevant to this form type are included in the returned object.
   * @returns The filtered object of form fields.
   */
  private filterFieldsBySubType(
    params: { [key: string]: IFormField },
    subFormType: string | undefined
  ) {
    // If subFormType is not undefined, filter the params object so that only
    // the fields that are relevant to the subFormType are included
    return typeof subFormType !== "undefined"
      ? Object.fromEntries(
          Object.entries(params).filter(
            ([_, value]) =>
              subFormType && value.type && value.type.includes(subFormType)
          )
        )
      : params; // If sub form type is undefined, return the params object
  }

  /**
   * Checks if any fields in the provided parameters are invalid based on their validity status.
   * If an sub form type is specified, filters the parameters to include only fields relevant
   * to that sub form type before validating them. Throws an error if any field is found invalid.
   *
   * @param params - An object containing form fields to be validated.
   * @param subFormType - An optional string specifying the sub type of the form,
   * If provided, only fields relevant to this form type are considered for validation.
   *
   * @throws {Error} Will throw an error if any of the fields are invalid.
   */
  private throwOnInvalidFields(params: { [key: string]: IFormField }) {
    // Goes through the params and checks if any of the fields are invalid
    const formInvalid = Object.entries(params).find(
      ([_, value]) => !value.valid
    );

    // Throws an error if any of the fields are invalid
    if (formInvalid !== undefined) throw new Error(errorMessages.invalidFields);
  }

  /**
   * Checks if the user is a bot by checking if the "hidden site" and "hidden date" fields
   * are filled. If either of them are, this method throws an error.
   * @param hdnSite - Value of the "hidden site" field.
   * @param hdnDate - Value of the "hidden date" field.
   * @throws {Error} If either of the fields is filled, an error is thrown with the message
   * "Potential bot detected.".
   */
  private throwOnBotDetection(hdnSite: string, hdnDate: string) {
    if (hdnSite.length > 0 || hdnDate.length > 0)
      throw new Error(errorMessages.potentialBot);
  }

  /**
   * Validates the reCAPTCHA token to ensure it is not empty.
   * If the token is empty or consists only of whitespace, an error is thrown.
   *
   * @param recaptchaToken - The reCAPTCHA token to validate.
   * @throws {Error} If the token is empty, an error is thrown with the message
   * "Please check the box to prove you are not a robot."
   */
  private throwOnInvalidRecaptcha(recaptchaToken: string) {
    if (recaptchaToken.trim().length === 0)
      throw new Error(errorMessages.recaptchaCheck);
  }

  /**
   * Checks if the user has selected at least one trade (plumbing, electrical, heating, etc.)
   * when filling in the form. If no trade is selected, an error is thrown.
   * @param params - The form data that is being validated.
   * @throws {Error} If no trade is selected, an error is thrown with the message
   * "Please select the trades you cover."
   */
  private throwOnTradeNotSelected(params: ApplicationFormType) {
    if (
      params.hdnType.value !== "head-office" &&
      !params.chkTradesDrainage.value &&
      !params.chkTradesElectrician.value &&
      !params.chkTradesGasBoilerInstall.value &&
      !params.chkTradesHeating.value &&
      !params.chkTradesLocksmith.value &&
      !params.chkTradesPlumbing.value
    )
      throw new Error(errorMessages.selectTrades);
  }

  /**
   * Converts an object with IFormField values into a new object that takes the
   * same keys and the value from the IFormField object. This is then used to send
   * data to the back end.
   *
   * @param params - The object of IFormField objects to be converted.
   * @returns The converted object.
   */
  private convertToPostObject(params: { [key: string]: IFormField }) {
    // Converts the array generated within the function to an object
    return Object.fromEntries(
      // Converts the params object into an array of key value pairs
      // Using the the value in the IFormField object
      Object.entries(params).map(([key, value]) => [key, value.value])
    );
  }

  /**
   * Converts an object with IFormField values into a FormData object that can be
   * used to send the data to the backend. This is necessary for sending files.
   *
   * @param params - The object of IFormField objects to be converted.
   * @returns The converted FormData object.
   */
  private convertToFormData(params: { [key: string]: IFormField }) {
    // Some forms have files which need to be sent as Blobs.
    // Converting the object to form data allows files to be uploaded.
    const formData = new FormData();
    Object.entries(params).forEach(([key, value]) => {
      // Form data expects the value to be a string or a Blob.
      // If we have a boolean or number, it is converted to a string.
      // Otherwise use the regular value.
      formData.append(
        key,
        typeof value.value === "boolean" || typeof value.value === "number"
          ? `${value.value}`
          : value.value
      );
    });
    return formData;
  }

  /**
   * Makes a GET request to the specified URL with the provided parameters.
   * If parameters are provided, they are converted to a query string and
   * appended to the URL. The response is then returned.
   *
   * @param url - The URL to which the GET request is made.
   * @param params - An object of parameters to be converted to a query string.
   * @returns The response from the server.
   */
  public async get(url: string, params: any | undefined) {
    {
      try {
        // Generate the query string from parameters provided (if any)
        const paramsStr = params
          ? `?${Object.entries(params)
              .map(([key, value]) => `${key}=${value}`)
              .join("&")}`
          : "";

        // Make the API call
        const response = await axios.get(`${url}${paramsStr}`, {
          auth: this.authHeader,
        });

        // Return the response
        return response;
      } catch (error: any) {
        throw error;
      }
    }
  }

  /**
   * Sends a POST request to the specified URL with the provided parameters.
   * Validates the fields and throws errors if any validation fails. Converts the parameters
   * into the appropriate format based on the controller type, and logs the form fill event.
   *
   * @param url - The URL to which the POST request is made.
   * @param controller - The backend controller to determine the type of request.
   * @param params - An object containing form fields to be sent in the request.
   * @param subFormType - An optional string specifying the sub type of form,
   * which can be "partner", "engineer", or "head-office".
   *
   * @returns The response from the server.
   *
   * @throws {Error} Will throw an error if any validation fails or if the server returns an error.
   */
  public async post(
    url: string,
    controller: BackendControllers,
    params: { [key: string]: IFormField },
    subFormType?: string | undefined
  ) {
    try {
      // If there's a sub form type, make sure we're only including the relevant fields
      const filteredParams = this.filterFieldsBySubType(params, subFormType);

      // Check if any fields are invalid
      this.throwOnInvalidFields(filteredParams);

      if (controller === "Recruitment")
        this.throwOnTradeNotSelected(params as ApplicationFormType);

      // Check if hidden fields are filled. If they are, this will throw an error
      // as these fields should always be blank.
      this.throwOnBotDetection(
        `${filteredParams.hdnSite.value}`,
        `${filteredParams.hdnDate.value}`
      );

      // Check the recaptcha token is filled
      this.throwOnInvalidRecaptcha(`${filteredParams.recaptchaToken.value}`);

      // Convert the filteredParams object into the object that needs to be sent to the backend
      const postData =
        controller === "Recruitment"
          ? this.convertToFormData(filteredParams)
          : this.convertToPostObject(filteredParams);

      // Set content type to multipart/form-data so the back end can process the formData
      const headers =
        controller === "Recruitment"
          ? {
              "Content-Type": "multipart/form-data",
            }
          : undefined;

      // Make the API call
      const response = await axios.post(url, postData, {
        auth: this.authHeader,
        headers,
      });

      // Create a form event object to log the event
      let eventProps: ILogGrafanaFormEvent = {
        type: controller,
        response: response.data,
      };

      // Add additional props if it is an application form
      if (controller === "Recruitment")
        eventProps = {
          ...eventProps,
          additionalProps: {
            opportunityType: `${filteredParams.hdnType.value}`,
          },
        };

      // Log the event
      this.#logToGrafana.logFormFillEvent({
        type: controller,
        response: response.data,
      });

      // Return the response
      return response;
    } catch (error: any) {
      throw error;
    }
  }

  /**
   * Calls the backend at the specified controller and endpoint.
   *
   * @param props The properties object containing the controller, endpoint, method,
   * and any required parameters.
   * @returns The response from the backend if the request is successful. If the request
   * fails, an error is thrown.
   */
  public async callBackend(props: BackendProps) {
    const url = `${process.env.GATSBY_BACKEND_URL}/${props.controller}/${props.endpoint}`;
    try {
      return props.method === "GET"
        ? await this.get(url, props.params)
        : await this.post(
            url,
            props.controller,
            props.params,
            props.subFormType
          );
    } catch (error) {
      // console.log(`Error occurred when calling: ${url}`);
      this.errorHandler(error, props);
    }
  }

  /**
   * Checks if the error message is one of the common error messages found in
   * errorMessages. If the error message is a common error, this function returns true.
   * Otherwise, false is returned.
   *
   * @param {string} errorMessage - The error message to check.
   * @returns {boolean} true if the error message is a common error, false otherwise.
   */
  public isCommonError(errorMessage: string) {
    const foundError = Object.values(errorMessages).find((x) => {
      return errorMessage.includes(x);
    });
    return typeof foundError !== "undefined";
  }

  /**
   *  Handles and logs errors that occur during the execution of the stats functionality.
   *
   *  @param {any} error - The error object that was thrown.
   *  @return {void} Throws an error with a formatted error message.
   */
  private errorHandler = (error: any, backendProps: BackendProps) => {
    const grafanaObject: ILogGrafanaFormError | ILogGrafanaRequestError =
      backendProps.method === "GET"
        ? {
            error,
            requestType:
              backendProps.controller === "Stats" ? "Stats" : "Latest Reviews",
          }
        : {
            error,
            formType:
              backendProps.controller === "Enquiries"
                ? "Request a Call Back"
                : backendProps.controller === "Recruitment"
                ? "Opportunity"
                : // : backendProps.controller === "Unsubscribe"
                  // ? "Unsubscribe"
                  // : backendProps.controller === "Reviews" &&
                  //   backendProps.endpoint === "CheckJobDetails"
                  // ? "Check Job"
                  // : "Reviews Submit",
                  "Unsubscribe",
          };

    // Log the error to grafana
    this.#logToGrafana.logError(grafanaObject);

    // Determine the error message and throw an error
    if (
      error.response &&
      error.response.data &&
      error.response.data.errorMessages
    ) {
      const errorStr = `<p>The following errors occurred:</p><ul>${error.response.data.errorMessages
        .map((message: string) => {
          return `<li>${message}</li>`;
        })
        .join("")}</ul>`;
      throw new Error(errorStr);
    } else throw new Error(error);
  };
}

export default BackendMethods;
