import * as styles from "./SliderArrow.module.css";

import { ISVGColours } from "@models/component-props";
import React from "react";
import { SVGIcon } from "@components/svgs";

/**
 * A button component with an arrow SVG to be used as a slider arrow.
 *
 * @prop {string} direction - The direction of the arrow: "left" or "right"
 * @prop {ISVGColours} [colour] - The colour of the SVG
 * @prop {React.MouseEventHandler<HTMLButtonElement>} [onClick] - The on click
 * event handler of the button
 * @prop {React.CSSProperties} [style] - The style of the button
 * @prop {string} [arrowClassName] - Additional class names to add to the
 * `button` element
 * @prop {string} [className] - Additional class names to add to the
 * `button` element
 * @prop {number} [svgWidth] - The width of the SVG
 * @prop {number} [svgHeight] - The height of the SVG
 * @prop {boolean} [useSVGSizeForButtonSize] - Use the SVG size as the button size
 * @returns A button element with an arrow SVG
 */
const SliderArrow = ({
  direction,
  colour,
  onClick,
  style,
  arrowClassName = "",
  className = "",
  svgWidth = 10.5,
  svgHeight = 16,
  useSVGSizeForButtonSize = false,
}: {
  direction: "left" | "right";
  colour?: ISVGColours;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  arrowClassName?: string;
  className?: string;
  style?: React.CSSProperties;
  svgWidth?: number;
  svgHeight?: number;
  useSVGSizeForButtonSize?: boolean;
}) => {
  // Set the button width and height based on svgWidth and svgHeight
  // if useSVGSizeForButtonSize is true
  let styleFinal = { ...style };
  if (useSVGSizeForButtonSize) {
    const buttonSize = svgWidth > svgHeight ? svgWidth : svgHeight;
    styleFinal = { ...styleFinal, width: buttonSize, height: buttonSize };
  }

  // Return the button with an arrow SVG
  return (
    <button
      className={`${className} ${
        direction === "left" ? styles.sliderArrowLeft : styles.sliderArrowRight
      } ${arrowClassName}`}
      style={{ ...styleFinal }}
      onClick={onClick}
    >
      <SVGIcon
        colour={colour}
        height={svgHeight}
        width={svgWidth}
        icon={direction === "left" ? "arrow-left" : "arrow-right"}
      />
    </button>
  );
};

export default SliderArrow;
