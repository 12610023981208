import React, { Suspense, useRef } from "react";

import { useFirstViewportEntry } from "@hooks";

/**
 * Suspends rendering of a React component until it has entered the viewport.
 * When the component enters the viewport, it renders the component wrapped
 * in a Suspense component with a fallback node. If the component has not
 * entered the viewport, it renders the fallback node.
 *
 * @param children The component to render once it has entered the viewport.
 * @param threshold The percentage of the element that must be visible in
 * the viewport for the component to be considered "entered". Defaults to 0.
 * @param root The element that is used as the viewport for calculating
 * visibility. Defaults to null (the root element of the document).
 * @param rootMargin The margin around the root element that is used to
 * calculate the viewport. Defaults to "0px 0px 0px 0px".
 * @param fallBackNode The node to render if the component has not entered
 * the viewport. Defaults to a paragraph with the text "Loading...".
 */
const RenderOnViewportEntry = ({
  children,
  threshold = 0,
  root = null,
  rootMargin = "0px 0px 0px 0px",
  fallBackNode = <p>Loading...</p>,
}: {
  children: React.ReactNode;
  threshold?: number;
  root?: Element | Document | null;
  rootMargin?: string;
  fallBackNode?: React.ReactNode;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const entered = useFirstViewportEntry(ref, { threshold, root, rootMargin });
  return (
    <div ref={ref}>
      {entered ? (
        <Suspense fallback={fallBackNode}>{children}</Suspense>
      ) : (
        fallBackNode
      )}
    </div>
  );
};

export default RenderOnViewportEntry;
