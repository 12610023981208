import React, { ReactNode, createContext, useEffect, useReducer } from "react";

import { IReviewsIO } from "@models";
import axios from "axios";
import { useReviewsIO } from "@hooks";

// Define the actions
export interface ReviewsIOAction {
  type: "SET_VALUE";
  payload: IReviewsIO;
}

// Define the initial state
const initialReviewsIOState: IReviewsIO = {
  reviews: [],
  stats: {
    average_rating: "4.77",
    total_reviews: 2865,
  },
  word: "Excellent",
  excellent_percentage: "95",
};

// Create the context
const ReviewsIOContext = createContext<
  | {
      state: IReviewsIO;
      dispatch: React.Dispatch<ReviewsIOAction>;
    }
  | undefined
>(undefined);

/**
 * Reducer function to handle actions related to the reviews state.
 *
 * @param {IReviewsIO} state - The current state of reviews.
 * @param {ReviewsIOAction} action - The action to be performed on the state.
 * @returns {IReviewsIO} The new state of reviews.
 */
const reviewsIOReducer = (state: IReviewsIO, action: ReviewsIOAction) => {
  switch (action.type) {
    case "SET_VALUE":
      return action.payload;
    default:
      return state;
  }
};

/**
 * A provider component for the reviews context.
 *
 * @param {Object} children - The child components to be wrapped by the provider.
 * @returns The provider component with the context value.
 */
const ReviewsIOContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reviewsIOReducer, initialReviewsIOState);
  const reviewsIOData = useReviewsIO(); // Fetch data using the hook

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get(process.env.GATSBY_REVIEWSIO_URL!);
        const reviewsIOResult = response.data as IReviewsIO;
        dispatch({ type: "SET_VALUE", payload: reviewsIOResult });
      } catch (error) {
        dispatch({ type: "SET_VALUE", payload: reviewsIOData });
      }
    };

    fetchReviews();
  }, []);
  return (
    <ReviewsIOContext.Provider value={{ state, dispatch }}>
      {children}
    </ReviewsIOContext.Provider>
  );
};

export { ReviewsIOContext, ReviewsIOContextProvider };
