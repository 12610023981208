import { IPostListItemExtended } from "@models/hooks";
import { ServiceType } from "@models";
import useBlogAllAdvice from "../hooks/useBlogAllAdvice";
import useBlogAllNews from "../hooks/useBlogAllNews";
import useBlogLatestAdvice from "../hooks/useBlogLatestAdvice";
import useBlogLatestNews from "../hooks/useBlogLatestNews";

/**
 * Helper class for managing blog carousel operations.
 */
class BlogCarouselMethods {
  #serviceTypes?: ServiceType[];
  #tags?: string[];
  #currentPostId?: string;
  #currentServiceId?: string;
  #hasServiceTypePosts: boolean = false;
  #hasTagPosts: boolean = false;
  #hasServiceId: boolean = false;

  /**
   * Constructs a new instance of BlogCarouselMethods.
   * @param blogCarouselMethodProps Object containing optional properties for filtering posts.
   */
  constructor(blogCarouselMethodProps: {
    serviceTypes?: ServiceType[];
    tags?: string[];
    currentPostId?: string;
    currentServiceId?: string;
  }) {
    this.#serviceTypes = blogCarouselMethodProps.serviceTypes;
    this.#tags = blogCarouselMethodProps.tags;
    this.#currentPostId = blogCarouselMethodProps.currentPostId;
    this.#currentServiceId = blogCarouselMethodProps.currentServiceId;
  }

  /**
   * Gets the number of posts to display in the carousel.
   */
  get postCount(): number {
    return 6;
  }

  /**
   * Gets the related posts based on the post type.
   * @param posts The array of blog post items.
   * @param postType The type of posts to retrieve.
   * @returns An array of related posts.
   */
  private getRelatedPosts(
    posts: IPostListItemExtended[],
    postType: "advice-hub" | "about-us/news"
  ): IPostListItemExtended[] {
    // If no filters are applied, return latest posts
    if (!this.hasAnyFilters(posts))
      return postType === "advice-hub" ? this.latestAdvice : this.latestNews;

    // Filtered posts based on different combinations of filters
    let filteredPosts: IPostListItemExtended[] = [];

    // Filter by tag and/or service type
    filteredPosts = posts.filter((post) =>
      this.filterPostByTagAndService(post)
    );

    // If filtered posts are less than postCount, add the latest posts
    if (filteredPosts.length < this.postCount) {
      const remainingPosts = posts.filter(
        (post) =>
          !filteredPosts.some((filteredPost) => filteredPost.id === post.id)
      );
      filteredPosts = filteredPosts.concat(
        remainingPosts.slice(0, this.postCount - filteredPosts.length)
      );
    }

    // Truncate to postCount if needed
    return filteredPosts.slice(0, this.postCount);
  }

  /**
   * Filters a post based on tag and service type filters.
   * @param post The blog post to filter.
   * @returns True if the post passes the filter, otherwise false.
   */
  private filterPostByTagAndService(post: IPostListItemExtended): boolean {
    // Boolean variable to pass in return statements
    const notCurrentPost = post.id !== (this.#currentPostId ?? "");
    const someTags = post.tags.some((tag) => this.#tags?.includes(tag));
    const someServiceTypeAndId = post.related_services.some(
      (service) =>
        service.services_id &&
        this.#serviceTypes?.includes(service.services_id?.type) &&
        service.services_id?.id === this.#currentServiceId
    );
    const someServiceTypeOrId = post.related_services.some(
      (service) =>
        service.services_id &&
        (this.#serviceTypes?.includes(service.services_id?.type) ||
          service.services_id?.id === this.#currentServiceId)
    );

    // If there are service types, a current service ID and tags
    if (this.#serviceTypes && this.#currentServiceId && this.#tags)
      return notCurrentPost && someTags && someServiceTypeAndId;
    // If there is tags and either a current service ID or service types
    else if ((this.#serviceTypes || this.#currentServiceId) && this.#tags)
      return notCurrentPost && someTags && someServiceTypeOrId;
    // If there are no tags but a current service ID and service types
    else if (this.#serviceTypes && this.#currentServiceId)
      return notCurrentPost && someServiceTypeAndId;
    // If there are no tags but either a current service ID or service types
    else if (this.#serviceTypes || this.#currentServiceId)
      return notCurrentPost && someServiceTypeOrId;
    // If there are just tags
    else if (this.#tags) return notCurrentPost && someTags;
    // If no filters are supplied
    else return false;
  }

  // Methods for checking filter conditions...

  /**
   * Checks if any filters are applied.
   * @param posts The array of blog post items.
   * @returns True if any filters are applied, otherwise false.
   */
  private hasAnyFilters(posts: IPostListItemExtended[]) {
    return (
      (this.#serviceTypes && this.hasServiceTypePosts(posts)) ||
      (this.#currentServiceId && this.hasServiceIdPosts(posts)) ||
      (this.#tags && this.hasTagPosts(posts))
    );
  }

  /**
   * Checks if there are any posts related to the specified service type.
   * @param posts The array of blog post items.
   * @returns True if there are related posts, otherwise false.
   */
  private hasServiceTypePosts(posts: IPostListItemExtended[]): boolean {
    this.#hasServiceTypePosts = posts.some(
      (post) =>
        post.id !== (this.#currentPostId || "") &&
        post.related_services.some(
          (service) =>
            service.services_id &&
            this.#serviceTypes?.includes(service.services_id?.type)
        )
    );
    return this.#hasServiceTypePosts;
  }

  /**
   * Checks if there are any posts related to the specified service type.
   * @param posts The array of blog post items.
   * @returns True if there are related posts, otherwise false.
   */
  private hasServiceIdPosts(posts: IPostListItemExtended[]): boolean {
    this.#hasServiceId = posts.some(
      (post) =>
        post.id !== (this.#currentPostId || "") &&
        post.related_services.some(
          (service) => service.services_id?.id === this.#currentServiceId
        )
    );
    return this.#hasServiceId;
  }

  /**
   * Checks if there are any posts related to the specified tags.
   * @param posts The array of blog post items.
   * @returns True if there are related posts, otherwise false.
   */
  private hasTagPosts(posts: IPostListItemExtended[]): boolean {
    this.#hasTagPosts = posts.some(
      (post) =>
        post.id !== (this.#currentPostId || "") &&
        post.tags.some((tag) => this.#tags?.includes(tag))
    );
    return this.#hasTagPosts;
  }

  // Utility methods to fetch latest posts...

  /**
   * Fetches the latest advice posts.
   * @returns An array of latest advice posts.
   */
  get latestAdvice(): IPostListItemExtended[] {
    return useBlogLatestAdvice();
  }

  /**
   * Fetches the latest news posts.
   * @returns An array of latest news posts.
   */
  get latestNews(): IPostListItemExtended[] {
    return useBlogLatestNews();
  }

  /**
   * Fetches the related advice posts.
   * @returns An array of related advice posts.
   */
  get relatedAdvice(): IPostListItemExtended[] {
    const allAdvice = useBlogAllAdvice();
    return this.getRelatedPosts(allAdvice, "advice-hub");
  }

  /**
   * Fetches the related news posts.
   * @returns An array of related news posts.
   */
  get relatedNews(): IPostListItemExtended[] {
    const allNews = useBlogAllNews();
    return this.getRelatedPosts(allNews, "about-us/news");
  }
}

export default BlogCarouselMethods;
