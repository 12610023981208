/**
 * Used in the majority of components and on the SVG itself
 */
export interface ISVGBasicProps {
  className?: string;
  width?: string | number;
  height?: string | number;
}
/**
 * viewbox and label attributes used on the SVG
 */
export interface ISVGProps extends ISVGBasicProps {
  viewBox: string;
  "aria-label": string;
}
/**
 * Used by the majority of SVG components
 */
export interface ISVGComponentProps {
  colour?: ISVGColours;
  svgProps: ISVGProps;
}
/**
 * Used by the SVGCircle component. The colour attribute can
 * also include red and amber which it can't on ther components.
 * The symbol being used is also limited.
 */
export interface ISVGCircleComponentProps {
  colour?: ISVGCircleColours;
  symbolInside: "tick" | "cross" | "question-mark" | "exclamation-mark";
  svgProps: ISVGProps;
}
/**
 * Mostly the same as other SVG components only the direction of
 * the arrow is also included
 */
export interface ISVGArrowComponentProps extends ISVGComponentProps {
  direction: "right" | "left";
}
/**
 * The name of the icon and the SVG props
 */
export interface ISVGIconAndProps {
  icon: ISVGIconType;
  svgProps: ISVGProps;
}

/**
 * A list of all the SVGs available to use as icons/inline SVGs.
 * Using this method instead of standard union type so that we
 * can easily check if a string is a member of the union type
 * https://dev.to/hansott/how-to-check-if-string-is-member-of-union-type-1j4m
 */
export const allIconTypes = [
  "arrow-left",
  "arrow-pointing-right-going-into-rectangle",
  "arrow-right",
  "badge-with-tick-on-the-front",
  "bag-with-dollar-sign",
  "bath",
  "boiler",
  "boiler-with-large-display",
  "calculator",
  "calendar",
  "care-club-heart-inside-diamond",
  "clipboard",
  "clock",
  "clock-inside-circular-arrow-going-anticlockwise",
  "clock-with-thin-border",
  "cross-inside-circle",
  "ems-letters",
  "exclamation-mark-inside-circle",
  "face-mask",
  "gas-gauge",
  "gear",
  "handshake",
  "hard-hat",
  "headset",
  "jigsaw-puzzle-piece",
  "keyhole",
  "light-bulb",
  "lightning-bolt",
  "location-pointer",
  "magnifying-glass",
  "mobile-phone",
  "padlock",
  "people-shaking-hands-with-star-above",
  "person-with-question-mark",
  "phone-receiver",
  "piggy-bank",
  "pipe-with-bend",
  "plug-hole",
  "plus",
  "price-tag",
  "question-mark-inside-circle",
  "radiator",
  "rightio-logo",
  "shower",
  "shield-with-tick-on-the-front",
  "spanner",
  "spanner-and-screwdriver",
  "star",
  "star-five-out-of-five",
  "star-four-out-of-five",
  "star-with-thin-border",
  "tap",
  "team-of-people",
  "thumbs-up",
  "tick-inside-circle",
  "toilet",
  "van",
  "washing-machine",
  "water-droplet",
  "water-tank",
  "wire",
] as const;

type IconTypeTuple = typeof allIconTypes;
export type ISVGIconType = IconTypeTuple[number];

/**
 * A union type of all icons where the path element
 * has a fill attribute specifying the colour
 */
export const allPathFillIconTypes = [
  // The only styling is setting the colour of fill on a path element
  "arrow-pointing-right-going-into-rectangle",
  "bag-with-dollar-sign",
  "calculator",
  "calendar",
  "clock-inside-circular-arrow-going-anticlockwise",
  "ems-letters",
  "face-mask",
  "gear",
  "hard-hat",
  "headset",
  "location-pointer",
  "magnifying-glass",
  "people-shaking-hands-with-star-above",
  "person-with-question-mark",
  "piggy-bank",
  "rightio-logo",
  "shield-with-tick-on-the-front",
  "spanner-and-screwdriver",
  "star-with-thin-border",
  "team-of-people",
  "thumbs-up",
  "van",
  // paths with coloured fills and a stroke width of 0px
  "badge-with-tick-on-the-front",
  "light-bulb",
  "padlock",
  "pipe-with-bend",
  "price-tag",
  "toilet",
  "gas-gauge",
  "handshake",
  "shower",
  "star-five-out-of-five",
  "star-four-out-of-five",
  "tap",
  // paths with coloured fills, stroke width 0px and evenodd fill rule
  "bath",
  "washing-machine",
  "wire",
] as const;
type pathFillIconTypeTuple = typeof allPathFillIconTypes;
export type ISVGPathFillIconType = pathFillIconTypeTuple[number];

/**
 * A union type where the path element has a fill attribute specifying the
 * colour and the stroke width is set to zero pixels
 */
export const allPathStrokeZeroIconTypes = [
  "badge-with-tick-on-the-front",
  "light-bulb",
  "padlock",
  "pipe-with-bend",
  "price-tag",
  "toilet",
  "handshake",
  "shower",
  "star-five-out-of-five",
  "star-four-out-of-five",
  "tap",
  "bath",
  "washing-machine",
  "wire",
] as const;
type pathStrokeZeroIconTypeTuple = typeof allPathStrokeZeroIconTypes;
export type ISVGPathStrokeZeroIconType = pathStrokeZeroIconTypeTuple[number];

/**
 * A union type where he path element has a fill attribute specifying the
 * colour, the stroke width is set to zero pixels, and there is an evenodd
 * fill rule on the path.
 */
export const allPathFillEvenOddIconTypes = [
  "bath",
  "washing-machine",
  "wire",
] as const;
type pathFillEvenOddIconTypeTuple = typeof allPathFillEvenOddIconTypes;
export type ISVGPathFillEvenOddIconType = pathFillEvenOddIconTypeTuple[number];

/**
 * A union type where just the stroke colour is included
 */
export const allPathStrokeIconTypes = [
  "jigsaw-puzzle-piece",
  "keyhole",
  "water-droplet",
] as const;
type pathStrokeIconTypeTuple = typeof allPathStrokeIconTypes;
export type ISVGPathStrokeIconType = pathStrokeIconTypeTuple[number];

/**
 * A union type where the polygon has a fill rule specifying the colour
 */
export const allPolygonFillIconTypes = ["plus"] as const;
type polygonFillIconTypeTuple = typeof allPolygonFillIconTypes;
export type ISVGPolygonFillIconType = polygonFillIconTypeTuple[number];

/**
 * A union type where the polygon has a stroke rule specifying the colour
 */
export const allPolygonStrokeIconTypes = ["lightning-bolt", "star"] as const;
type polygonStrokeIconTypeTuple = typeof allPolygonStrokeIconTypes;
export type ISVGPolygonStrokeIconType = polygonStrokeIconTypeTuple[number];

/**
 * A union type where the polygon has a stroke width of 2.5px
 */
export const allPolygonStrokeTwoFiveIconTypes = ["star"] as const;
type polygonStrokeTwoFiveIconTypeTuple =
  typeof allPolygonStrokeTwoFiveIconTypes;
export type ISVGPolygonStrokeTwoFiveIconType =
  polygonStrokeTwoFiveIconTypeTuple[number];

export interface ISVGIconComponentProps extends ISVGBasicProps {
  icon: ISVGIconType;
  colour?: ISVGColours;
}
export interface ISVGPathFillComponentProps extends ISVGComponentProps {
  icon: ISVGPathFillIconType;
}
export interface ISVGPolygonFillComponentProps extends ISVGComponentProps {
  icon: ISVGPolygonFillIconType;
}
export interface ISVGPolygonStrokeComponentProps extends ISVGComponentProps {
  icon: ISVGPolygonStrokeIconType;
}
export interface ISVGPathStrokeComponentProps extends ISVGComponentProps {
  icon: ISVGPathStrokeIconType;
}
/**
 * Standard colours used by the makority of SVG components
 */
export type ISVGColours =
  | "black"
  | "green"
  | "white"
  | "blue"
  | "dark-green"
  | "dark-blue"
  | "light-blue";

/**
 * Includes the standard colours used by all SVG components and red and amber
 */
export type ISVGCircleColours =
  | "black"
  | "green"
  | "white"
  | "blue"
  | "dark-green"
  | "dark-blue"
  | "light-blue"
  | "red"
  | "amber";
