import * as colourStyles from "./SVGStyles/SVGColours.module.css";
import * as styles from "./SVGStyles/SVGPolygon.module.css";

import { ISVGPolygonFillComponentProps } from "@models/component-props";
import React from "react";
import { SVGFromIconName } from "@components/svgs";
import { useSVGColour } from "@hooks";

/**
 * A component to render a polygon with a fill colour, based on the given icon
 * and colour. This component ensures the styles specific to this icon are
 * applied.
 *
 * @param {ISVGPolygonFillComponentProps} props
 * @prop {ISVGIconType} icon - The icon to render
 * @prop {ISVGColours} [colour] - The colour of the SVG
 * @prop {ISVGProps} [svgProps] - Additional props to pass to the underlying SVG
 * @returns {React.ReactElement} The polygon SVG where generic styles are applied to the fill
 */
const SVGPolygonFill = ({
  icon,
  colour,
  svgProps,
}: ISVGPolygonFillComponentProps) => {
  // The class name based on the colour provided
  const className = `${styles.svgPolygonFill} ${useSVGColour(
    colourStyles,
    colour
  )} ${svgProps.className || ""}`;

  // Return the SVG code based on the icon and the class name
  return <SVGFromIconName icon={icon} svgProps={{ ...svgProps, className }} />;
};

export default SVGPolygonFill;
