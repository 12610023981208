import { useCallback, useEffect, useMemo, useState } from "react";

import useLocationPhoneAndAccountCodes from "./useLocationPhoneNumbers";
import useSiteMetadata from "./useSiteMetadata";

/**
 * Custom hook to manage phone number and account code based on location.
 * @returns {{phoneNumber: string, accountCode: number}} Phone number and account code based on location.
 */
const usePhoneAndAccountCode = () => {
  // Fetch site metadata
  const { phoneNumber: phone, accountCode: account } = useSiteMetadata();

  // Fetch location-specific phone numbers and account codes
  const locationPhoneNumbers = useLocationPhoneAndAccountCodes();

  // State to hold phone number and account code
  const [phoneAndAccountCode, setPhoneAndAccountCode] = useState({
    phoneNumber: phone,
    accountCode: account,
  });

  // Check if current URL path is a location page
  const locationPathFound =
    typeof window !== "undefined" &&
    typeof window.location !== "undefined" &&
    window.location.pathname.match(/^\/locations\//);

  // Memoize location details based on URL path
  const locationDetails = useMemo(() => {
    if (locationPathFound) {
      const locationSlug = window.location.pathname.replace("/locations/", "");
      const locationSlugSubString =
        locationSlug.indexOf("/") > -1
          ? locationSlug.substring(0, locationSlug.indexOf("/"))
          : locationSlug;
      return locationPhoneNumbers.find((x) => x.slug === locationSlugSubString);
    }
    return null;
  }, [locationPhoneNumbers]);

  // Callback function to handle location change
  const handleLocationChange = useCallback(() => {
    const phoneNumber =
      locationDetails?.phone_number &&
      locationDetails.phone_number.trim().length > 0
        ? locationDetails.phone_number
        : phone;
    const accountCode =
      locationDetails?.account_code && locationDetails.account_code > 9999
        ? locationDetails.account_code
        : account;

    if (phoneNumber !== phone)
      setPhoneAndAccountCode({ phoneNumber, accountCode });
  }, [phone, account, locationDetails]);

  // Effect to handle location change
  useEffect(() => {
    if (locationPathFound) handleLocationChange();
  }, []);

  return phoneAndAccountCode;
};
export default usePhoneAndAccountCode;
