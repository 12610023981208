import * as colourStyles from "./SVGStyles/SVGColours.module.css";
import * as styles from "./SVGStyles/SVGPath.module.css";

import {
  ISVGColours,
  ISVGPathFillEvenOddIconType,
  ISVGPathFillIconType,
  ISVGPathStrokeZeroIconType,
  ISVGProps,
  allPathFillEvenOddIconTypes,
  allPathStrokeZeroIconTypes,
} from "@models/component-props";

import React from "react";
import { SVGFromIconName } from "@components/svgs";
import { useSVGColour } from "@hooks";

/**
 * A component to render an SVG path with a fill colour, based on the given icon
 * and colour. This component ensures the styles specific to this icon are
 * applied.
 *
 * @param {{ icon: ISVGPathFillIconType, colour?: ISVGColours, svgProps: ISVGProps }} props
 * @prop {ISVGPathFillIconType} icon - The icon to render
 * @prop {ISVGColours} [colour] - The colour of the SVG
 * @prop {ISVGProps} [svgProps] - Additional props to pass to the underlying SVG
 * @returns {React.ReactElement} The path SVG where generic styles are applied to the fill
 */
const SVGPathFill = ({
  icon,
  colour,
  svgProps,
}: {
  icon: ISVGPathFillIconType;
  colour?: ISVGColours;
  svgProps: ISVGProps;
}) => {
  // Check if the path needs a stroke-width: 0px; rule
  const isStrokeZero = (value: string): value is ISVGPathStrokeZeroIconType => {
    return allPathStrokeZeroIconTypes.includes(
      value as ISVGPathStrokeZeroIconType
    );
  };

  // Check if the path needs a fill-rule: evenodd; rule
  const isFillEvenOdd = (
    value: string
  ): value is ISVGPathFillEvenOddIconType => {
    return allPathFillEvenOddIconTypes.includes(
      value as ISVGPathFillEvenOddIconType
    );
  };

  // The class name based on the colour provided and the results of the above
  const className = `${styles.svgPathFill} ${useSVGColour(
    colourStyles,
    colour
  )} ${isStrokeZero(icon) ? styles.svgPathStrokeWidthZero : ""} ${
    isFillEvenOdd(icon) ? styles.svgPathFillEvenOdd : ""
  } ${svgProps.className || ""}`;

  // Return the SVG code based on the icon and the class name
  return <SVGFromIconName icon={icon} svgProps={{ ...svgProps, className }} />;
};

export default SVGPathFill;
