import {
  IPostListItemExtended,
  IPostListItemsHook,
  PostListStyle,
} from "@models/hooks";
import { graphql, useStaticQuery } from "gatsby";

/**
 * Retrieves a list of blog items based on the specified page type.
 *
 * @param {string} pageType - The type of page to filter blog items by.
 * @return {IPostListItemExtended[]} A list of blog items with extended properties.
 */
const useBlogListItems = (pageType: string) => {
  // Can't pass the pageType to the query as you can't pass a variable
  // to static queries (otherwise they wouldn't be static) so filtering
  // by page_type is done after the results from the query are obtained.

  // Also sorting by date created in the query isn't always reliable
  // so sorting is done after the results from the query are obtained.
  const data = useStaticQuery<IPostListItemsHook>(graphql`
    {
      allBlog {
        edges {
          node {
            id
            page_type
            title
            slug
            excerpt
            tags
            date_created
            date_updated
            related_services {
              services_id {
                id
                type
                title
                slug
              }
            }
            hero {
              id
              title
              imageFile {
                childImageSharp {
                  gatsbyImageData(width: 300, height: 300, layout: FIXED)
                }
              }
            }
          }
        }
      }
    }
  `);

  // Filter by the relevant page type, sort by date created, and map
  // to post list item extended then return results
  return (
    data.allBlog.edges
      // Filter by page type
      .filter(({ node: b }) => b.page_type === pageType)
      // Sort by date created descending
      .sort(({ node: a }, { node: b }) => {
        return a.date_created > b.date_created
          ? -1
          : a.date_created < b.date_created
          ? 1
          : 0;
      })
      // Map to post list item extended
      .map(({ node: b }) => {
        return {
          ...b,
          styleType: PostListStyle.BlogItem,
          cta: "",
        } as IPostListItemExtended;
      })
  );
};

export default useBlogListItems;
