import * as styles from "./ReviewsCarouselSkeleton.module.css";

import React, { ReactNode, useId } from "react";

/**
 * A skeleton component that displays a loading state for a reviews carousel.
 *
 * This component renders a skeleton placeholder for a reviews carousel,
 * including a skeleton for the info section and up to three review items.
 *
 * @return {JSX.Element} The JSX element for the reviews carousel skeleton.
 */
const ReviewsCarouselSkeleton = () => {
  // The skeleton for each review that may be visible (max 3)
  const reviews: ReactNode[] = [];
  for (let i = 0; i < 3; i++) {
    reviews.push(
      <div key={useId()} className={`skeleton ${styles.reviewItem}`}>
        <div className={`skeletonBone ${styles.nameBone}`}></div>
        <div className={`skeletonBone ${styles.verifiedBone}`}></div>
        <div className={`skeletonBone ${styles.commentsBone}`}></div>
        <div className={`skeletonBone ${styles.dateBone}`}></div>
      </div>
    );
  }
  return (
    <>
      <section className={`skeleton inner ${styles.reviewsCarouselSkeleton}`}>
        {/* Skeleton for the info section */}
        <div className={`skeleton ${styles.reviewsInfo}`}>
          <div className={`skeletonBone ${styles.wordBone}`}></div>
          <div className={`skeletonBone ${styles.starsBone}`}></div>
          <div className={`skeletonBone ${styles.statsBone}`}></div>
          <div className={`skeletonBone ${styles.logoBone}`}></div>
        </div>
        {/* Skeleton for the reviews */}
        <div className={`skeleton ${styles.reviewItems}`}>{reviews}</div>
      </section>
    </>
  );
};

export default ReviewsCarouselSkeleton;
