import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { HtmlInline, HtmlList, inlineTagRegex } from "@components/html";
import {
  IHtmlInlineWithContentImageProps,
  IHtmlOutputProps,
  IProcessHtmlMatchesProps,
} from "@models/component-props";
import React, { Fragment } from "react";
import { usePhoneAndAccountCode, useSiteMetadata } from "@hooks";

import { AdaptableHTag } from "@components/general";

/**
 * A component that cleans HTML provided in a string and outputs it as JSX elements.
 * This stops style or class attributes or even invalid tags from being rendered.
 *
 * The component handles images and converts them to GatsbyImage components instead of img tags.
 *
 * It also calls HTMLInline to handle links, ensuring internal links use the Link component,
 * and external links have the relevant target and rel attributes.
 *
 * As well as this, it also handles HTML lists and does the necessary processing if they have
 * sub lists or nested elements.
 *
 * @param {string} html - The HTML content to be processed and rendered.
 * @param {string} [title=""] - The title of the HTML content.
 * @param {array} [contentImages=[]] - An array of content images.
 * @return {JSX.Element} The processed and rendered HTML content.
 */
const HtmlOutput = ({
  html,
  title = "",
  contentImages = [],
}: IHtmlOutputProps) => {
  const { phoneNumber } = usePhoneAndAccountCode();

  // Clean HTML string by removing line breaks and replacing HTML entities
  const inputHtml = cleanHTMLString(html, phoneNumber);

  // Split the input html string into chunks of individual elements
  const htmlElements = inputHtml.match(upperLevelHtmlRegex);

  // Default output, an empty fragment
  let output = [<Fragment key="Fragment_output"></Fragment>];

  // Process the HTML elements if the string is not empty
  // and there are matches and add them to the output
  if (inputHtml.length > 0 && htmlElements)
    output = processHtmlMatches({
      htmlElements,
      title,
      contentImages,
    });

  // Return the output
  return <Fragment key="Fragment">{output}</Fragment>;
};

/**
 * This regular expression is used to split the html string into chunks of
 * individual elements. It matches the following:
 * - A blockquote element with content that may contain new lines
 *   (required as there may be lists included within the blockquote)
 * - A p, h1, h2, h3, h4, h5, or h6 element with content
 * - An ul or ol element with content, which may contain multiple li
 *   elements. The content of each li element is captured.
 * - A ul or ol element that is not followed by another ul or ol
 *   element, but may contain content. This is to handle cases where
 *   there is a solitary list item without any text.
 * - An hr element (either self-closing or not)
 */
export const upperLevelHtmlRegex =
  /<blockquote.*?>(([\s\S]*?))<\/blockquote>|<(?<t0>(p|h1|h2|h3|h4|h5|h6)).*?>(.*?)<\/\k<t0>>|<(?<t1>(ul|ol)).*?>(?:(?!<\k<t1>.*?>).)*<li.*?>(.*?)<(u|o)l.*?>(?:(?!<(li|ul|ol))).*(?:<\/(li|ul|ol)>)<\/li>*(?:(?!<\/\k<t1>>).)<\/\k<t1>>|<(?<t2>(ul|ol)).*?>(?:(?!<\k<t2>.*?>).)*(?:(?!<\/\k<t2>>).<\/\k<t2>>)|<hr.*?\/*>/gi;

const processHtmlMatches = ({
  htmlElements,
  title,
  contentImages,
}: IProcessHtmlMatchesProps) => {
  // Get site metadata and phone number
  const { domain, title: siteTitle } = useSiteMetadata();

  // Get page title or use site title if not provided
  const pageTitle = title && title.trim().length > 0 ? title : siteTitle;

  // Process the HTML elements
  return htmlElements.map((e, i) => {
    const key = `${i}`;
    const htmlNormProps = {
      domain,
      pageTitle,
      contentImages,
      keyProvided: key,
    };

    // Default element
    let htmlEl = <Fragment key={`Fragment_${key}`}></Fragment>;

    // If the element is a blockquote
    if (e.match(/^<blockquote.*?>(.*?)<\/blockquote>$/gis)) {
      htmlEl = genereateBlockQuoteElement({
        htmlString: e,
        keyProvided: key,
        domain,
        pageTitle,
        contentImages,
      });
    }
    // If the element is a list type element
    else if (e.match(/^<(?<t0>(ul|ol)).*?>(.*?)<\/\k<t0>>$/gi))
      htmlEl = (
        <HtmlList
          key={`htmlList_${key}`}
          domain={domain}
          pageTitle={pageTitle}
          htmlString={e}
          keyProvided={`${i}`}
        />
      );
    // If the element is a h1, h2, h3, h4, h5, or h6
    else if (e.match(/^<(?<t0>(h1|h2|h3|h4|h5|h6)).*?>(.*?)<\/\k<t0>>$/gi)) {
      const he = e.match(inlineTagRegex)
        ? e.replace(
            /^<(?<t0>(h1|h2|h3|h4|h5|h6)).*?>(.*?)<\/\k<t0>>$/gi,
            "<$1><span>$3</span></$1>"
          )
        : e;
      htmlEl = (
        <AdaptableHTag
          key={`AdaptableHTag_${key}`}
          hTagLevel={
            e.match(/<h(1|2)/gi)
              ? 2
              : e.match(/<h3/gi)
              ? 3
              : e.match(/<h4/gi)
              ? 4
              : e.match(/<h5/gi)
              ? 5
              : 6
          }
        >
          <HtmlInline
            key={`htmlNorm_htag_${key}`}
            htmlString={he}
            {...htmlNormProps}
          />
        </AdaptableHTag>
      );
    }
    // If the element is a paragraph
    else if (e.match(/^<p.*?>(.*?)<\/p>$/gi)) {
      // // The way the html editor works, images can end up in paragraphs.
      // // This works around that
      const imgMatch = e.match(/<img.*?\/?>/gi);
      if (imgMatch) {
        htmlEl = generateImgElement({
          htmlString: e,
          keyProvided: key,
          domain,
          pageTitle,
          contentImages,
        });
      } else {
        // If there is no image, process the tag like a normal paragraph
        const pe = e.match(inlineTagRegex)
          ? e.replace("<p>", "<p><span>").replace("</p>", "</span></p>")
          : e;
        htmlEl = (
          <p key={`p_${key}`}>
            <HtmlInline
              key={`htmlNorm_p_${key}`}
              htmlString={pe}
              {...htmlNormProps}
            />
          </p>
        );
      }
    }
    // If the element is a hr
    else if (e.match(/^<hr.*?\/*>$/)) htmlEl = <hr key={`hr_${key}`} />;

    // Return the element
    return htmlEl;
  });
};

/**
 * Cleans an HTML string by removing line breaks, replacing char codes,
 * replacing common HTML entities, replacing div tags with p tags, removing
 * comments, removing attributes from headers, adding line breaks to list
 * items, and replacing phone number with phone link.
 *
 * @param {string} html - The HTML string to clean.
 * @param {string} phoneNumber - The phone number to replace in the HTML.
 * @return {string} The cleaned HTML string.
 */
const cleanHTMLString = (html: string, phoneNumber: string) => {
  return (
    html
      // Remove all line breaks to help regex when searching
      .replace(/[\r\n\t]/g, "")
      // Replace all char codes with their specified character
      .replace(/&#([0-9]{3,5});/gi, String.fromCharCode(+"$1"))
      // Replace some common html entities
      // A more comprehensive list of html entities can be found here:
      // https://www.freeformatter.com/html-entities.html
      .replace(/&nbsp;|&ensp;|&emsp;|&thinsp;|&zwnj;|&zwj;|&lrm;|&rlm;/gi, " ")
      .replace(/&ndash;|&mdash;/gi, "-")
      .replace(/&lsquo;|&rsquo;|&sbquo;/gi, "'")
      .replace(/&ldquo;|&rdquo;|&bdquo;/gi, '"')
      .replace(/&pound;/gi, "£")
      .replace(/&copy;/gi, "©")
      .replace(/&reg;/gi, "®")
      .replace(/&bull;/gi, "•")
      .replace(/&ordm;/gi, "º")
      .replace(/&hellip;/gi, "...")
      .replace(/&euro;/gi, "€")
      .replace(/&trade;/gi, "™")
      .replace(/&deg;/gi, "°")
      .replace(/&amp;/gi, "&")
      // Replace div tags with p tags
      .replace(/<(\/*)div.*?>/gi, "<$1p>")
      // Remove all comments
      .replace(/<!--.*?-->/gi, "")
      // Remove attributes from headers
      .replace(
        /<(?<t0>(h1|h2|h3|h4|h5|h6)).*?>(.*?)<\k<t0>><p>/gi,
        "<$1>$3</$1><p>"
      )
      // Add line breaks to all list items (this helps identify list items easier)
      .replace(/><ul.*?>/gi, ">\n<ul>")
      .replace(/><ol.*?>/gi, ">\n<ol>")
      // Replace phone number with phone link
      .replace(
        /href="#phone#"/gi,
        `href="tel:${phoneNumber.replace(/\s/g, "")}"`
      )
      .replace(/#phone#/gi, phoneNumber)
      // Trim the html
      .trim() || ""
  );
};

/**
 * Generates JSX for a blockquote element, handling nested upper level tags.
 *
 * @param {IHtmlInlineWithContentImageProps} props - The properties for the blockquote element.
 * @param {string} props.htmlString - The HTML string to process.
 * @param {string} props.keyProvided - A unique key for the blockquote element.
 * @param {object} props.contentImages - The content images for the blockquote element.
 * @param {string} props.domain - The domain for the blockquote element.
 * @param {string} props.pageTitle - The page title for the blockquote element.
 * @return {JSX.Element} The JSX for the blockquote element.
 */
const genereateBlockQuoteElement = ({
  htmlString,
  keyProvided,
  contentImages,
  domain,
  pageTitle,
}: IHtmlInlineWithContentImageProps) => {
  // Remove blockquotes to check for nested upper level tags
  const blockquoteHtml = htmlString.replace(
    /<blockquote.*?>(.*?)<\/blockquote>/gis,
    "$1"
  );
  // Check if the blockquote contains nested upper level tags
  const blockquoteElements = blockquoteHtml.match(upperLevelHtmlRegex);

  return blockquoteElements ? (
    // If there are nested upper level tags, create a blockquote tag and process the inner HTML
    <blockquote key={`blockquote_${keyProvided}`}>
      {processHtmlMatches({
        htmlElements: blockquoteElements,
        title: pageTitle,
        contentImages,
      })}
    </blockquote>
  ) : (
    // If there are no nested upper level tags, create a blockquote tag with a single paragraph tag
    <blockquote key={`blockquote_${keyProvided}`}>
      <p key={`p_${keyProvided}`}>
        <HtmlInline
          key={`htmlNorm_p_${keyProvided}`}
          htmlString={htmlString}
          domain={domain}
          keyProvided={keyProvided}
          pageTitle={pageTitle}
        />
      </p>
    </blockquote>
  );
};

/**
 * Generates an img element from a given html string, processing images and paragraphs.
 * This will use the GatsbyImage component if the image can be found in the content images.
 * Otherwise, it will use a regular img tag.
 *
 * It also processes the content around an image to, separating it onto a different line.
 *
 * @param {IHtmlInlineWithContentImageProps} props - The properties for the function.
 * @param {string} props.htmlString - The html string to process.
 * @param {string} props.keyProvided - The key to use for the fragment.
 * @param {object[]} props.contentImages - The content images to use.
 * @param {string} props.domain - The domain to use.
 * @param {string} props.pageTitle - The page title to use.
 * @return {JSX.Element} The generated img element.
 */
const generateImgElement = ({
  htmlString,
  keyProvided,
  contentImages,
  domain,
  pageTitle,
}: IHtmlInlineWithContentImageProps) => {
  // Images end up in paragraph tags, and it can mean that there's content
  // around the image that needs to be processed
  const formattedHtml = htmlString
    .replace(/<strong.*?><img(.*?)\/?><\/strong>/gi, "<img$1/>")
    .replace(/<strong.*?><\/strong>/gi, "")
    .replace(/<em.*?><img(.*?)\/?><\/em>/gi, "<img$1/>")
    .replace(/<em.*?><\/em>/gi, "")
    .replace(/<span(.*?)><img(.*?)\/?><\/span>/gi, "<img$2/>")
    .replace(/<span.*?><\/span>/gi, "")
    .replace(/<p.*?><img(.*?)\/?><\/p>/gi, "<img$1/>")
    .replace(/<p>(.*?)<img(.*?)\/?>(.*?)<\/p>/gi, "<p>$1</p><img$2/><p>$3</p>")
    .replace(/<p><\/p>/gi, "");

  // Default element
  const tagFragment = <Fragment key={`Fragment_${keyProvided}`}></Fragment>;

  // Only process if there are image / paragraph tags
  const tagMatch = formattedHtml.match(/<p>(.*?)<\/p>|<img(.*?)\/?>/gi);
  if (!tagMatch) return tagFragment;

  return (
    <Fragment key={`Fragment_${keyProvided}`}>
      {tagMatch.map((tag: string, x: number) => {
        // Paragraph tags
        if (tag.startsWith("<p")) {
          return (
            <p key={`p_${keyProvided}_${x}`}>
              <HtmlInline
                key={`htmlNorm_p_${keyProvided}_${x}`}
                htmlString={tag}
                domain={domain}
                keyProvided={`htmlNorm_p_${keyProvided}_${x}`}
                pageTitle={pageTitle}
              />
            </p>
          );
        } else {
          // Only process if the image has a valid source attribute
          const imgSrcMatch = tag.match(/src=".*?"/gi);
          if (imgSrcMatch) {
            // Get the source of the image
            const imgSrc = imgSrcMatch[0].replace(/src="(.*?)"/gi, "$1").trim();

            // Check if the image is in the contentImages array, only process if it is
            const contentImage = contentImages.find(
              (ci) => ci.externalUrl === imgSrc
            );
            if (contentImage) {
              // Get the alt text of the image. If the alt text is empty, use the title of the page
              const imgAlt =
                contentImage.alt.length > 0
                  ? contentImage.alt
                  : `${pageTitle} - image`;

              // If the image is a type which can be used by GatsbyImage, use the GatsbyImage component
              if (
                [
                  "image/avif",
                  "image/jpeg",
                  "image/png",
                  "image/webp",
                ].includes(contentImage.mime)
              ) {
                // Get the image from the contentImages array, continue if found
                const img = getImage(contentImage.imageFile);
                if (img) {
                  // Set the width and height of the image (if supplied)
                  if (contentImage.width > 0) img.width = contentImage.width;
                  if (contentImage.height > 0) img.height = contentImage.height;

                  // Return the GatsbyImage component
                  return (
                    <GatsbyImage
                      key={`img_${keyProvided}_${x}`}
                      image={img}
                      alt={imgAlt}
                    />
                  );
                } else return tagFragment;
              } else {
                // If the image is not a type which can be used by GatsbyImage, use the img tag
                // Set the width and height of the image (if supplied)
                const imgWidth =
                  contentImage.width && contentImage.width > 0
                    ? contentImage.width
                    : undefined;
                const imgHeight =
                  contentImage.height && contentImage.height > 0
                    ? contentImage.height
                    : undefined;

                // Return the img tag
                return (
                  <div key={`div_${keyProvided}_${x}`}>
                    <img
                      key={`img_${keyProvided}_${x}`}
                      src={contentImage.imageFile.publicURL}
                      alt={imgAlt}
                      width={imgWidth}
                      height={imgHeight}
                    />
                  </div>
                );
              }
            } else return tagFragment;
          } else return tagFragment;
        }
      })}
    </Fragment>
  );
};

export default HtmlOutput;
