import * as styles from "./SVGClipPath.module.css";

import React from "react";

/**
 * A component to render SVG clip paths. The SVG element is sized to 0x0 and is
 * used purely for defining clip paths, which can be referenced in CSS to cut
 * elements into the shape of the clip path.
 *
 * @param {Object} props
 * @param {Array} props.clipPaths - An array of objects defining each clip path.
 * @param {string} props.clipPaths[].id - The unique identifier for the clip path.
 * @param {React.ReactNode} props.clipPaths[].children - The SVG content of the clip path.
 * @returns {React.ReactElement} An SVG element containing the defined clip paths.
 */
const SVGClipPath = ({
  clipPaths,
}: {
  clipPaths: {
    id: string;
    children: React.ReactNode;
  }[];
}) => {
  return (
    <svg
      width="0"
      height="0"
      viewBox="0 0 1 1"
      className={styles.svg}
      role="presentation"
      aria-label="hidden"
    >
      {clipPaths.map((clipPath) => {
        return (
          <clipPath
            id={clipPath.id}
            key={clipPath.id}
            clipPathUnits="objectBoundingBox"
          >
            {clipPath.children}
          </clipPath>
        );
      })}
    </svg>
  );
};

export default SVGClipPath;
