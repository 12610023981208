import {
  IDirectusContentImage,
  IDirectusContentImages,
  IImageData,
  IImageFileData,
} from "@models/directus";
import { getImage, getSrc } from "gatsby-plugin-image";

import { IWebPageImageProps } from "@models/component-props";
import useSiteMetadata from "./useSiteMetadata";
import useSlugToTitle from "./useSlugToTitle";

/**
 * Custom hook to generate page-related objects like URL, breadcrumbs, and images.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {string} params.slug - The slug of the page.
 * @param {string} params.title - The title of the page.
 * @param {IDirectusContentImages|null} [params.directusContentImages=null] - Directus content images, if any.
 * @param {(IImageData|null)[]|null} [params.extraImages=null] - Additional images, if any.
 * @returns {Object} Page-related objects including domain, URL, breadcrumbs, canonical URL, content images, and images.
 */
const usePageObjects = ({
  slug,
  title,
  directusContentImages = null,
  extraImages = null,
}: {
  slug: string;
  title: string;
  directusContentImages?: null | IDirectusContentImages;
  extraImages?: (IImageData | null)[] | null;
}) => {
  const { domain, title: siteTitle } = useSiteMetadata(); // The domain of the site
  const pathName = getPathName(slug); // The path name without the trailing slash
  const paths = getPaths(pathName); // The paths to the page in order from home.
  const url = `${domain}/${pathName}`; // The full URL of the page.
  const canonical = url; // The canonical URL of the page.

  // The breadcrumbs for the page.
  const breadcrumbs = getBreadcrumbs(domain, url, paths, title);

  // The images inserted into the content
  const contentImages = directusContentImages
    ? directusContentImages.contentImages
    : [];

  // The images from the content plus any other images inserted by image fields
  // on directs (for example the hero image)
  const images =
    (extraImages && extraImages.length > 0) || contentImages.length > 0
      ? getWebPageImagePropsArray(domain, title, contentImages, extraImages)
      : [];

  // Return the page objects
  return {
    domain,
    url,
    breadcrumbs,
    canonical,
    contentImages,
    images,
    siteTitle,
  };
};

/**
 * Generates the path name from the slug.
 *
 * @param {string} slug - The slug of the page.
 * @returns {string} The path name.
 */
const getPathName = (slug: string) => {
  const pathName = slug.endsWith("/")
    ? slug.substring(0, slug.length - 2)
    : slug;
  return pathName;
};

/**
 * Splits the path name into an array of paths.
 *
 * @param {string} pathName - The path name.
 * @returns {string[]} The array of paths.
 */
const getPaths = (pathName: string) => {
  const pathNameFinal = pathName.replace(/page\/([0-9]+)$/gi, "page-$1");
  const paths = pathNameFinal.split("/");
  return paths;
};

/**
 * Generates breadcrumbs for the page.
 *
 * @param {string} domain - The domain of the site.
 * @param {string} url - The URL of the page.
 * @param {string[]} paths - The array of paths.
 * @param {string} title - The title of the page.
 * @returns {Object[]} The breadcrumbs including the position, name, and the URL.
 */
const getBreadcrumbs = (
  domain: string,
  url: string,
  paths: string[],
  title: string
) => {
  // If the URL is the homepage, return an array with just the home page breadcrumb
  if (url === `${domain}/`)
    return [
      {
        position: 1,
        name: title,
        item: domain,
      },
    ];

  // Otherwise, generate the breadcrumbs. The home page is hardcoded as the first breadcrumb
  const breadcrumbs = [
    {
      position: 1,
      name: "Home",
      item: domain,
    },
  ].concat(
    paths.map((path, index) => {
      // Replaces hyphens in a path with a space and sets it so each word starts
      // with an uppercase letter to generate the page title for the specific breadcrumb
      let name = useSlugToTitle(path);

      // Generates the link for the breadcrumb
      let fullPath = `${domain}/`;
      if (index > 0)
        for (let x = 0; x < index; x++) fullPath = `${fullPath}${paths[x]}/`;
      fullPath = `${fullPath}${path}`;

      // The final breadcrumb has a slightly different set up as it is the current page
      if (index === paths.length - 1) {
        // Sets the final breadcrumb URL to the current URL
        fullPath = url;
        // Sets the title for the final breadcrumb based on if it's a pagination page or
        // the title/meta title that's been supplied
        name = path.match(/page-([0-9]+)$/)
          ? `Page ${path.substring(5)}`
          : title;
      }

      // The final breadcrumb object
      return {
        position: index + 2,
        name,
        item: fullPath,
      };
    })
  );
  return breadcrumbs;
};

/**
 * Generates an array of web page image properties used for schema and open graph.
 *
 * @param {string} domain - The domain of the site.
 * @param {string} title - The title of the page.
 * @param {IDirectusContentImage[]} contentImages - The Directus content images.
 * @param {(IImageData|null)[]|null} extraImages - Additional images.
 * @returns {IWebPageImageProps[]} The array of web page image properties.
 */
const getWebPageImagePropsArray = (
  domain: string,
  title: string,
  contentImages: IDirectusContentImage[],
  extraImages: (IImageData | null)[] | null
) => {
  // Creates an IWebPageImageProps array from the content images
  const webPageContentImages = contentImages.map((ci, i) => {
    // SVGs can't use Gatsby Image. if this is an SVG, the public URL is used.
    const imgSrc =
      ci.ext === ".svg" ? ci.imageFile.publicURL : getSrc(ci.imageFile);

    // Get the correct width and height for the image
    const imageSize = getImageSize({
      imageFile: ci,
      passedWidth: ci.width,
      passedHeight: ci.height,
      extension: ci.ext,
    });

    // Gets the alt text for the image. If no alt text is supplied, alt text is
    // generated based off the title of the page and the current image index
    const imgAlt = ci.alt.length > 0 ? ci.alt : `${title} - image ${i}`;

    // The full IWebPageImageProps object
    return {
      url: `${domain}${imgSrc}`,
      width: imageSize.width,
      height: imageSize.height,
      alt: imgAlt,
    };
  });

  // Creates an IWebPageImageProps array from the extra images.
  // If null, an empty array is produced.
  const webPageExtraImages = extraImages
    ? (extraImages
        .map((ei) => {
          // Returns if the object is null.
          if (!ei) return;

          // Get the correct width and height for the image
          const imageSize = getImageSize({
            imageFile: ei,
            passedWidth: ei.width,
            passedHeight: ei.height,
          });

          // The full IWebPageImageProps object
          const webPageImageProp: IWebPageImageProps = {
            url: `${domain}${getSrc(ei.imageFile)}`,
            width: imageSize.width,
            height: imageSize.height,
            alt: ei.title,
          };
          return webPageImageProp;
        })
        .filter((x) => x) as IWebPageImageProps[]) // Filters out undefined results
    : [];

  // Joins the two arrays together. extraImages go first as this
  // includes images important to the page such as the hero image
  const images: IWebPageImageProps[] =
    webPageExtraImages.concat(webPageContentImages);
  return images;
};

/**
 * Calculates the width and height of an image, considering passed dimensions and file type.
 *
 * @param {Object} params - The parameters for the function.
 * @param {IImageFileData} params.imageFile - The image file data.
 * @param {number} params.passedWidth - The width passed for the image.
 * @param {number} params.passedHeight - The height passed for the image.
 * @param {string} [params.extension=""] - The file extension of the image.
 * @returns {Object} The calculated width and height of the image.
 * @returns {number|null} return.width - The calculated width of the image.
 * @returns {number|null} return.height - The calculated height of the image.
 */
const getImageSize = ({
  imageFile,
  passedWidth,
  passedHeight,
  extension = "",
}: {
  imageFile: IImageFileData;
  passedWidth: number;
  passedHeight: number;
  extension?: string;
}) => {
  // If an SVG, Return the passed through sizes or null if no sizes are passed
  if (extension === ".svg")
    return {
      width: passedWidth && passedWidth > 0 ? passedWidth : null,
      height: passedHeight && passedHeight > 0 ? passedHeight : null,
    };

  // Get the image to retrieve the rendered width and height.
  const img = getImage(imageFile.imageFile);

  // Gets the rendered width of the image. If it's not returned,
  // uses the width that was passed through. Otherwise the width is null.
  const width =
    img && img.width && img.width > 0
      ? img.width
      : passedWidth && passedWidth > 0
      ? passedWidth
      : null;

  // Gets the rendered height of the image. If it's not returned,
  // uses the height that was passed through. Otherwise the height is null.
  const height =
    img && img.height && img.height > 0
      ? img.height
      : passedHeight && passedHeight > 0
      ? passedHeight
      : null;

  // Returns the calculated width and height
  return { width, height };
};
export default usePageObjects;
