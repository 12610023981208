/**
 * Checks if monitoring is enabled and should be done.
 *
 * @returns {boolean} True if monitoring is enabled, false otherwise.
 */
const useMonitoring = () => {
  if (
    typeof window === "undefined" ||
    typeof window.location === "undefined"
    // || window.location.host.indexOf("localhost") > -1
  )
    return false;
  return typeof process.env.GATSBY_MONITORING_ENDPOINT !== "undefined";
};
export default useMonitoring;
