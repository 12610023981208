import * as styles from "./RadioButton.module.css";

import React from "react";

const RadioButton = ({
  name,
  label,
  value,
  onChange,
  checked,
}: {
  name: string;
  label: string;
  value: string;
  onChange: (_value: string) => void;
  checked?: boolean;
}) => {
  // Handles the change event of the radio button
  const onChangeHandler = () => {
    onChange(value);
  };
  return (
    <label htmlFor={`${name}-${value}`} className={styles.radioLabel}>
      <div className={styles.radioField}>
        {/* 
          Input for the radio button, not shown to the user, 
          they see the span for the image and the text 
        */}
        <input
          id={`${name}-${value}`}
          className={styles.radioFieldInput}
          name={name}
          type="radio"
          value={value}
          onChange={onChangeHandler}
          checked={checked}
        />

        {/* Span for the radio button image */}
        <span className={styles.radioFieldSpan}></span>
      </div>

      {/* Display the text for the radio button */}
      <span>{label}</span>
    </label>
  );
};

export default RadioButton;
