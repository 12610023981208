import * as colourStyles from "../SVGStyles/SVGColours.module.css";
import * as styles from "./SVGPlughole.module.css";

import { ISVGComponentProps } from "@models/component-props";
import React from "react";
import { SVGFromIconName } from "@components/svgs";
import { useSVGColour } from "@hooks";

/**
 * A component to render a plug-hole SVG based on a given colour.
 * This component ensures the styles specifc to this icon are applied.
 *
 * @param {ISVGComponentProps} props
 * @prop {ISVGColours} [colour] - The colour of the plug-hole.
 * @prop {ISVGProps} [svgProps] - Additional props to pass to the underlying SVG
 * @returns {React.ReactElement} The plug-hole SVG
 */
const SVGPlugHole = ({ colour, svgProps }: ISVGComponentProps) => {
  // The class name based on the colour provided
  const className = `${styles.svgPlugHole} ${useSVGColour(
    colourStyles,
    colour
  )} ${svgProps.className || ""}`;

  // Return the SVG code based on the icon and the class name
  return (
    <SVGFromIconName icon={"plug-hole"} svgProps={{ ...svgProps, className }} />
  );
};

export default SVGPlugHole;
