import * as buttonElementStyles from "./ButtonElement.module.css";
import * as buttonStyles from "../Button.module.css";

import { ButtonStyle, IButtonElementProps } from "@models/component-props";

import React from "react";

/**
 * Renders a button element with customizable text, style, and click event handler.
 *
 * @param {IButtonElementProps} props - The props object containing the following properties:
 *   - buttonText: The text to display on the button.
 *   - title: The title attribute of the button.
 *   - onClick: The click event handler function.
 *   - buttonStyle: The style of the button.
 *   - className: Additional CSS classes for the button.
 *   - childClassNames: Additional CSS classes for child elements.
 *   - showLoading: Whether to show a loading spinner.
 * @return {JSX.Element} The rendered button element.
 */
const ButtonElement = ({
  title,
  onClick,
  buttonStyle = ButtonStyle.Primary,
  className = "",
  childClassNames = {},
  showLoading = false,
}: IButtonElementProps) => {
  // State to track whether the button is currently loading
  const [isLoading, setIsLoading] = React.useState(false);

  /**
   * Event handler for the button click event.
   * Sets the loading state and calls the onClick function.
   */
  const onClickEventHandler = async () => {
    // For testing how the loading indicator looks. To test, uncomment the following line:
    // setIsLoading(!isLoading);
    // And comment out the lines below.
    if (showLoading) setIsLoading(true);
    await onClick();
    if (showLoading) setIsLoading(false);
  };

  // Mapping of button styles to CSS classes
  const buttonClassNames = {
    [ButtonStyle.Primary]: buttonStyles.button1,
    [ButtonStyle.Secondary]: buttonStyles.button2,
    [ButtonStyle.Tertiary]: buttonStyles.button3,
    [ButtonStyle.TransparentBackgroundWhiteBorder]: buttonStyles.button4,
    [ButtonStyle.TransparentBackgroundGreenBorder]: buttonStyles.button5,
  };

  // Determine the CSS class for the button based on the button style
  const buttonClassName = buttonClassNames[buttonStyle] || buttonStyles.button1;

  return (
    <button
      className={`${buttonStyles.button} ${
        buttonElementStyles.button
      } ${buttonClassName} ${
        isLoading ? buttonElementStyles.loading : ""
      } ${className}`}
      type="button"
      onClick={onClickEventHandler}
      aria-label={isLoading ? "Loading…" : title}
      disabled={isLoading}
    >
      {/* Button content */}
      <span className={buttonElementStyles.buttonInner}>
        <span
          className={`${buttonElementStyles.buttonContent} ${
            Object.prototype.hasOwnProperty.call(
              childClassNames,
              "buttonContent"
            )
              ? childClassNames.buttonContent
              : ""
          }`}
        >
          {title}
        </span>
        {/* Loading spinner */}
        {showLoading && (
          <span
            className={`${buttonElementStyles.loadingContainer} ${
              Object.prototype.hasOwnProperty.call(
                childClassNames,
                "loadingContainer"
              )
                ? childClassNames.loadingContainer
                : ""
            }`}
          >
            <span className={buttonElementStyles.loadingSpinner}></span>
            <span className={buttonElementStyles.loadingText}>
              {isLoading && "Loading…"}
            </span>
          </span>
        )}
      </span>
    </button>
  );
};

export default ButtonElement;
