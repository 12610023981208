import { ReviewsIOContext } from "@store";
import { useContext } from "react";

export const useReviewsIOContext = () => {
  const context = useContext(ReviewsIOContext);
  if (context === undefined) {
    throw new Error(
      "useReviewsIOContext must be used within a ReviewsIOProvider"
    );
  }
  return context;
};
