import { IPostListItemExtended, IPostListItemsHook } from "@models/hooks";
import { graphql, useStaticQuery } from "gatsby";

/**
 * Retrieves all blog items that are classified as 'advice-hub' and orders them by the
 * date they were created in descending order (newest first). The returned objects are
 * then extended with a style type and a cta.
 *
 * @return {IPostListItemExtended[]} A list of blog items with extended properties.
 */
const useBlogAllAdvice = () => {
  const data = useStaticQuery<IPostListItemsHook>(graphql`
    {
      allBlog(filter: { page_type: { eq: "advice-hub" } }) {
        edges {
          node {
            id
            title
            slug
            excerpt
            tags
            date_created
            date_updated
            related_services {
              services_id {
                id
                type
                title
                slug
              }
            }
            hero {
              id
              title
              imageFile {
                childImageSharp {
                  gatsbyImageData(height: 300, width: 300, layout: FIXED)
                }
              }
            }
          }
        }
      }
    }
  `);

  // Return the items in date descending order and add the style type and cta
  return data.allBlog.edges
    .sort(({ node: a }, { node: b }) => {
      return a.date_created > b.date_created
        ? -1
        : a.date_created < b.date_created
        ? 1
        : 0;
    })
    .map(({ node: b }) => {
      return {
        ...b,
        styleType: 1,
        cta: "",
        page_type: "advice-hub",
      } as IPostListItemExtended;
    });
};

export default useBlogAllAdvice;
