import * as buttonLinkStyles from "./ButtonLinkWithArrow.module.css";

import { ButtonStyle, IButtonLinkProps } from "@models/component-props";

import { ButtonLink } from "@components/ui";
import React from "react";

/**
 * Renders a button link with an arrow.
 *
 * @param {string} link - The URL or path to navigate to when the button is clicked.
 * @param {string} buttonText - The text to display on the button.
 * @param {string} title - The title of the button (optional).
 * @param {ButtonStyle} buttonStyle - The style of the button (default: ButtonStyle.Primary).
 * @param {string} className - Additional class names to apply to the button (optional).
 * @return {JSX.Element} The rendered button link with an arrow.
 */
const ButtonLinkWithArrow = ({
  link,
  title,
  buttonStyle = ButtonStyle.Primary,
  className = "",
}: IButtonLinkProps) => {
  return (
    <ButtonLink
      link={link}
      title={title}
      buttonStyle={buttonStyle}
      className={`${buttonLinkStyles.buttonLink} ${className}`}
    />
  );
};

export default ButtonLinkWithArrow;
