import {
  ILogGrafanaClickToCallEvent,
  ILogGrafanaFormError,
  ILogGrafanaFormEvent,
  ILogGrafanaRequestError,
} from "@models";

import { faro } from "@grafana/faro-react";
import useMonitoring from "../hooks/useMonitoring";

class LogToGrafana {
  #isMonitoringEnabled: boolean;
  constructor() {
    this.#isMonitoringEnabled =
      useMonitoring() && typeof faro.api !== "undefined";
  }
  /**
   * Tracks any forms filled
   * @param eventProps The type of form, the response received
   * from the submission, and any additional data that may need
   * to be logged.
   */
  logFormFillEvent = (eventProps: ILogGrafanaFormEvent) => {
    if (this.#isMonitoringEnabled)
      faro.api.pushEvent("Form Fill", {
        type: eventProps.type,
        response: eventProps.response,
        ...eventProps.additionalProps,
      });
  };

  /**
   * Tracks any links clicked with a telephone number.
   * @param eventProps The details of the link clicked such as the href,
   * class name, title etc.
   */
  logClickToCallEvent = (eventProps: ILogGrafanaClickToCallEvent) => {
    if (this.#isMonitoringEnabled)
      faro.api.pushEvent("Click to Call", {
        ...eventProps,
      });
  };

  /**
   * Logs common errors on Grafana such as exceptions when filling in
   * a form or retrieving data from Reviews.io. Potentially more logging
   * would be required if we send/retrieve more data in the future
   * @param errorProps
   */
  logError = (errorProps: ILogGrafanaFormError | ILogGrafanaRequestError) => {
    if (this.#isMonitoringEnabled) {
      const isFormType = "formType" in errorProps;
      let errorContext: {
        errorData: any;
        formType?: string;
        requestType?: string;
      } = {
        errorData:
          errorProps.error?.response?.data ||
          errorProps.error?.response ||
          errorProps.error?.message ||
          "An error occurred but there were no response or message properties in the error object.",
      };
      errorContext = isFormType
        ? { ...errorContext, formType: errorProps.formType }
        : { ...errorContext };
      faro.api.pushError(errorProps.error, {
        type: isFormType ? "Form Fill" : "Request",
        context: {
          ...errorContext,
          ...errorProps.additionalProps,
        },
      });
    }
  };

  /**
   * Logs the 404 error as an event so we can see if there are
   * any common 404 errors occurring which may help identify
   * broken links on this site/external websites.
   * @param location The window.location parameter so the URL
   * used can be identified.
   */
  log404Error = (location: Location) => {
    if (this.#isMonitoringEnabled)
      faro.api.pushEvent("404 error", {
        href: location.href,
        pathname: location.pathname,
      });
  };
}

export default LogToGrafana;
