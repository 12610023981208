/**
 * Returns a string with the first letter of each word capitalized and
 * the rest of the letters lowercased.
 * @param {string} value The string to be processed.
 * @returns {string} The processed string with proper case.
 */
const useProperCase = (value: string) => {
  return value.replace(/\w\S*/g, (word: string) => {
    return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
  });
};
export default useProperCase;
