import {
  ApplicationFormType,
  IFormField,
  RequestACallbackFormType,
  ReviewsCheckJobType,
  ReviewsFormType,
  UnsubscribeFormType,
} from "@models/forms";

// Multiple interfaces for props so that calls to the back end can be strictly typed
interface IBackendBaseProps {
  controller: BackendControllers;
  method: "GET" | "POST";
  endpoint: string;
}
interface IBackendGetProps extends IBackendBaseProps {
  method: "GET";
  params:
    | undefined
    | {
        [key: string]: string;
      };
}
interface IBackendPostProps extends IBackendBaseProps {
  method: "POST";
  params: {
    [key: string]: IFormField;
  };
  subFormType?:
    | undefined
    | "partner"
    | "engineer"
    | "head-office"
    | "nps"
    | "additional-feedback";
}
interface IBackendEnquiriesProps extends IBackendPostProps {
  controller: "Enquiries";
  endpoint: "SetEnquiry";
  params: RequestACallbackFormType;
}
interface IBackendRecruitmentProps extends IBackendPostProps {
  controller: "Recruitment";
  endpoint: "SetRecruitment";
  params: ApplicationFormType;
  subFormType: "partner" | "engineer" | "head-office";
}
interface IBackendReviewsGetProps extends IBackendGetProps {
  controller: "Reviews";
  endpoint: "GetReviewDetails";
  params: {
    jobReviewId: string;
  };
}
interface IBackendReviewsCheckJobProps extends IBackendPostProps {
  controller: "Reviews";
  endpoint: "CheckJobDetails";
  params: ReviewsCheckJobType;
}
interface IBackendReviewsSubmitNPSProps extends IBackendPostProps {
  controller: "Reviews";
  endpoint: "SubmitNPS";
  params: ReviewsFormType;
  subFormType: "nps";
}
interface IBackendReviewsSubmitAdditionalFeedbackProps
  extends IBackendPostProps {
  controller: "Reviews";
  endpoint: "SubmitAdditionalFeedback";
  params: ReviewsFormType;
  subFormType: "additional-feedback";
}
interface IBackendReviewsFlagSubmittedThirdPartyReviewProps
  extends IBackendGetProps {
  controller: "Reviews";
  endpoint: "FlagSubmittedThirdPartyReview";
  params: {
    jobReviewId: string;
    thirdParty: "Google" | "TrustPilot" | "ReviewsIO";
  };
}
interface IBackendStatsEngineerProps extends IBackendGetProps {
  controller: "Stats";
  endpoint: "GetEngineerByPostcodeArea";
  params: {
    postcodeArea: string;
  };
}
interface IBackendStatsJobProps extends IBackendGetProps {
  controller: "Stats";
  endpoint: "GetJobCompletionsCount";
  params: undefined;
}
interface IBackendUnsubscribeProps extends IBackendPostProps {
  controller: "Unsubscribe";
  method: "POST";
  endpoint: "RemoveCustomer";
  params: UnsubscribeFormType;
}

// Export type for props
export type BackendProps =
  | IBackendEnquiriesProps
  | IBackendRecruitmentProps
  | IBackendReviewsGetProps
  | IBackendReviewsCheckJobProps
  | IBackendReviewsSubmitNPSProps
  | IBackendReviewsSubmitAdditionalFeedbackProps
  | IBackendReviewsFlagSubmittedThirdPartyReviewProps
  | IBackendStatsEngineerProps
  | IBackendStatsJobProps
  | IBackendUnsubscribeProps;

// The controllers that are on the backend that can be called by the front end
export type BackendControllers =
  | "Enquiries"
  | "Recruitment"
  | "Reviews"
  | "Stats"
  | "Unsubscribe";

// The common error messages that may occur when validating fields
export const errorMessages = {
  invalidFields: "Form contains invalid fields.",
  potentialBot: "Potential bot detected.",
  recaptchaCheck: "Please check the box to prove you are not a robot.",
  selectTrades: "Please select the trades you cover.",
};
