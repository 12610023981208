import { ApplicationFormContext } from "@store";
import { useContext } from "react";

/**
 * Custom hook to use the application form context.
 *
 * @throws Error if used outside of an ApplicationFormProvider.
 * @returns The application form context value.
 */
export const useApplicationForm = () => {
  const context = useContext(ApplicationFormContext);
  if (context === undefined) {
    throw new Error(
      "useApplicationForm must be used within an ApplicationFormProvider"
    );
  }
  return context;
};
