import * as colourStyles from "../SVGStyles/SVGColours.module.css";
import * as styles from "./SVGArrow.module.css";

import { ISVGArrowComponentProps, ISVGIconType } from "@models/component-props";

import React from "react";
import { SVGFromIconName } from "@components/svgs";
import { useSVGColour } from "@hooks";

/**
 * A component to render an arrow SVG based on a given direction and colour.
 * This component ensures the styles specifc to this icon are applied.
 *
 * @param {ISVGArrowComponentProps} props
 * @prop {string} direction - The direction of the arrow: "left" or "right"
 * @prop {ISVGColours} [colour] - The colour of the SVG.
 * @prop {ISVGProps} [svgProps] - Additional props to pass to the underlying SVG
 * @returns {React.ReactElement} The arrow SVG
 */
const SVGArrow = ({ direction, colour, svgProps }: ISVGArrowComponentProps) => {
  // The icon name based on the direction of the arrow
  const icon: ISVGIconType = `arrow-${direction}`;

  // The class name based on the colour provided
  const className = `${styles.svgArrow} ${useSVGColour(colourStyles, colour)} ${
    svgProps.className || ""
  }`;

  // Return the SVG code based on the icon and the class name
  return <SVGFromIconName icon={icon} svgProps={{ ...svgProps, className }} />;
};

export default SVGArrow;
