import queryString from "query-string";

/**
 * Extracts and returns the category and location query string parameters from the
 * current location.search, if provided. If no location is provided, or if neither
 * parameter is present, returns default values of an empty string for both category
 * and location.
 * @param {Location | undefined} location - The location object from which to
 *   extract the query string parameters.
 * @returns {{category: string, location: string}} An object with two properties:
 *   category, the value of the category query string parameter, and location, the
 *   value of the location query string parameter.
 */
const useOpportunitiesQueryString = (location: Location | undefined) => {
  // If location is defined, extract the query string parameters from it
  // otherwise return default values
  if (location) {
    // Parse the query string from the URL
    const parsedQuery = queryString.parse(location.search);

    // Extract the category and location query string parameters from the parsed query string
    const { category, location: loc } = parsedQuery;

    // Return an object with the category and location query string parameters
    return {
      // If category is an array, return the first element in the array, otherwise return category
      category: Array.isArray(category) ? category[0] || "" : category || "",

      // If location is an array, return the first element in the array, otherwise return location
      location: Array.isArray(loc) ? loc[0] || "" : loc || "",
    };
  } else return { category: "", location: "" };
};
export default useOpportunitiesQueryString;
