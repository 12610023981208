import * as styles from "./Rating.module.css";

import { FormDispatches, FormTypes } from "@models/forms";
import React, { Fragment, useEffect, useState } from "react";

import { AdaptableHTag } from "@components/general";
import { RatingButton } from "@components/ui";
import { SVGClipPath } from "@components/svgs";
import { useFormDispatch } from "@hooks";

/**
 * A component that renders a rating interface with buttons to select a rating
 * from 1 to 10. The selected rating is displayed and can be validated if required.
 *
 * @param {Object} props - The properties for the Rating component.
 * @param {string} props.title - The title to display above the rating buttons.
 * @param {Function} props.setValue - A function to update the parent component with the selected rating.
 * @param {boolean} [props.required=true] - Determines if the rating is required for form submission.
 * @param {boolean} [props.incompleteForm=false] - Indicates if the form submission has been attempted with incomplete data.
 * @param {2 | 3 | 4 | 5 | 6} [props.hTagLevel] - The level of the h tag to use for the title.
 * @param {boolean} [props.useParagraph] - Whether to use a paragraph tag for the title instead of an h tag.
 * @param {string} [props.className] - An optional class name to apply to the component's container.
 * @param {5 | 10} [props.maxRating=5] - The maximum number of stars to display.
 * @returns {JSX.Element} The rendered rating component.
 */
const Rating = ({
  title,
  name,
  dispatch = null, //used when part of a form
  formType = undefined,
  setValue = (_value) => {}, //used when it's for display
  required = true,
  incompleteForm = false,
  hTagLevel = undefined,
  useParagraph = undefined,
  className = undefined,
  maxRating = 5,
  initialValue = undefined,
}: {
  title: string;
  name: string;
  setValue?: (_value: number) => void;
  dispatch?: FormDispatches;
  formType?: FormTypes;
  required?: boolean;
  incompleteForm?: boolean;
  hTagLevel?: 2 | 3 | 4 | 5 | 6;
  useParagraph?: boolean;
  className?: string;
  maxRating?: 5 | 10;
  initialValue?: number;
}) => {
  // State for the rating
  const [rating, setRating] = useState<number | undefined>(initialValue);

  // Update the parent when the rating changes
  useEffect(() => {
    if (rating) {
      if (formType && dispatch)
        useFormDispatch({
          name,
          value: { valid: true, value: rating },
          formType,
          dispatch,
        });
      else setValue(rating);
    }
  }, [rating]);
  return (
    <div className={className}>
      {/* 
        Clip paths used in the rating button. Defined here so as not to repeat 
        in each button 
      */}
      <SVGClipPath
        clipPaths={[
          {
            id: "squareWithStarCutoutClipPath",
            children: (
              <path d="M0,0v1.006h1.007V0H0ZM.995.411h-.001l-.227.222.054.314c.002.01-.002.019-.01.025-.004.003-.01.005-.015.005,0,0-.008,0-.012-.003l-.281-.148-.281.148c-.009.005-.019.004-.027-.002-.008-.006-.012-.016-.01-.025l.054-.314L.01.411c-.007-.007-.009-.017-.007-.026.003-.009.011-.016.021-.018l.314-.046L.479.036c.004-.009.013-.014.023-.014.01,0,.019.006.023.014l.141.285.314.046c.01.001.018.008.021.018.003.009,0,.02-.006.026Z" />
            ),
          },
        ]}
      />

      {/* Title */}
      {hTagLevel ? (
        <AdaptableHTag hTagLevel={hTagLevel} useParagraph={useParagraph}>
          {title}
        </AdaptableHTag>
      ) : (
        <p className={styles.fieldTitle}>{title}</p>
      )}

      {/* Rating Section */}
      <div
        className={`${styles.ratingContainer} ${
          maxRating === 5 ? styles.fiveStar : ""
        }`}
      >
        {/* Split buttons into two rows so it's easier to click on mobile */}
        <div className={styles.ratingSubContainer}>
          {/* Loop through 1 - 5 and add an input for each */}
          {[...Array(5)].map((_, index) => (
            <Fragment key={`overall_rating_button_section_${index}`}>
              <RatingButton
                index={index + 1}
                currentValue={rating}
                setValue={setRating}
                maxRating={maxRating}
              />
              {/* Separate buttons on desktop */}
              {index < 4 && <span className={styles.ratingSpacer}></span>}
            </Fragment>
          ))}
        </div>

        {/* 
          If there are more than 5 stars, add another row so it's not too condensed
          on mobile 
        */}
        {maxRating > 5 && (
          <>
            {/* Adda a spacer to separate the rows when loading on desktop */}
            <span className={styles.ratingSpacer}></span>

            {/* Second row */}
            <div className={styles.ratingSubContainer}>
              {/* Loop through 6 - 10 and add an input for each */}
              {[...Array(5)].map((_, index) => (
                <Fragment key={`overall_rating_button_section_${index}`}>
                  <RatingButton
                    index={index + 6}
                    currentValue={rating}
                    setValue={setRating}
                    maxRating={maxRating}
                  />
                  {/* Separate buttons on desktop */}
                  {index < 4 && <span className={styles.ratingSpacer}></span>}
                </Fragment>
              ))}
            </div>
          </>
        )}
      </div>

      {/* 
          Written confirmation of the score provided or error if rating is 
          not supplied and submission has been attempted.
        */}
      {rating ? (
        <p>
          {rating} out of {maxRating}
        </p>
      ) : (
        incompleteForm &&
        required && <p className={styles.error}>This field is required</p>
      )}
    </div>
  );
};

export default Rating;
