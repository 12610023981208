import { IAdaptableHTag } from "@models/component-props";
import React from "react";

/**
 * Renders a section heading with an optional class name and determines
 * whether to use a paragraph tag or an h tag based on the useParagraph prop.
 *
 * @param {React.ReactNode} children - The text / HTML to display as the section heading.
 * @param {string} [className] - An optional class name to apply to the section heading.
 * @param {number} [hTagLevel=2] - The level of the h tag to use (2-6). Defaults to 2.
 * @param {boolean} [useParagraph=false] - Whether to use a paragraph tag instead of an h tag. Defaults to false.
 * @return {JSX.Element} The rendered section heading as either a paragraph tag or an h tag.
 */
const AdaptableHTag = ({
  children,
  className,
  hTagLevel = 2,
  useParagraph = false,
}: IAdaptableHTag) => {
  // Supplies class name to h tag only if it is not undefined
  let hTagProps: { className?: string } = {};
  if (typeof className !== "undefined") hTagProps = { className };

  // Paragraph tag for when we don't want to send SEO signals
  const pTag = <p className={`h${hTagLevel} ${className || ""}`}>{children}</p>;

  // Determines the correct h tag to be used, helps with SEO signals
  const hTag =
    hTagLevel === 2 ? (
      <h2 {...hTagProps}>{children}</h2>
    ) : hTagLevel === 3 ? (
      <h3 {...hTagProps}>{children}</h3>
    ) : hTagLevel === 4 ? (
      <h4 {...hTagProps}>{children}</h4>
    ) : hTagLevel === 5 ? (
      <h5 {...hTagProps}>{children}</h5>
    ) : (
      <h6 {...hTagProps}>{children}</h6>
    );

  // Returns either paragraph or h tag based on whether we want to send SEO signals
  return useParagraph ? pTag : hTag;
};

export default AdaptableHTag;
