import * as colourStyles from "./SVGStyles/SVGColours.module.css";
import * as styles from "./SVGStyles/SVGPath.module.css";

import { ISVGPathStrokeComponentProps } from "@models/component-props";
import React from "react";
import { SVGFromIconName } from "@components/svgs";
import { useSVGColour } from "@hooks";

/**
 * A component to render an SVG path with a stroke colour, based on the given icon
 * and colour. This component ensures the styles specific to this icon are
 * applied.
 *
 * @param {ISVGPathStrokeComponentProps} props
 * @prop {ISVGIconType} icon - The icon to render
 * @prop {ISVGColours} [colour] - The colour of the SVG
 * @prop {ISVGProps} [svgProps] - Additional props to pass to the underlying SVG
 * @returns {React.ReactElement} The path SVG where generic styles are applied to the stroke
 */
const SVGPathStroke = ({
  icon,
  colour,
  svgProps,
}: ISVGPathStrokeComponentProps) => {
  // The class name based on the colour provided
  const className = `${styles.svgPathStroke} ${useSVGColour(
    colourStyles,
    colour
  )} ${styles.svgPathFillNone} ${styles.svgPathStrokeThree} ${
    svgProps.className || ""
  }`;

  // Return the SVG code based on the icon and the class name
  return <SVGFromIconName icon={icon} svgProps={{ ...svgProps, className }} />;
};

export default SVGPathStroke;
