import { graphql, useStaticQuery } from "gatsby";

import { IStepsHook } from "@models/hooks";

/**
 * Retrieves the "steps" data object from Directus.
 *
 * @return {IStepsHook} Gatsby data object containing the "steps" data.
 */
const useSteps = () => {
  const data = useStaticQuery<IStepsHook>(graphql`
    {
      steps {
        steps_title
        step_1_icon
        step_1_description
        step_2_icon
        step_2_description
        step_3_icon
        step_3_description
      }
    }
  `);
  return data;
};

export default useSteps;
