import { IBlogPostRelatedService } from "@models/pages";
import { IImageData } from "@models/directus";

export interface IPostListItemsHook {
  allBlog: {
    edges: { node: IPostListItem }[];
  };
}
export interface IPostListItem {
  id: string;
  date_created: Date;
  date_updated: Date | null;
  title: string;
  excerpt: string;
  page_type: "advice-hub" | "about-us/news" | "locations";
  slug: string;
  tags: string[];
  related_services: IBlogPostRelatedService[];
  hero: IImageData;
}
export interface IPostListItemExtended extends IPostListItem {
  styleType: PostListStyle;
  cta: string;
}

export enum PostListStyle {
  BlogItem = 1,
  LocationItem = 2,
}
