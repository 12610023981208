import { graphql, useStaticQuery } from "gatsby";

import { IReviewsIOHook } from "@models";

/**
 * Fetches the ReviewsIO data using Gatsby's useStaticQuery hook.
 *
 * @return {IReviewsIO} The ReviewsIO data.
 */
const useReviewsIO = () => {
  const data = useStaticQuery<IReviewsIOHook>(graphql`
    {
      reviewsIo {
        id
        word
        excellent_percentage
        stats {
          average_rating
          total_reviews
        }
        reviews {
          store_review_id
          store_branch_id
          title
          comments
          rating
          user_id
          date
          date_created
          reviewer {
            first_name
            last_name
            verified_buyer
            address
          }
        }
      }
    }
  `);
  return data.reviewsIo;
};

export default useReviewsIO;
