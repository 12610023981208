import * as styles from "./Select.module.css";

import { FormDispatches, FormTypes } from "@models/forms";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { useFormDispatch } from "@hooks";

/**
 * Renders a custom select component with the given options.
 *
 * @param {Object} props - The properties for the Select component.
 * @param {string} props.title - The title of the select component.
 * @param {string} props.name - The name of the select component.
 * @param {string} props.placeholder - The placeholder text for the select component.
 * @param {FormDispatches} [props.dispatch=null] - The dispatch function for the form.
 * @param {FormTypes | undefined} [props.formType=undefined] - The type of the form.
 * @param {(_value: string) => void} [props.setValue=(_value) => {}] - The function to set the value.
 * @param {boolean} [props.required=true] - Whether the select component is required.
 * @param {Array<{key: string; value: string}>} [props.options=[]] - The options for the select component.
 * @param {boolean} [props.incompleteForm=false] - Whether the form is incomplete.
 * @param {string} [props.className=""] - The class name for the select component.
 * @param {Object} [props.defaultSelectedOption={key: `- ${placeholder} -`, value: ""}] - The default selected option.
 * @param {() => Array<{key: string; value: string}>} [props.generateOptions=undefined] - The function to generate options.
 * @return {JSX.Element} The custom select component.
 */
const Select = ({
  title,
  name,
  placeholder,
  dispatch = null, //used when part of a form
  formType = undefined,
  setValue = (_value) => {}, //used when it's for display
  required = true,
  options = [],
  incompleteForm = false,
  className = "",
  defaultSelectedOption = {
    key: `- ${placeholder} -`,
    value: "",
  },
  generateOptions = undefined,
}: {
  title: string;
  name: string;
  placeholder: string;
  dispatch?: FormDispatches;
  formType?: FormTypes;
  setValue?: (_value: string) => void;
  required?: boolean;
  options: {
    key: string;
    value: string;
  }[];
  incompleteForm?: boolean;
  className?: string;
  defaultSelectedOption?: {
    key: string;
    value: string;
  };
  generateOptions?: () => {
    key: string;
    value: string;
  }[];
}) => {
  const newPlaceholder = `- ${placeholder} -`;

  // If a default option is provided, but the options need to be generated,
  // the an array with just the default option is returned until the options are generated
  // Otherwise, the options provided are returned
  const defaultOptions =
    typeof generateOptions !== "undefined" && defaultSelectedOption.value !== ""
      ? [defaultSelectedOption]
      : options;
  const [generatedOptions, setGeneratedOptions] = useState(defaultOptions);

  // Checks if the default option is in the options provided
  const defaultOptionFound =
    defaultSelectedOption.value !== "" &&
    generatedOptions.find((x) => x.value === defaultSelectedOption.value);

  const [isValid, setIsValid] = useState<boolean | null>(
    defaultOptionFound ? true : null
  );

  // Sets the selected option to the default if it is valid
  const [selectedOption, setSelectedOption] = useState(
    defaultOptionFound
      ? defaultSelectedOption
      : { key: newPlaceholder, value: "" }
  );
  const [isOpen, setIsOpen] = useState(false);
  const [optionsGenerated, setOptionsGenerated] = useState(
    typeof generateOptions === "undefined"
  );
  const selectRef = useRef<HTMLDivElement>(null);

  // If the field is required, this checks that the selected option isn't blank
  const checkIsValid = useCallback(
    (option: { key: string; value: string } | null = null) => {
      if (required) {
        const optionBlank = option
          ? option.value.trim().length === 0
          : selectedOption.value.trim().length === 0;
        setIsValid(!optionBlank);
      } else setIsValid(true);
    },
    [required, selectedOption]
  );

  // When a user clicks on the select component, it checks if the options
  // need to be generated and generates them if they do (this can improve
  // performance if there are a lot of options). It then opens/closes
  // the select component depending on whether it's open or not
  const onSelectClickHandler = () => {
    if (!optionsGenerated && typeof generateOptions === "function") {
      setGeneratedOptions(generateOptions());
      setOptionsGenerated(true);
    }
    const open = !isOpen;
    if (!open) checkIsValid();
    setIsOpen(open);
  };

  // When a user clicks on an option, the select component should close
  // and the dispatch for any form the select component is part of should be updated
  const onOptionClickHandler = (option: { key: string; value: string }) => {
    checkIsValid(option);
    setIsOpen(false);
    setSelectedOption(option);
    if (dispatch && formType)
      useFormDispatch({
        name,
        value: {
          valid: required ? option.value.trim().length > 0 : true,
          value: option.value,
        },
        formType,
        dispatch,
      });
    else setValue(option.value);
  };

  // Closes the select component if the user clicks outside of it
  useEffect(() => {
    const onClickHandler = (e: MouseEvent) => {
      if (
        (selectRef.current &&
          e.target instanceof Element &&
          selectRef.current.contains(e.target)) ||
        !isOpen
      )
        return; // inside click
      checkIsValid();
      setIsOpen(false);
    };
    document.addEventListener("mousedown", onClickHandler);
    return () => {
      document.removeEventListener("mousedown", onClickHandler); // return function to be called when unmounted
    };
  }, [isOpen]);

  // Highlishts the select component if it is a required field
  // and the user has attempted to submit the form when it is incomplete
  useEffect(() => {
    if (incompleteForm) checkIsValid();
  }, [required, incompleteForm]);

  return (
    <div
      ref={selectRef}
      className={`${styles.selectContainer} ${
        className.length > 0 ? className : ""
      }`}
    >
      {/* Label for the select component */}
      <label id={`${name}-label`} htmlFor={name} className={styles.selectTitle}>
        {title}
      </label>

      {/* 
      Select input, not shown to the user, user sees button made to look more 
      like a fancier select and a ul with the options displays when the button is 
      clicked 
      */}
      <select
        id={name}
        name={name}
        className={styles.select}
        required={required}
        value={selectedOption.value}
        onChange={(_event) => {}}
      >
        <option value="">{newPlaceholder}</option>
        {generatedOptions.map((o, i) => {
          return (
            <option key={`${name}_option_${o.value}_${i}`} value={o.value}>
              {o.key}
            </option>
          );
        })}
      </select>

      {/* Select button user clicks to see options */}
      <button
        type="button"
        className={`${styles.selectSelected} ${
          isOpen
            ? styles.selectSelectedOpen
            : isValid
            ? styles.selectValid
            : isValid === false
            ? styles.selectInvalid
            : ""
        }`}
        aria-labelledby={`${name}-label`}
        onClick={onSelectClickHandler}
      >
        {selectedOption.key}
      </button>

      {/* Styled options for the user to scroll through */}
      <ul className={`${styles.selectItems} ${isOpen ? styles.open : ""}`}>
        {/* If the field is not required, a blank option is added to the select */}
        {!required && (
          <li
            className={
              selectedOption.value === "" ? styles.selectItemsSelected : ""
            }
          >
            {/* Button to change the value when the user selects the blank option */}
            <button
              type="button"
              aria-label={placeholder}
              onClick={() =>
                onOptionClickHandler({ key: newPlaceholder, value: "" })
              }
            >
              <span>{newPlaceholder}</span>
            </button>
          </li>
        )}

        {/* List of options */}
        {generatedOptions.map((o, i) => {
          return (
            <li
              key={`${name}_li_${o.value}_${i}`}
              className={
                selectedOption.value === o.value
                  ? styles.selectItemsSelected
                  : ""
              }
            >
              {/* Button to change the value when the user selects an option */}
              <button
                type="button"
                aria-label={o.key}
                onClick={() => onOptionClickHandler(o)}
              >
                <span>{o.key}</span>
              </button>
            </li>
          );
        })}
      </ul>

      {/* Error message */}
      {isValid === false && !isOpen && (
        <span className={styles.selectError}>This field is required</span>
      )}
    </div>
  );
};

export default Select;
