import { graphql, useStaticQuery } from "gatsby";

import { ISiteMetadataHook } from "@models/hooks";

/**
 * Returns the site metadata from the GraphQL query result.
 * The site meta data is established in gatsby-config.ts
 *
 * @return {Object} The site metadata object with the following properties:
 *   - siteUrl: The URL of the site.
 *   - title: The title of the site.
 *   - description: The description of the site.
 *   - phoneNumber: The phone number of the site.
 *   - accountCode: The account code of the site.
 *   - facebook: The Facebook URL of the site.
 *   - twitter: The Twitter URL of the site.
 *   - linkedIn: The LinkedIn URL of the site.
 *   - businessStartDate: The start date of the business.
 *   - averageResponseTime: The average response time of the site.
 *   - address: The address of the site with the following properties:
 *     - streetAddress: The street address of the site.
 *     - addressLocality: The locality of the address.
 *     - addressRegion: The region of the address.
 *     - postalCode: The postal code of the address.
 *     - addressCountry: The country of the address.
 *   - geo: The geographic location of the site with the following properties:
 *     - latitude: The latitude of the location.
 *     - longitude: The longitude of the location.
 */
const useSiteMetadata = () => {
  const data = useStaticQuery<ISiteMetadataHook>(graphql`
    {
      site {
        siteMetadata {
          siteUrl
          title
          description
          phoneNumber
          accountCode
          facebook
          twitter
          linkedIn
          businessStartDate
          averageResponseTime
          address {
            streetAddress
            addressLocality
            addressRegion
            postalCode
            addressCountry
          }
          geo {
            latitude
            longitude
          }
        }
      }
    }
  `);

  // Gets the relevant site metadata from the object returned by the graphql query
  const siteMetadata = data.site.siteMetadata;

  // Ensure the site metadata has a domain
  return {
    ...siteMetadata,
    domain: siteMetadata.siteUrl,
  };
};

export default useSiteMetadata;
