import { useEffect, useRef, useState } from "react";

/**
 * Fires once when the element enters the viewport for the first time.
 *
 * @param ref the React ref to the element you want to observe
 * @param observerOptions the options object for the IntersectionObserver constructor
 * @returns a boolean indicating whether the element has entered the viewport
 */
const useFirstViewportEntry = (
  ref: React.RefObject<HTMLDivElement>,
  observerOptions?: IntersectionObserverInit
) => {
  // State for whether the element has entered the viewport
  const [entered, setEntered] = useState(false);

  // Observe the element if IntersectionObserver is available
  if (typeof IntersectionObserver !== "undefined") {
    // Create an IntersectionObserver and store it in a ref
    // This will be used to observe the element
    const observer = useRef(
      new IntersectionObserver(
        ([entry]) => setEntered(entry.isIntersecting),
        observerOptions
      )
    );

    useEffect(() => {
      const element = ref.current; // The element we want to observe is stored in the ref
      const ob = observer.current; // The IntersectionObserver is stored in a ref

      // If the element has already entered the viewport, disconnect the observer
      if (entered) {
        ob.disconnect();
        return;
      }

      // If the element exists and has not entered the viewport, observe it
      if (element && !entered) ob.observe(element);

      // When the component is cleaned up, disconnect the observer
      return () => ob.disconnect();
    }, [entered, ref]);
  }

  // Return whether the element has entered the viewport
  return entered;
};
export default useFirstViewportEntry;
