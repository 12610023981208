import * as styles from "./YouTubeVideo.module.css";

import React from "react";

/**
 * A component that renders a YouTube video player iframe within a section element.
 *
 * @param {{ src: string }} props - Props for the YouTubeVideo component.
 * @param {string} props.src - The source URL of the YouTube video to be played.
 * @return {JSX.Element} A JSX element representing the YouTubeVideo component.
 */
const YouTubeVideo = ({ src }: { src: string }) => {
  return (
    <section className="inner">
      <iframe
        width="260"
        height="148"
        src={src}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className={styles.iframe}
      ></iframe>
    </section>
  );
};

export default YouTubeVideo;
